import React, { useState } from 'react';
import { UploadView, Button } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { TemplateBuilder } from './template-generator';
import { Role } from '../constants';

export const AmenitiesView: React.FunctionComponent = () => {
    const [showBuilder, setShowBuilder] = useState(false);
    const label = 'Amenities';
    const templateSection = (
        <div>
            <p>
                Amenities require a special template to upload. Click to select
                which amenities and respective properties should be included,
                then download and complete the custom template.
            </p>
            <Button
                id="amenities-template-generator"
                // https://vacasait.atlassian.net/browse/UJM-1313.
                label="Amenities Template"
                type="primary"
                onClick={() => setShowBuilder(true)}
            />
        </div>
    );

    return showBuilder ? (
        <TemplateBuilder onCancel={() => setShowBuilder(false)} label={label} />
    ) : (
        <div>
            <UploadView
                id="amenities-upload-view"
                requiredRole={Role.Amenities}
                label="Amenities"
                apiPath="unit/amenities"
                uploadType="amenity"
                parseConfig={parseConfig}
                fixSpecs={fixSpecs}
                templateSection={templateSection}
                isPlural={true}
            />
        </div>
    );
};
