import { DataType } from '../util';
import {
    greaterThanZero,
    LTDExcludeValidation,
    MaxCharacterValidation,
    StandardDiscountsValidation,
    TurnoverValidation
} from './parsers';
import _ from 'lodash';

/**
 * Unique unit field names required by the Unit API.
 */
export const Field = {
    UnitId: 'UnitID',
    MinMinStay: 'minminstay',
    CustomMinStayNotes: 'custom_minstay_notes',
    VacasaMinRate: 'vacasa_min_rate',
    OwnerMinRate: 'owner_min_rate',
    HoaMinRate: 'hoa_min_rate',
    VacasaLTRMinRate: 'vacasa_ltr_min_rate',
    OwnerLTRMinRate: 'owner_ltr_min_rate',
    HoaLTRMinRate: 'hoa_ltr_min_rate',
    CustomRatesNotes: 'custom_rates_notes',
    LTDexclude: 'LTDexclude',
    StandardDiscountsExclude: 'StandardDiscountsExclude',
    CleaningFee: 'CleaningFee',
    OwnerCleaningFee: 'OwnerCleaningFee',
    HousekeeperPayment: 'HousekeeperPayment',
    HousekeeperPieceRate: 'HousekeeperPieceRate',
    fee_note: 'fee_note',
    DeepCleanContractorPieceRate: "DeepCleanContractorPieceRate",
    DeepCleanVacasaHousekeeperPieceRate: "DeepCleanVacasaHousekeeperPieceRate",
    TurnoverDay: "TurnoverDay",
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match.
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Units/paths/~1v1~1units/post
 * @see https://docs.google.com/spreadsheets/d/1fs_noswYJqnzSHLXyQU67D0Zrb0tYWDTBAcLeQCIweU/edit?ts=5d1a9195#gid=91542833
 */
const rateInfo: Model = [
    {
        name: Field.UnitId,
        label: 'Unit Id',
        type: DataType.Integer,
        required: true,
        parse: greaterThanZero,
    },
    {
        name: Field.MinMinStay,
        label: 'Minimum Minstay',
        type: DataType.Integer,
    },
    {
        name: Field.CustomMinStayNotes,
        label: 'Minimum Minstay Notes',
        type: DataType.Text,
    },
    {
        name: Field.VacasaMinRate,
        label: 'Vacasa Minimum Rate',
        type: DataType.Integer,
        parse: MaxCharacterValidation
    },
    {
        name: Field.OwnerMinRate,
        label: 'Owner Minimum Rate',
        type: DataType.Integer,
        parse: MaxCharacterValidation
    },
    {
        name: Field.HoaMinRate,
        label: 'HOA Minimum Rate',
        type: DataType.Integer,
        parse: MaxCharacterValidation
    },
    {
        name: Field.VacasaLTRMinRate,
        label: 'Vacasa LTR Minimum Rate',
        type: DataType.Integer,
        parse: MaxCharacterValidation
    },
    {
        name: Field.OwnerLTRMinRate,
        label: 'Owner LTR Minimum Rate',
        type: DataType.Integer,
        parse: MaxCharacterValidation
    },
    {
        name: Field.HoaLTRMinRate,
        label: 'HOA LTR Minimum Rate',
        type: DataType.Integer,
        parse: MaxCharacterValidation
    },
    {
        name: Field.CustomRatesNotes,
        label: 'Rate Notes/Requirements',
        type: DataType.Text,
    },
    {
        name: Field.LTDexclude,
        label: 'Exclude from Long Stay Discounts',
        type: DataType.Integer,
        parse: LTDExcludeValidation,
        required: true,
    },
    {
        name: Field.StandardDiscountsExclude,
        label: 'Standard Discounts Exclude',
        type: DataType.Integer,
        parse: StandardDiscountsValidation,
        required: true,
    },
    {
        name: Field.CleaningFee,
        label: 'Guest Cleaning Fee',
        type: DataType.Integer,
    },
    {
        name: Field.OwnerCleaningFee,
        label: 'Owner Stay Cleaning Fee',
        type: DataType.Integer,
    },
    {
        name: Field.fee_note,
        label: 'Fee Notes',
        type: DataType.Text,
    },
    {
        name: Field.HousekeeperPayment,
        label: 'Contractor Piece Rate',
        type: DataType.Integer,
    },
    {
        name: Field.HousekeeperPieceRate,
        label: 'Vacasa Housekeeper Piece Rate',
        type: DataType.Integer,
    },
    {
        name: Field.DeepCleanContractorPieceRate,
        label: 'Deep Clean Contractor Piece Rate',
        type: DataType.Integer,
    },
    {
        name: Field.DeepCleanVacasaHousekeeperPieceRate,
        label: 'Deep Clean Vacasa Housekeeper Piece Rate',
        type: DataType.Integer,
    },
    {
        name: Field.TurnoverDay,
        label: 'Fixed Turnover',
        parse: TurnoverValidation,
        type: DataType.Integer,
    },
];

export const parseConfig: ParseConfig = {
    model: rateInfo,
    skipRows: 2
};
