import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ProgramView: FunctionComponent = () => (
    <UploadView
        id="program-upload-view"
        requiredRole={Role.Programs}
        label="Program"
        apiPath="unit/programs"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        uploadType="program"
    />
);
