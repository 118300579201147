import { DATE_FORMAT, Field, RowError } from './model';
import { CommonField } from '../util/';

const alwaysShow: string[] = [CommonField.UnitID, CommonField.Email];

const ownerFields = alwaysShow.concat([
    Field.StartDate,
    Field.EndDate,
    Field.ReferralEligible,
    Field.ReferralDiscount,
]);

const financialFields = alwaysShow.concat([
    Field.ManagementFee,
    Field.PercentOwnership,
    Field.ReferralDiscount,
    Field.TaxOwnership,
    Field.MonthlyRent,
    Field.ExpenseDebitMethodID,
]);

const identifierFields = alwaysShow.concat([
    Field.TemplateVersionID,
    Field.FormID,
    Field.ChannelFeeCostSharingID,
    Field.AmendmentByNoticeID,
    Field.ServiceTypeID
]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'start-date-required',
        label: `Start Date is required`,
        triggeredBy: [RowError.StartDateRequired],
        editFields: [Field.StartDate],
        showFields: ownerFields,
    },
    {
        key: 'end-date-required',
        label: `End Date is required`,
        triggeredBy: [RowError.EndDateRequired],
        editFields: [Field.EndDate],
        showFields: ownerFields,
    },
    {
        key: 'invalid-date-format',
        label: `Invalid Start or End Date format (must be ${DATE_FORMAT})`,
        triggeredBy: [RowError.InvalidDateFormat],
        editFields: [Field.StartDate, Field.EndDate],
        showFields: ownerFields,
    },
    {
        key: 'unit-id-not-found',
        label: 'Invalid Unit ID',
        editFields: [CommonField.UnitID],
        showFields: identifierFields,
    },
    {
        key: 'contact-email-not-found',
        label: 'Invalid Email',
        editFields: [CommonField.Email],
        showFields: identifierFields,
    },
    {
        key: 'form-id-not-found',
        label: 'Invalid Form ID',
        editFields: [Field.FormID],
        showFields: identifierFields,
    },
    {
        key: 'template-version-id-not-found',
        label: 'Invalid Template Version ID',
        editFields: [Field.TemplateVersionID],
        showFields: identifierFields,
    },
    {
        key: 'expense-debit-method-id-not-found',
        label: 'Invalid Expense Debit Method ID',
        editFields: [Field.ExpenseDebitMethodID],
        showFields: financialFields,
    },
    {
        key: 'invalid-service-type',
        triggeredBy: [RowError.InvalidServiceType],
        label: 'Invalid Service Type ID',
        editFields: [Field.ServiceTypeID],
        showFields: identifierFields,
    },
    {
        key: 'invalid-owner-totals',
        label: 'Ownership totals for the unit must add to 1',
        editFields: [Field.PercentOwnership, Field.TaxOwnership],
        showFields: financialFields,
    },
    {
        key: 'invalid-fee-or-discount',
        label: 'Fee and Discount must be between 0 and 1',
        editFields: [Field.ManagementFee, Field.ReferralDiscount],
        showFields: financialFields,
    },
    {
        key: 'invalid-employee-id',
        label: 'Invalid Employee ID',
        editFields: [CommonField.CreatedBy],
        showFields: alwaysShow.concat([CommonField.CreatedBy]),
    },
    {
        key: 'invalid-referral',
        label: 'Referral Discount must be 0 if not eligible or less than fee',
        triggeredBy: [RowError.ReferralDiscount],
        editFields: [
            Field.ReferralDiscount,
            Field.ReferralEligible,
            Field.ManagementFee,
        ],
        showFields: financialFields,
    },
    {
        key: 'invalid-secured-by',
        label: 'Invalid Secured By',
        editFields: [Field.SecuredBy],
        showFields: alwaysShow.concat([Field.SecuredBy]),
    },
];
