import React, { FunctionComponent } from 'react';
import { UploadView } from '../components/';
import { fixSpecs } from './fix-specs';
import { parseConfig } from './model';
import { Role } from '../constants';

export const FinanceView: FunctionComponent = () => (
    <UploadView
        id="financial-upload-view"
        requiredRole={Role.Finance}
        label="Financial"
        apiPath="finances"
        uploadType="financial"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
    />
);
