import React, { useState, useEffect, createRef, FunctionComponent } from 'react';
import './status-table.scss';
import { Button, Modal, Spinner } from '../components';
import { months, uploadTypesWithSuccess } from '../constants';
import { round } from '../util';

const maxFileNameLength = 50;
const notShowFiles = [ 'amenity'];
const notSnapshotFiles = ['financial', 'property_tax', 'unit_create', 'contract', 'contact', 'reservation'];

/** Add leading zero as needed to force two digits */
const timeValue = (n: number): string => ('0' + n).slice(-2);
const hourValue = (h: number): number => (h > 12 ? h - 12 : h);
/** Return `0` if given value is falsey */
const numberfy = (n: number | undefined): number => (n ? n : 0);

const DateParts: FunctionComponent<{ date: Date }> = ({ date }) => (
    <>
        <td className="date">
            {months[date.getMonth()]} {date.getDate()}
        </td>
        <td className="time">
            {hourValue(date.getHours())}:{timeValue(date.getMinutes())}:{timeValue(date.getSeconds())}&nbsp;
            {date.getHours() >= 12 ? 'PM' : 'AM'}
        </td>
    </>
);

/**
 * Sub-table shown for each file upload row
 */
const RowSummary: FunctionComponent<{
    startedOn: Date;
    finishedOn: Date;
    percentDone: number;
    /** Person who uploaded */
    uploader: string;
}> = ({ startedOn, finishedOn, percentDone, uploader }) => (
    <table className="file-summary">
        <tbody>
            <tr>
                <th>Started</th>
                <DateParts date={startedOn} />
            </tr>
            <tr>
                <th>Finished</th>
                <DateParts date={finishedOn} />
            </tr>
            <tr>
                <th>Percent Done</th>
                <td className="percent-done" colSpan={2}>
                    {isNaN(percentDone) ? 0 : round(percentDone * 100)}%
                </td>
            </tr>
            <tr>
                <th>Uploaded By</th>
                <td colSpan={2}>{uploader}</td>
            </tr>
        </tbody>
    </table>
);

const RowFiles: FunctionComponent<{
    row: UploadFile;
    idField: string;
    viewFiles?: (fileID: number, viewSuccess: boolean) => void;
    uploadType?: string;
    failed: number;
    handleFileHistoryClick: (fileType: string, row: any, idField: any) => void;
}> = ({ row, idField, viewFiles, uploadType, failed, handleFileHistoryClick }) => {
    const handleLinkClick = async (fileType: string): Promise<void> => {
        await handleFileHistoryClick(fileType, row, idField);
    };

    return (
        <td className="number">
            {!notSnapshotFiles.includes(uploadType ?? '') && <a onClick={() => handleLinkClick('snapshot')}>Snapshot</a>}

            <a onClick={() => handleLinkClick('imported')} className="link-margin">
                Imported
            </a>
            {failed > 0 && viewFiles && (
                <a
                    // @ts-ignore-next
                    id={`review-failed-record-${row[idField]}`}
                    // @ts-ignore-next
                    onClick={() => viewFiles(row[idField])}
                    className="link-margin"
                >
                    Error
                </a>
            )}
        </td>
    );
};

const StatusTableRow: FunctionComponent<{
    row: UploadFile;
    idField: string;
    viewFiles?: (fileID: number, viewSuccess: boolean) => void;
    uploadType?: string;
    showFiles?: boolean;
    handleFileHistoryClick: (fileType: string, row: any, idField: any) => void;
}> = ({ row, idField, viewFiles, uploadType, showFiles, handleFileHistoryClick }) => {
    const total = numberfy(row.total_records);
    const complete = numberfy(row.total_records_pass);
    const failed = numberfy(row.total_records_fail);
    const pending = total - (complete + failed);

    let status = 'Pending';

    /**
     * Truncate long text. Theoretically, the `text-overflow` CSS rule should
     * handle this but it's having no effect.
     * @param max Maximum allowed text length
     */
    const limitLength = (text: string, max: number) => (text.length > max ? text.substr(0, max - 1) + '&hellip;' : text);

    if (total === complete) {
        status = 'Complete';
    } else if (total === failed) {
        status = 'Failed';
    }

    /**
     * Compares if two arrays of strings have the same values in the same position
     * @param originalArray first array to compare
     * @param arrayToCompare second array to compare
     */
    const areArraysEquals = (originalArray: Array<string>, arrayToCompare: Array<string>) => {
        return originalArray.length === arrayToCompare.length && originalArray.every((v, i) => v === arrayToCompare[i]);
    };

    const handleLinkClick = async (fileType: string): Promise<void> => {
        await handleFileHistoryClick(fileType, row, idField);
    };

    return (
        <tr className={status}>
            <td className="file-name">
                <div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: limitLength(row.file_name, maxFileNameLength),
                        }}
                    />
                    {failed > 0 && viewFiles && !showFiles && (
                        <Button
                            // @ts-ignore-next
                            id={`review-failed-record-${row[idField]}`}
                            // @ts-ignore-next
                            onClick={() => viewFiles(row[idField])}
                            type="secondary"
                            label="Review Failed Records"
                        />
                    )}
                    {complete > 0 && uploadTypesWithSuccess.includes(uploadType ?? '') && (
                        <Button
                            // @ts-ignore-next
                            id={`review-success-record-${row[idField]}`}
                            // @ts-ignore-next
                            onClick={() => viewFiles(row[idField], true)}
                            type="secondary"
                            label="Review Success Records"
                        />
                    )}
                </div>
            </td>
            {showFiles && (
                <RowFiles
                    row={row}
                    idField={idField}
                    viewFiles={viewFiles}
                    uploadType={uploadType}
                    failed={failed}
                    handleFileHistoryClick={handleFileHistoryClick}
                />
            )}

            <td className="number">{total}</td>
            <td className="number">{pending}</td>
            <td className={`number${failed > 0 ? ' error' : ''}`}>{failed}</td>
            <td className="number">{complete}</td>
            <td className="summary">
                <RowSummary uploader={row.uploaded_by} startedOn={row.upload_time} finishedOn={row.finish_time} percentDone={complete / total} />
            </td>
        </tr>
    );
};

/**
 * Show table with status of uploaded files
 */
export const StatusTable: FunctionComponent<{
    idField: string;
    items: UploadFile[];
    viewFiles?: (fileID: number, viewSucces: boolean) => Promise<void>;
    /**
     * Option to assign outside reference to selection in order to lookup
     * currently selected range
     */
    rangeRef?: React.RefObject<HTMLSelectElement>;
    /** Method to request new data */
    requestReload: (maxDaysOld: number) => void;
    uploadType?: string;
    handleFileHistoryClick: (fileType: string, row: any, idField: any) => void;
}> = ({ idField, items, requestReload, rangeRef, viewFiles, uploadType, handleFileHistoryClick }) => {
    const [isReloading, setIsReloading] = useState(false);
    const [maxDaysOld, setMaxDaysOld] = useState(0);
    const rangeOptions: [number, string][] = [
        // uploads are only split on upload date and ignore upload time
        [0, 'Today'],
        [3, '3 Days'],
        [7, '7 Days'],
        [30, '30 Days'],
    ];

    if (!rangeRef) rangeRef = createRef();
    // sort newest items first
    items.sort((a, b) => b.upload_time.getTime() - a.upload_time.getTime());
    // items update implies reload is complete
    useEffect(() => setIsReloading(false), [items]);

    /**
     * Load new range of status days
     */
    const onRequestReload = () => {
        if (rangeRef!.current) {
            const daysOld = parseInt(rangeRef!.current.value);
            setMaxDaysOld(daysOld);
            setIsReloading(true);
            requestReload(daysOld);
        }
    };

    /**
     * Refresh current status view.
     */
    const onRefresh = () => {
        setIsReloading(true);
        requestReload(maxDaysOld);
    };

    return (
        <table className="status-table">
            <thead>
                <tr className="actions">
                    <th colSpan={7}>
                        {isReloading ? (
                            <div className="center-loading-spinner">
                                <Spinner size={32} />
                            </div>
                        ) : (
                            <>
                                <div className="filters">
                                    <label>Select imported job(s) range filter:</label>
                                    <select ref={rangeRef} value={maxDaysOld} onChange={onRequestReload}>
                                        {rangeOptions.map(([daysOld, label]) => (
                                            <option key={daysOld} value={daysOld}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="refresh">
                                    or
                                    <a id="refresh-upload-table" onClick={onRefresh}>
                                        refresh
                                    </a>
                                    to see latest status
                                </div>
                            </>
                        )}
                    </th>
                </tr>
                <tr>
                    <th className="file-name" rowSpan={2}>
                        File Name
                    </th>
                    {!notShowFiles.includes(uploadType ?? '') && viewFiles && (
                        <th className="file-name" rowSpan={2}>
                            Files
                        </th>
                    )}

                    <th className="counts" colSpan={4}>
                        Rows
                    </th>
                    <th className="summary" rowSpan={2}>
                        Summary
                    </th>
                </tr>
                <tr className="counts">
                    <th>Total</th>
                    <th>Pending</th>
                    <th>Failed</th>
                    <th>Complete</th>
                </tr>
            </thead>
            <tbody>
                {items.length > 0 ? (
                    items.map(row => (
                        <StatusTableRow
                            // @ts-ignore-next
                            key={row[idField]}
                            row={row}
                            idField={idField}
                            viewFiles={viewFiles}
                            uploadType={uploadType}
                            showFiles={!notShowFiles.includes(uploadType ?? '')}
                            handleFileHistoryClick={handleFileHistoryClick}
                        />
                    ))
                ) : (
                    <tr className="no-matches">
                        <td colSpan={9}>There were no uploads in the selected timeframe</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};
