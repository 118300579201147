import { DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import { validateBlockTypeId, validateDaysOut } from './parsers';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    UnitBlockId: {
        name: 'unit_block_id',
        label: 'Unit Block Id',
    },
    UnitBlockTypeId: {
        name: 'unit_block_type_id',
        label: 'Unit Block Type Id',
    },
    DaysOut: {
        name: 'days_out',
        label: 'Days Out',
    },
    Note: {
        name: 'note',
        label: 'Note',
    },
};

export const RowError = {
    InvalidBlockTypeId: 'invalid_block_type_id',
    InvalidDaysOut: 'invalid_days_out',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const unitBlocksModel: Model = [
    unitIdField(true),
    {
        name: Field.UnitBlockId.name,
        label: Field.UnitBlockId.label,
        type: DataType.PositiveInteger,
    },
    {
        name: Field.UnitBlockTypeId.name,
        label: Field.UnitBlockTypeId.label,
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.DaysOut.name,
        label: Field.DaysOut.label,
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.Note.name,
        label: Field.Note.label,
        type: DataType.Text,
        required: true,
    },
];

export const parseConfig: ParseConfig = {
    model: unitBlocksModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        validateBlockTypeId(...file.rows);
        validateDaysOut(...file.rows);
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
        validateBlockTypeId(row);
        validateDaysOut(row);
    },
};
