import React, { useState } from 'react';
import './fetcher.scss';
import { Button } from './button';
import { getTemplateCSV, fetchEntitiesByUnitIds } from '../api';
import * as _ from 'lodash';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../util/parse-field';
import { FilterArguments } from './upload-view';

type UnitIdObject = {
    uuId: string;
    legacyUnitId: number;
};

export const Fetcher: React.FunctionComponent<{
    apiPath: string;
    isConnectEntity: boolean;
    entityName: string;
    entityCSVModel: Array<string>;
    unitIdsLimit: number;
    altTemplate?: string;
    customFilter?: (input: FilterArguments) => FilterArguments;
}> = ({ apiPath, isConnectEntity, entityName, entityCSVModel, unitIdsLimit, altTemplate, customFilter }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [unitsValue, setUnitsValue] = useState('');

    const parseCSVModel = async (entities: any, csvTemplate: string, unitIdsObject: Array<UnitIdObject>): Promise<string> => {
        csvTemplate = csvTemplate + '\n';
        if (entityName === 'UnitProgram') {
            for (const program of entities) {
                for (const programField of _.get(program, 'attributes.program_fields', [])) {
                    _.set(program, `attributes.${programField['slug']}`, programField['data']);
                }
            }

            entities = filterDamageInsurance(entities, unitIdsObject);
        }

        if (entityName === 'ReservationRulesUnitStay') {
            const restrictions = [];
            for (const rule of entities.data) {
                for (const restriction of _.get(rule, 'attributes.unit_rule_value_attributes.enrolled', [])) {
                    restrictions.push(setRestriction(rule, restriction))
                }
            }

            entities = restrictions;
        }

        // Seasonal rates
        if (entityName === 'ReservationRulesSeasonalRates') {
            const rates = [];
            for (const rule of entities.data) {
                for (const restriction of _.get(rule, 'attributes.unit_rule_value_attributes.enrolled', [])) {
                    rates.push(setRestriction(rule, restriction))
                }
            }

            entities = rates;
        }


        if (entityName === 'ReservationRules') {
            for (const rule of entities.data) {
                rule.attributes.unit_rule_value_attributes = formatUnitRuleValueAttribute(rule.attributes.unit_rule_value_attributes)
            }
        }
        entities = _.isArray(entities) ? entities : _.get(entities, 'data', []);

        if (customFilter) {
            let result: FilterArguments = customFilter({
                entities,
                csvTemplate,
                entityCSVModel,
            });

            entities = result.entities;
            csvTemplate = result.csvTemplate;
            entityCSVModel = result.entityCSVModel ? result.entityCSVModel : entityCSVModel;
        }

        //return an empty file
        if (_.isNil(entities) || _.isEmpty(entities)) {
            return addEmptyRows(
                unitIdsObject.map((u: any) => _.get(u, 'legacyUnitId')),
                csvTemplate
            );
        }


        for (const unitIds of unitIdsObject) {
            const entitiesById = getEntitiesByUnitId(unitIds, entities);
            if (entitiesById.length > 0) {
                for (const entity of entitiesById) {
                    let row = unitIds.legacyUnitId + ',';
                    row += fillRow(entity);
                    csvTemplate += row.slice(0, -1) + '\n';
                }
            } else {
                csvTemplate += getEmptyRow(unitIds.legacyUnitId);
            }
        }
        return csvTemplate;
    };

    const filterDamageInsurance = (entities: Array<Object>, unitIdsObject: Array<UnitIdObject>) => {
        const notDamageInsurance: Array<number> = [];
        const hasDamageInsurance: Array<number> = [];

        const filteredEntities = entities.filter(ie => {
            const legacyUnitId = _.get(
                unitIdsObject.find(uio => _.get(uio, 'uuId') === _.get(ie, 'relationships.unit.data.id')),
                'legacyUnitId'
            );

            if (legacyUnitId) {
                if (_.get(ie, 'relationships.program.data.id') === 2) {
                    hasDamageInsurance.push(legacyUnitId);
                    return ie;
                }
            }
        });

        if (!_.isEmpty(notDamageInsurance.filter(ndi => !hasDamageInsurance.some(hdi => hdi === ndi)))) {
            toast.info(`INFO: The following units has no damage insurance associated ${_.uniq(notDamageInsurance).join(',')}`);
        }

        return filteredEntities;
    };

    const setRestriction = (rule: any, restriction: any) => {
        return {
            id: rule.id,
            type: rule.type,
            attributes: {
                ...rule.attributes,
                unit_rule_value_attributes: {...restriction}
            }
        };
    }

    const formatUnitRuleValueAttribute = (attributes: any) => {
        if (attributes == null) return attributes;

        delete attributes?.code;
        attributes = Object.keys(attributes).map(function(key) {
            return `${key}=${attributes[key]}`;
        }).join(';');
        return attributes;
    }

    const getEntitiesByUnitId = (unitIds: UnitIdObject, entities: Array<any>) => {
        if (isConnectEntity) {
            if (entityName == 'units') {
                return _.filter(entities, e => e.id == unitIds.legacyUnitId);
            } else {
                return _.filter(entities, e => e.attributes.unit_id == unitIds.legacyUnitId);
            }
        }

        if (entityName == 'unit_rates_info' || entityName == 'unit_reserve_balance') {
            return _.filter(entities, e => e.attributes.UnitID == unitIds.legacyUnitId);
        }

        return _.filter(entities, e => {
            return unitIds.uuId == _.get(e, 'attributes.unit_id', _.get(e, 'relationships.unit.data.id'));
        });
    };

    const fillRow = (entity: Object) => {
        let row = '';
        for (const entry of entityCSVModel) {
            let columnValue = _.get(entity, `attributes.${entry}`, _.get(entity, `${entry}`, ''));

            //Sometimes notes and descriptions have \n characters at the start of the string, causing the csv to crash.
            if (typeof columnValue === 'string') {
                columnValue = columnValue.replaceAll('"', "'").trim();
            }

            if (typeof columnValue !== 'object') {
                if (entry === 'start_date' || entry === 'end_date') {
                    columnValue = formatDate(columnValue);
                }
            } else {
                columnValue = _.get(columnValue, 'id', '');
            }
            row += typeof columnValue === 'string' ? `"${columnValue}",` : `${columnValue},`;
        }
        return row;
    };

    const addEmptyRows = (unitIds: Array<any>, csvTemplate: string): string => {
        unitIds.map(id => {
            csvTemplate += getEmptyRow(_.isNumber(id) ? id : _.get(id, 'legacyUnitId'));
        });
        return csvTemplate;
    };

    const getEmptyRow = (unitId: number): string => {
        let columns = '';
        for (let i = 0; i < entityCSVModel.length; i++) {
            columns += ',';
        }

        return `${unitId}${columns}\n`;
    };

    const getEntityAndDownload = async (apiPath: string, isConnectEntity: boolean, entityName: string) => {
        try {
            const csvTemplate = await getTemplateCSV(altTemplate || apiPath);

            if (!unitsValue) {
                toast.warning('WARNING: Text box can not be empty, please add some unit ids and try again.');
                return;
            }
            if (_.isNil(csvTemplate)) {
                toast.error('ERROR: CSV Template not found');
                return '';
            }

            setIsFetching(true);

            const units = await getExistingUnits();
            const legacyUnitIds = units.map((ui: any) => _.get(ui, 'attributes.legacy_unit_id'));

            const entities = await fetchEntitiesByUnitIds(isConnectEntity, entityName, legacyUnitIds);
            let unitIdsObject: Array<UnitIdObject> = units.map((ui: any) => {
                return {
                    uuId: _.get(ui, 'id'),
                    legacyUnitId: _.get(ui, 'attributes.legacy_unit_id'),
                };
            });

            downloadFile(await parseCSVModel(entities, csvTemplate, unitIdsObject));
            setIsFetching(false);
        } catch (error) {
            toast.error(`ERROR: There was an error during the template flow process ${error}`);
            setIsFetching(false);
        } finally {
            setIsFetching(false);
        }
    };

    const getExistingUnits = async (): Promise<any> => {
        let curatedIds = getCuratedIds();

        let existentUnits = await fetchEntitiesByUnitIds(false, 'Unit', curatedIds);

        orderEntitiesByLegacyUnitId(existentUnits, curatedIds);
        curatedIds = curatedIds.filter(ci => !existentUnits.find((eu: any) => _.get(eu, 'attributes.legacy_unit_id') === ci));

        if (!_.isEmpty(curatedIds)) {
            toast.warning(`WARNING: The following non existent units will be filtered: ${curatedIds.join(',')}.`);
        }

        return existentUnits;
    };

    const orderEntitiesByLegacyUnitId = (entities: Array<any>, userUnitsIdOrder: Array<number>) => {
        const key = 'attributes.legacy_unit_id';
        return entities.sort((a: any, b: any) => {
            const A = _.get(a, key);
            const B = _.get(b, key);
            return userUnitsIdOrder.indexOf(A) - userUnitsIdOrder.indexOf(B);
        });
    };

    const getCuratedIds = (): Array<number> => {
        const reg = new RegExp('^[0-9]+$');
        let unitsInput;

        if (_.includes(unitsValue, ',')) {
            unitsInput = unitsValue.split(',');
        } else {
            unitsInput = unitsValue.split(/\n/);
        }

        unitsInput = unitsInput.map((unitsInput: string) => unitsInput.trim());

        const notNumericValues: Array<number | string> = [];

        unitsInput = unitsInput
            .map(ui => {
                if (!reg.test(ui)) {
                    notNumericValues.push(ui);
                    return 0;
                }

                return parseInt(ui);
            })
            .filter(ui => ui != 0);

        if (!_.isEmpty(notNumericValues)) {
            toast.warning(`WARNING: Found and filtered not numeric unit ids: ${notNumericValues.join(',')}`);
        }

        const unitGroups = _.groupBy(unitsValue.split(','));
        if (Object.values(unitGroups).some(ug => ug.length > 1)) {
            toast.warning(
                `WARNING: There were duplicated unit ids, proceeding with a curaded list of units. Duplicated Units: ${Object.keys(unitGroups).filter(
                    ug => unitGroups[ug].length > 1
                )} `
            );
        }

        if (unitsInput.length > unitIdsLimit) {
            toast.error('ERROR: Unit ids limit for this resource is ' + unitIdsLimit + ' please remove some of them and try again.');

            throw 'Units over limit set for this resource.';
        }

        return unitsInput;
    };

    const downloadFile = (filledTemplate: string) => {
        const element = document.createElement('a');

        const file = new Blob([filledTemplate]);

        element.href = URL.createObjectURL(file);
        element.download = `Template flow ${entityName}.csv`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        toast.success('SUCCESS: CSV template is ready and the download should start soon.');
    };

    return (
        <div>
            <div className="fetcher-container">
                <h2>Step 1</h2>
                <p>
                    Enter the Unit IDs separated by commas or in a vertical column without commas. Then click "Download CSV template for these units".{' '}
                    <br />
                    Max of {unitIdsLimit} units per request.
                </p>
                <textarea
                    className="fetcher-input"
                    id="fetcher-imput"
                    placeholder={`Examples:  16156,25834,8696... \n16156\n25834\n8696...`}
                    onChange={e => {
                        setUnitsValue(e.target.value);
                    }}
                ></textarea>
            </div>

            {isFetching ? (
                <p>Retrieving data and creating CSV, this may take a while depending on the amount of units.</p>
            ) : (
                <Button
                    id="fetch-units-button"
                    label={'Download CSV template for these units'}
                    onClick={() => {
                        getEntityAndDownload(apiPath, isConnectEntity, entityName);
                    }}
                    type="secondary"
                />
            )}
            <ToastContainer />
        </div>
    );
};
