import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ReservationRulesPermitsView: FunctionComponent = () => {
    return (
    <UploadView
        id="reservation-rules-upload-view"
        requiredRole={Role.ReservationRules}
        label="Reservation Rules"
        apiPath="reservation-rules"
        altTemplate="reservation-rules-permits"
        uploadType="reservation_rules"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={false}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="ReservationRulesPermit"
        entityCSVModel={ReservationRulesPermitsCSVModel}
    />
    )
};

const ReservationRulesPermitsCSVModel: Array<string> = [
    'id',
    'rule.code',
    'value.code',
    'notes',
    'start_date',
    'end_date',
    'unit_rule_value_attributes.number_permits_req',
    'unit_rule_value_attributes.override_requirement',
    'unit_rule_value_attributes.override_effective_date',
    'unit_rule_value_attributes.override_end_date',
    'unit_rule_value_attributes.override_notes'
];
