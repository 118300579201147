import { DataType, unitIdField, createdByField } from '../util/';
import { verifyUnitIDs } from '../util/parse-field';
import { verifyDates, verifyProgramSlugAndFields, parseBillingCycle, verifySetupBillingActual } from './parsers';

/** Recurring billing amounts */
const BillingAmountPerCycle = {
    /**
     * Vacasa’s cost for the program.
     * *For example*, Vacasa pays Smart Lock vendors for the ongoing
     * connection; smart lock hardware has a cost for Vacasa.
     *
     * Not all programs use the Cost field.
     */
    Cost: 'billing_amount_per_cycle_cost',
    Default: 'billing_amount_per_cycle_default',
    Actual: 'billing_amount_per_cycle_actual',
};

/** One-time billing amounts */
const BillingAmountSetup = {
    Cost: 'billing_amount_setup_cost',
    Default: 'billing_amount_setup_default',
    Actual: 'billing_amount_setup_actual',
};

/** Fields related to billing */
const Billing = {
    Currency: 'billing_currency',
    Cycle: 'billing_cycle',
    PerCycle: BillingAmountPerCycle,
    Setup: BillingAmountSetup,
};

/**
 * Errors triggers
 */
export const RowError = {
    InvalidEndDate: 'invalid_end_date',
    StartDateRequired: 'start_date_required',
    MissingRequiredData: 'missing_required_data',
};

/**
 * Unique reservation field names required by the Connect API
 */
export const Field = {
    ProgramSlug: 'program_slug',
    /**
     * `MM/DD/YYYY` or `M/D/YY`
     *
     * Cannot overlap with an existing enrollment for the same program
     *
     * `POST /unit-program` will return a 400 error if there are overlaps
     */
    StartDate: 'start_date',
    EndDate: 'end_date',
    Billing: Billing,
    /** `TRUE` or `FALSE` */
    IsDraft: 'is_draft',
    Notes: 'notes',
    /**
     * The following programs have program specific fields i.e. These
     * data fields are necessary for specific programs.
     *
     * ### Linen & Terry
     * - `linen-quality` "standard" or "luxury" *required*
     *
     * ### Credit Card Fee**
     * - `owner-fee-percent` 0.00 - 1.00 *required*
     * *Example*: `owner-fee-percent=0.50` means the owner pays 50% of the
     * credit card fees.
     *
     * ### Damage Insurance
     * - `payer` "Vacasa" or "Owner" *required*
     * - `opt_in_received` MM/DD/YYYYor M/D/YY
     * - `opt_in_effective` MM/DD/YYYYor M/D/YY
     * - `poi_received` Proof of Insurance MM/DD/YYYY or M/D/YY
     * - `poi_effective` MM/DD/YYYYor M/D/YY
     * - `opt_out_received` MM/DD/YYYYor M/D/YY
     * - `opt_out_effective` MM/DD/YYYY or M/D/YY
     *
     * If a program field relationship exists for the program you’re
     * creating an enrollment for and there is no data, you must still
     * provide it with a value of `null`, not just empty.
     */
    ProgramFields: 'program_fields',
    FieldSlug: 'slug',
    FieldValue: 'data',
    /**
     * `TRUE` or `FALSE`
     *
     * Whether this is a one-time billing amount, usually at the start of a new
     * enrollment.
     */
    IsSetup: 'is_setup',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#operation/post-reservations-import
 */
const programModel: Model = [
    unitIdField(true),
    {
        name: Field.ProgramSlug,
        label: 'Program Slug',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.StartDate,
        label: 'Start Date',
        type: DataType.Date,
    },
    {
        name: Field.EndDate,
        label: 'End Date',
        type: DataType.Date,
    },
    {
        name: Field.Billing.Cycle,
        label: 'Billing Cycle',
        parse: parseBillingCycle,
        required: true,
    },
    {
        name: Field.IsDraft,
        label: 'Is Draft',
        type: DataType.Boolean,
        // match values required in template
        yesNoLabels: ['TRUE', 'FALSE'],
        required: true,
    },
    {
        name: Field.ProgramFields,
        label: 'Program Fields',
        type: DataType.Table,
        table: {
            summarize: (rows?: ParsedRow[]): string => (rows ? `${rows.length} field${rows.length !== 1 ? 's' : ''}` : ''),
            fields: [
                {
                    name: Field.FieldSlug,
                    label: 'Slug',
                    type: DataType.Text,
                },
                {
                    name: Field.FieldValue,
                    label: 'Value',
                    type: DataType.Text,
                },
            ],
        },
    },
    {
        name: Field.Notes,
        label: 'Notes',
        maxLength: 256,
        type: DataType.MultiLineText,
    },
    {
        name: Field.Billing.PerCycle.Cost,
        label: 'Billing Amount Per Cycle Cost',
        type: DataType.Float,
    },
    {
        name: Field.Billing.PerCycle.Default,
        label: 'Billing Amount Per Cycle Default',
        type: DataType.Float,
    },
    {
        name: Field.Billing.PerCycle.Actual,
        label: 'Billing Amount Per Cycle Actual',
        type: DataType.Float,
        required: true,
    },
    {
        name: Field.IsSetup,
        label: 'Is Setup',
        type: DataType.Boolean,
        required: true,
        // match values required in template
        yesNoLabels: ['TRUE', 'FALSE'],
    },
    {
        name: Field.Billing.Setup.Cost,
        label: 'Billing Amount Setup Cost',
        type: DataType.Float,
    },
    {
        name: Field.Billing.Setup.Default,
        label: 'Billing Amount Setup Default',
        type: DataType.Float,
    },
    {
        name: Field.Billing.Setup.Actual,
        label: 'Billing Amount Setup Actual',
        type: DataType.Float,
    },
    createdByField(),
    {
        name: 'owner_notes',
        label: 'Owner Notes',
        maxLength: 256,
        type: DataType.MultiLineText,
    },
];

export const parseConfig: ParseConfig = {
    model: programModel,
    skipRows: 2,

    /** Verify billing cycle */
    onRowParse(row) {
        verifyDates(row);
        verifySetupBillingActual(row);
    },

    /**
     * Update errors when row values change
     */
    onRowUpdate(row, fields) {
        this.onRowParse!(row);
        return verifyUnitIDs(row);
    },

    async onUpdate(file, fixSpec) {},

    onComplete: async file => {
        await Promise.all([verifyProgramSlugAndFields(file.rows), verifyUnitIDs(...file.rows)]);
    },
};
