import { DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import { mapCurrencyIdToAE, verifyDates } from './parsers';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    RecordId: {
        name: 'record_id',
        label: 'Record Id',
    },
    AccountingEntityId: {
        name: 'accounting_entity_id',
        label: 'Accounting Entity Id',
    },
    StartDate: {
        name: 'start_date',
        label: 'Start Date',
    },
    EndDate: {
        name: 'end_date',
        label: 'End Date',
    },
};

export const RowError = {
    InvalidAccountingEntityId: 'invalid_accounting_entity_id',
    InvalidDates: 'invalid_dates',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const permitsModel: Model = [
    unitIdField(true),
    { ...Field.RecordId, type: DataType.Integer },
    {
        ...Field.AccountingEntityId,
        type: DataType.Integer,
        required: true,
    },
    {
        name: Field.StartDate.name,
        label: Field.StartDate.label,
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.EndDate.name,
        label: Field.EndDate.label,
        type: DataType.Date,
    },
];

export const parseConfig: ParseConfig = {
    model: permitsModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        mapCurrencyIdToAE(...file.rows);
        verifyDates(...file.rows);
    },

    async onRowUpdate(row, fields) {
        verifyDates(row);
        verifyUnitIDs(row);
    },
};
