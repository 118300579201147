import React, { useEffect, FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import './modal.scss';
import { Icon } from './icon';

/** "Window" that will contain modal content */
const ModalWindow: FunctionComponent<{
    showCloseIcon?: boolean;
    className: string;
    onClose?: () => void;
}> = ({ children, className, onClose, showCloseIcon }) => {
    if (showCloseIcon === undefined) showCloseIcon = true;

    useEffect(() => {
        const root = document.getElementById('root');
        if (root) {
            root.classList.add('blurred');
            // some useEffect magic -- if you return a function it will be called
            // when the component unmounts
            return () => {
                root.classList.remove('blurred');
            };
        }
    });

    return (
        <div className="overlay">
            <div className={(className ? className + ' ' : '') + 'window'}>
                {showCloseIcon && onClose && (
                    <Icon className="close" onClick={onClose} name="close" />
                )}
                {children}
            </div>
        </div>
    );
};

export const Modal: FunctionComponent<{
    showCloseIcon?: boolean;
    className: string;
    onClose?: () => void;
}> = props => {
    const el = document.getElementById('modal');
    if (el) {
        // must render in element outside root in order to have an overlay that
        // covers all of the root app
        return ReactDOM.createPortal(<ModalWindow {...props} />, el);
    } else {
        throw Error('No element with id "modal" exists');
    }
};
