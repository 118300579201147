import React, {
    useState,
    useEffect,
    useCallback,
    FunctionComponent,
} from 'react';
import './data-input.scss';
import { DataType, Pattern, formatCurrency } from '../util';
import { SubTable } from './sub-table';
import { Icon } from './icon';

/**
 * Convert regular expression objects to string format accepted by HTML inputs
 */
export const regExpText = (re: RegExp): string =>
    re.toString().replace(/(^\/|\/$)/g, '');

/**
 * RegEx patterns used for built-in HTML input validation
 * @see https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Form_validation#Validating_against_a_regular_expression
 */
let inputPattern: { [key: number]: string } = {
    [DataType.Bit]: regExpText(Pattern.Bit),
    [DataType.Float]: regExpText(Pattern.Decimals),
    [DataType.Date]: regExpText(Pattern.ExcelDate),
    [DataType.Integer]: regExpText(Pattern.Integer),
    [DataType.PositiveInteger]: regExpText(Pattern.PositiveInteger),
    [DataType.Email]: regExpText(Pattern.Email),
};

/**
 * Numeric input
 */
export const NumberInput: FunctionComponent<{
    id?: string;
    value: string;
    min?: number;
    max?: number;
    required?: boolean;
    /** Whether to allow decimals */
    decimals?: boolean;
    onChange: (v: string) => void;
}> = ({ id, value, min, max, required, decimals, onChange }) => (
    <input
        id={id}
        type="number"
        onChange={e => onChange(e.target.value)}
        // number input won't show non-number value so display instead as
        // placeholder as reference for correction
        placeholder={Pattern.Decimals.test(value) ? undefined : value}
        defaultValue={value}
        step={decimals ? '0.01' : undefined}
        min={min}
        max={max}
        required={required}
    />
);

/**
 * True/False input able to indicate an initial `undefined` state
 */
export const Toggle: FunctionComponent<{
    id?: string;
    value?: boolean;
    required?: boolean;
    labels?: [string, string];
    onChange: (v: boolean) => void;
}> = ({ id, value, required, labels, onChange }) => {
    const isValid = useCallback(
        v => typeof v === 'boolean' || (!required && v === undefined),
        [required]
    );
    const [choice, setChoice] = useState(value);
    const [valid, setValid] = useState(isValid(value));
    const ToggleButton: FunctionComponent<{
        setsValue?: boolean;
        label: string;
    }> = ({ setsValue, label }) => (
        <button
            id={id}
            className={
                label.replace(/\W/g, '') +
                (choice === setsValue ? ' selected' : '')
            }
            onClick={() => setChoice(setsValue)}
        >
            {label}
        </button>
    );

    if (!labels) labels = ['Yes', 'No'];

    useEffect(() => {
        setValid(isValid(choice));
        onChange(choice === true);
    }, [choice, isValid, onChange]);

    return (
        <div className={'toggle' + (valid ? '' : ' error')}>
            <ToggleButton setsValue={true} label={labels[0]} />
            {/* if value isn't required then allow an N/A selection */}
            {!required && <ToggleButton setsValue={undefined} label="N/A" />}
            <ToggleButton setsValue={false} label={labels[1]} />
        </div>
    );
};

/**
 * Render a read-only input that displays the sum of object or array values
 * that can be edited individually in a `SubTable`
 */
export const TableInput: FunctionComponent<{
    id: string;
    model: SubModel;
    rows: ParsedRow[];
    onChange: (v: { [key: string]: string | number }[]) => void;
}> = ({ id, rows, model, onChange }) => {
    const [visible, setVisible] = useState(false);
    const [values, setValues] = useState(rows);
    const [summary, setSummary] = useState(
        model.summarize ? model.summarize(rows) : 0
    );
    /** Reference to HTML table element in `SubTable` */
    const popupRef = React.createRef<HTMLTableElement>();
    /** Show or hide `SubTable` */
    const toggle = () => setVisible(!visible);

    const icon = visible
        ? 'keyboard_arrow_up'
        : values && values.length > 0
        ? 'keyboard_arrow_down'
        : 'add';

    /**
     * Update displayed total and emit change. Keep values in state so
     * `SubTable` popup is always given current values instead of last prop
     * value.
     */
    const update = (v: ParsedRow[]) => {
        setSummary(model.summarize ? model.summarize(v) : 0);
        setValues(v);
        onChange(v);
    };

    // hide popup when click happens elsewhere
    useEffect(() => {
        const onDocumentClick = (e: MouseEvent) => {
            if (
                !popupRef.current ||
                popupRef.current === e.target ||
                popupRef.current.contains(e.target as Node)
            ) {
                return;
            }
            setVisible(false);
        };
        if (visible) {
            document.addEventListener('click', onDocumentClick);
        } else {
            document.removeEventListener('click', onDocumentClick);
        }
    }, [popupRef, visible]);

    return (
        <div className="number-table">
            <Icon onClick={toggle} name={icon} />
            <input
                id={id}
                type="text"
                value={formatCurrency(summary)}
                onClick={toggle}
                readOnly
            />
            {visible && (
                <SubTable
                    innerRef={popupRef}
                    onChange={update}
                    specs={model.fields}
                    rows={values}
                />
            )}
        </div>
    );
};

/**
 * Render input components specific to data type
 */
export const DataInput: FunctionComponent<{
    value: any;
    spec: FieldSpec;
    /** React cache key for table cell */
    cellKey: string;
    /** Method called when value changes */
    onChange: (v: any) => void;
}> = ({ value, spec, cellKey, onChange }) => {
    const idName = `${cellKey}-field`;

    switch (spec.type) {
        case DataType.Boolean:
            return (
                <Toggle
                    id={idName}
                    value={value}
                    required={spec.required === true}
                    onChange={onChange}
                />
            );
        case DataType.Bit: {
            const b: boolean | undefined =
                value === '1' || value === 1
                    ? true
                    : value === '0' || value === 0
                    ? false
                    : undefined;
            return (
                <Toggle
                    id={idName}
                    value={b}
                    required={spec.required === true}
                    onChange={v => onChange(v ? 1 : 0)}
                />
            );
        }
        case DataType.Integer:
            return (
                <NumberInput
                    id={idName}
                    value={value}
                    onChange={onChange}
                    required={spec.required === true}
                />
            );

        case DataType.PositiveInteger:
            return (
                <NumberInput
                    id={idName}
                    value={value}
                    min={0}
                    onChange={onChange}
                    required={spec.required === true}
                />
            );

        case DataType.Float:
            return (
                <NumberInput
                    id={idName}
                    value={value}
                    decimals={true}
                    onChange={onChange}
                    required={spec.required === true}
                />
            );
        case DataType.Date:
            return (
                <input
                    id={idName}
                    type="text"
                    defaultValue={value}
                    required={spec.required}
                    onChange={e => onChange(e.target.value)}
                    pattern={inputPattern[spec.type]}
                />
            );
        case DataType.Table:
            if (!spec.table) {
                console.error(
                    `${idName} defined as a table but the the FieldSpec lacks a table definition`
                );
                return null;
            }
            return (
                <TableInput
                    id={idName}
                    model={spec.table}
                    onChange={onChange}
                    rows={value}
                />
            );

        case DataType.Email:
            return (
                <input
                    id={idName}
                    type="email"
                    onChange={e => onChange(e.target.value)}
                    defaultValue={value}
                    pattern={inputPattern[spec.type]}
                />
            );
        default:
            return (
                <input
                    id={idName}
                    type="text"
                    onChange={e => onChange(e.target.value)}
                    defaultValue={value}
                />
            );
    }
};
