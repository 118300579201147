import { Field, RowError, DATE_FORMAT } from './model';

const alwaysShow: string[] = [Field.ContactEmail];

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-tax-id-and-ein',
        label: 'Row has both Tax Id and EIN',
        triggeredBy: [RowError.InvalidTaxIDandEIN],
        editFields: [Field.TaxId, Field.Ein],
        showFields: [...alwaysShow, Field.TaxId, Field.Ein],
    },
    {
        key: 'empty-tax-id-and-ein',
        label: 'Row has both Tax Id and EIN empty, it should have one of them',
        triggeredBy: [RowError.EmptyTaxIDandEIN],
        editFields: [Field.TaxId, Field.Ein],
        showFields: [...alwaysShow, Field.TaxId, Field.Ein],
    },
    {
        key: 'required-tax-classification-type',
        label: 'Tax classification type is required',
        triggeredBy: [RowError.RequiredTaxClassificationType],
        editFields: [Field.TaxClassificationType],
        showFields: [...alwaysShow, Field.TaxClassificationType],
    },
    {
        key: 'required-tax-classification',
        label: 'Tax classification is required',
        triggeredBy: [RowError.RequiredTaxClassification],
        editFields: [Field.TaxClassification],
        showFields: [...alwaysShow, Field.TaxClassification],
    },
    {
        key: 'required-tax-classification-other',
        label: 'Tax classification other is required',
        triggeredBy: [RowError.RequiredTaxClassificationOther],
        editFields: [Field.TaxClassificationOther],
        showFields: [...alwaysShow, Field.TaxClassificationOther],
    },
    {
        key: 'requires-exempt-payee-code',
        label: 'Exempt Payee Code is required',
        triggeredBy: [RowError.RequiredExemptPayeeCode],
        editFields: [Field.ExemptPayeeCode],
        showFields: [...alwaysShow, Field.ExemptPayeeCode],
    },
    {
        key: 'requires-exempt-fatca-code',
        label: 'Exempt Fatca Code is required',
        triggeredBy: [RowError.RequiredExemptFatcaCode],
        editFields: [Field.ExemptFatcaCode],
        showFields: [...alwaysShow, Field.ExemptFatcaCode],
    },
    {
        key: 'requires-signature',
        label: 'Signature is required',
        triggeredBy: [RowError.RequiredSignature],
        editFields: [Field.Signature],
        showFields: [...alwaysShow, Field.Signature],
    },
    {
        key: 'requires-signdate',
        label: 'Sign Date is required',
        triggeredBy: [RowError.RequiredSignDate],
        editFields: [Field.SignDate],
        showFields: [...alwaysShow, Field.SignDate],
    },
    {
        key: 'invalid-signdate-format',
        label: `Sign Date format is not valid (${DATE_FORMAT})`,
        triggeredBy: [RowError.InvalidSignDateFormat],
        editFields: [Field.SignDate],
        showFields: [...alwaysShow, Field.SignDate],
    },
    {
        key: 'requires-ip-address',
        label: 'Ip Address is required',
        triggeredBy: [RowError.RequiredIpAddress],
        editFields: [Field.IpAddress],
        showFields: [...alwaysShow, Field.IpAddress],
    },
    {
        key: 'requires-created-by',
        label: 'Created By is required',
        triggeredBy: [RowError.RequiredCreatedBy],
        editFields: [Field.CreatedBy],
        showFields: [...alwaysShow, Field.CreatedBy],
    },
];
