/**
 * Check if the window is embedded in an iframe or not
 */
export function inIFrame(): boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

/**
 * Convert name to URL compatible text
 */
export const slugify = (name: string): string =>
    name.replace(/\s+/g, '-').toLocaleLowerCase();

/**
 * Create a hidden IFrame used for Implicit flow silent token refresh
 */
export function createSilentRefreshFrame() {
    const refreshFrame = document.createElement('iframe');
    refreshFrame.src = window.location.origin;
    refreshFrame.style.display = 'none';
    refreshFrame.id = 'refresh-frame';
    document.body.append(refreshFrame);
}
