import { Field, RowError } from './model';
import { CommonField } from '../util';
import { DATE_FORMAT, OVERRIDE_NOTES_MIN } from './parsers'

const alwaysShow: string[] = [
    CommonField.UnitID,
    Field.RuleCode.name,
    Field.ValueCode.name,
    Field.StartDate.name,
    Field.EndDate.name,
    Field.Notes.name,
];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit ID is not valid.',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'value-code-is-required',
        label: 'Value Code is required.',
        editFields: [Field.ValueCode.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'start-date-is-required',
        label: 'Start date is required.',
        triggeredBy: [RowError.StartDateRequired],
        editFields: [Field.StartDate.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'invalid-start-date',
        label: `Start date is invalid (Format should be: ${DATE_FORMAT}).`,
        triggeredBy: [RowError.InvalidStartDate],
        editFields: [Field.StartDate.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'invalid-end-date',
        label: `End date is invalid (Format should be: ${DATE_FORMAT}).`,
        triggeredBy: [RowError.InvalidEndDate],
        editFields: [Field.EndDate.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'invalid-rule-code',
        label: 'Wrong rule code. Please check it.',
        triggeredBy: [RowError.InvalidRuleCode],
        editFields: [Field.RuleCode.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'invalid-required-permits',
        label: 'Permits should be between 1 and 5.',
        triggeredBy: [RowError.InvalidRequiredPermits],
        editFields: [Field.RequiredPermits.name, Field.ValueCode.name],
        showFields: [CommonField.UnitID, Field.RequiredPermits.name, Field.ValueCode.name],
    },
    {
        key: 'invalid-override',
        label: 'Override should be valid.',
        triggeredBy: [RowError.InvalidOverride],
        editFields: [Field.Override.name],
        showFields: [CommonField.UnitID, Field.Override.name],
    },
    {
        key: 'invalid-override-effective-date',
        label: `Override effective date is invalid (Format should be: ${DATE_FORMAT}).`,
        triggeredBy: [RowError.InvalidOverrideEffectiveDate],
        editFields: [Field.OverrideEffectiveDate.name],
        showFields: [CommonField.UnitID, Field.OverrideEffectiveDate.name],
    },
    {
        key: 'override-effective-date-required',
        label: `Override effective date (Format should be: ${DATE_FORMAT}) is required when Override is true.`,
        triggeredBy: [RowError.OverrideEffectiveDateRequired],
        editFields: [Field.OverrideEffectiveDate.name],
        showFields: [CommonField.UnitID, Field.OverrideEffectiveDate.name],
    },
    {
        key: 'invalid-override-end-date',
        label: `Override end date should be valid (Format should be: ${DATE_FORMAT}).`,
        triggeredBy: [RowError.InvalidOverrideEndDate],
        editFields: [Field.OverrideEndDate.name],
        showFields: [CommonField.UnitID, Field.OverrideEndDate.name],
    },
    {
        key: 'invalid-override-notes',
        label: `Override notes should be greater than ${OVERRIDE_NOTES_MIN} characters.`,
        triggeredBy: [RowError.InvalidOverrideNotes],
        editFields: [Field.OverrideNotes.name],
        showFields: [CommonField.UnitID, Field.OverrideNotes.name],
    },
    {
        key: 'override-notes-required',
        label: 'Override notes is required when Override is true.',
        triggeredBy: [RowError.OverrideNotesRequired],
        editFields: [Field.OverrideNotes.name],
        showFields: [CommonField.UnitID, Field.OverrideNotes.name],
    },
    {
        key: 'notes-is-required',
        label: 'Notes is required.',
        editFields: [Field.Notes.name],
        showFields: [...alwaysShow],
    },
];
