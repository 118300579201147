import { DataType } from './data-types';
import { pairDelimiter } from '../constants';
import {rowHasError} from './parse-file';

/**
 * Convert data to CSV text and initiate DOM download.
 * @param template Header template
 * @param errorsOnly Whether to only download rows with errors
 */
export function downloadCSV(
    file: ParsedFile,
    model: FieldSpec[],
    template: string | null = null,
    errorsOnly = false,
    successOnly: boolean = false,
    successModel?: FieldSpec[]
) {
    const csv = exportCSV(file.rows, model, template, errorsOnly, successModel);
    const blobData = new Blob([csv], { type: 'text/csv' });
    const el = document.createElement('a');

    el.setAttribute('href', window.URL.createObjectURL(blobData));
    const date = new Date();
    el.setAttribute('download', `${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}-${successOnly ? 'success' : 'errors'}-${file.name}`);
    el.style.display = 'none';

    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
}

/**
 * Export parsed rows as CSV data
 * @param template Header template
 * @param errorsOnly Whether to only download rows with errors
 */
export const exportCSV = (
    rows: ParsedRow[],
    model: FieldSpec[],
    template: string | null = null,
    errorsOnly = false,
    successModel?: FieldSpec[]
): string => {
    /**
     * Convert row fields to text based on the model and append error summary
     */
    const modelToString = successModel ? successModel : model;
    const rowString = (r: ParsedRow) =>
        modelToString
            .map(m => {
                let val = toString(r, m);
                if (typeof val === 'string') {
                    if (/\r|\n|["|,]/g.test(val)) {
                        return '"' + val.replace(/["]/g, '""') + '"';
                    }
                    return `${val}`;
                }
                return val;
            })
            .join(',') +
        ',' +
        errorSummary(r);

    const include: ParsedRow[] = errorsOnly ? rows.filter((r: ParsedRow) => rowHasError(r)) : rows;

    const csv = include.map(rowString);

    if (template) {
        if (!successModel) {
            insertErrorRowInHeader(template, csv);
        } else {
            csv.unshift( template );
        }
    }
    return csv.join('\n');
};

/**
 * Generate summary of row errors appended to CSV download
 */
export function errorSummary(row: ParsedRow): string {
    let summary: string[] = [];
    /** Convert set to string without adding commas or new lines */
    const setString = (s: Set<any>, delimit = ', ') => Array.from(s).join(delimit);

    if (row._required.size > 0) {
        summary.push('Missing required fields: ' + setString(row._required));
    }
    if (row._invalid.size > 0) {
        summary.push('Fields values are invalid: ' + setString(row._invalid));
    }
    if (row._unhandled.size > 0) {
        summary.push(setString(row._unhandled, '\n').replace(/(\")/gm, ""));
    }
    return summary.length > 0 ? '"' + summary.join('\n') + '"' : '';
}

/**
 * Convert row value to string based on field spec
 */
export function toString(row: ParsedRow, spec: FieldSpec): string {
    const value = row[spec.name];
    const formatter = spec.type ? format[spec.type] : null;
    return formatter ? formatter(value, spec) : value;
}

/** Methods to convert special data types to a string */
export const format: { [key: number]: (v: any, s?: FieldSpec) => string } = {
    [DataType.Table]: (value, spec) => {
        if (!value || !Array.isArray(value) || spec === undefined || !spec.table) {
            return '';
        }
        const rows: ParsedRow[] = value;
        const fields = spec.table.fields;

        return rows
            .map(r => {
                const k = r[fields[0].name];
                const v = fields[1].type == 10 ? (new Boolean(r[fields[1].name])).toString() : r[fields[1].name];
                return !v || v.length === 0 ? k : `${k}=${v}`;
            })
            .join(pairDelimiter);
    },
    [DataType.MultiLineText]: (value, _spec) => {
        return value ? value : '';
    },
};

/** Insert cells in header for the error summary **/
function insertErrorRowInHeader (template: string, csv: any) {
    const delimiter = "\\n"
    //clean "\r" and separate template by end of line
    const temp = template.replace(/(\r)/gm, "").split(/(?=[\n])|(?<=[\n])/g);
    const lastColumnOfFirstRow = temp.length-3; //the last cell of the first row of description of the template
    temp.forEach((t, k) => {
        const toAdd = (temp[k+1] === delimiter ? temp[k+1] : "");
        if (k === 0) template = t + toAdd;
        else if (k === lastColumnOfFirstRow) {
            template += t + ',Column must be deleted before re-uploading';
        } else if (t !== delimiter) template += t + toAdd;
    });
    csv.unshift(template + ',"Errors"');
}
