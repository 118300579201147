import React, { useEffect, useState, useContext, FunctionComponent  } from 'react';
import { Context } from '../context';
import { getAllUsers, setUserRole, deleteUserFromDB } from './api';
import { Role } from '../constants';
import { Button, RequiresRole, Spinner } from "../components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './user-view.scss';

/** Render a single row in the user table */
export const UserEntry: FunctionComponent<{ user: IdpUser, currentUserId: number, refreshList: any}> = ({ user, currentUserId, refreshList }) => {
    const [updating, setUpdating] = useState(false);
    const [userPermissions, setUserPermissions] = useState(user.roles);
    const ctx = useContext(Context);
    const currentUserRoles = ctx.role;

    const onUpdateRoles = async(e: React.MouseEvent<HTMLButtonElement>) => {
        setUpdating(true);
        const success = await setUserRole(user.user_id, userPermissions);
        refreshList()
        setUpdating(false);
        if (!success) {
            toast.error('Unable to update user permissions');
        } else {
            toast.success('Updated user permissions');
        }
    }

    const onDeleteUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setUpdating(true)
        const success = await deleteUserFromDB(user.user_id);
        refreshList()
        setUpdating(false);
        if (!success) {
            toast.error("Unable to delete user")
        } else {
            toast.success("User deleted")
        }
    }

    const onChangeRole = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const roleChange = parseInt(e.target.value);
        const isChecked = e.target.checked;
        if(!isChecked) {
            userPermissions.splice(userPermissions.indexOf(roleChange), 1);
        } else {
            userPermissions.push(roleChange)
        }

        setUserPermissions(userPermissions);
    };

    return (
            <tr>
                <td>{user.email}</td>
                <td className="role-checkbox-flex">
                    {Object.entries(Role).sort()
                        .filter(([, roleID]) => {
                            if (!currentUserRoles.includes(Role.SuperUser)) return roleID >= 0 && roleID != 3
                            return roleID >= 0
                        } ) // Skip "Pending" role since it's a loading state
                        .map(([roleName, roleID]) => (
                          <div className="role-checkbox-div">
                                <input
                                  type="checkbox"
                                  value={roleID}
                                  key={roleID}
                                  disabled={user.user_id === currentUserId}
                                  defaultChecked={user.roles.includes(roleID)}
                                  onChange={onChangeRole}
                                /> {roleName}
                          </div>
                        ))
                    }
                </td>
                <td className="action-buttons">
                    {updating && <Spinner/>}
                    <div className="update-button">
                        <Button
                          id="user-update-button"
                          label={"Update"}
                          onClick={onUpdateRoles}
                          disabled={updating || user.user_id === currentUserId}
                        />
                    </div>
                    <div className="delete-button">
                        <Button
                          id="user-delete-button"
                          label={"Delete User"}
                          onClick={onDeleteUser}
                          disabled={updating || user.user_id === currentUserId}
                        />
                    </div>
                    <ToastContainer/>
                </td>
            </tr>
    );
};

export const UsersView: FunctionComponent = () => {
    const [users, setUsers] = useState<IdpUser[]>([]);
    const [isReloading, setIsReloading] = useState(false);
    const ctx = useContext(Context);
    // show spinner when fetching data
    useEffect(() => setIsReloading(false), [users]);

    // Fetch data on initial mount. note that the braces syntax is important here, see the following for details:
    // https://github.com/facebook/react/issues/14326#issuecomment-441680293 
    useEffect(() => {
        fetchUserList();
    }, []);

    const fetchUserList = async () => {
        setIsReloading(true);
        setUsers(await getAllUsers());
    };

    return (
        <RequiresRole requiredRole={Role.UserAdmin}
            message="You are not authorized to view this page"
        >
            <div id="users-view">
                <h1>Acquisition Tools Users</h1>

                <table className="status-table">
                    <thead>
                        <tr className="actions">
                            <th colSpan={2}>
                                {isReloading ? (
                                    <div className="center-loading-spinner">
                                        <Spinner size={32} />
                                    </div>
                                ) : (
                                    <div className="refresh">
                                        <span onClick={fetchUserList}>
                                            refresh&nbsp;
                                        </span>
                                        to see recent changes
                                    </div>
                                )}
                            </th>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <th>Permission</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users &&
                            users.map(user => (
                                <UserEntry key={user.user_id} user={user} currentUserId={ctx.userId} refreshList={fetchUserList}/>
                            ))}
                    </tbody>
                </table>
            </div>
        </RequiresRole>
    );
};
