import * as _ from 'lodash';
import { FilterArguments } from '../components/upload-view';
import { BedSizeMap, BedTypeMap, Room, ResponseFormat, Bed } from './types';

export class DynamicTemplate {
    static readonly roomTypes = [
        { name: 'Bedroom', key: 'bedroom' },
        { name: 'Common space', key: 'common' },
        { name: 'Unassigned beds', key: 'unassigned' },
        { name: 'Living room', key: 'living' },
        { name: 'Loft', key: 'loft' },
        { name: 'Basement', key: 'basement' },
        { name: 'Office', key: 'office' },
        { name: 'Study', key: 'study' },
        { name: 'Garage', key: 'garage' },
        { name: 'Bunk room', key: 'bunk' },
        { name: 'Studio', key: 'studio' },
        { name: 'Other', key: 'other' },
    ];
    static readonly bedSizesMap: Array<BedSizeMap> = [
        { name: 'King', key: 'K' },
        { name: 'Queen', key: 'Q' },
        { name: 'Double', key: 'D' },
        { name: 'Twin', key: 'T' },
        { name: 'Child', key: 'C' },
        { name: 'Crib', key: 'B' },
    ];

    static readonly bedTypesMap: Array<BedTypeMap> = [
        { name: 'Regular', key: 'R' },
        { name: 'Lower bunk bed', key: 'L' },
        { name: 'Upper bunk bed', key: 'U' },
        { name: 'Murphy / Wall', key: 'M' },
        { name: 'Futon', key: 'F' },
        { name: 'Sofa bed', key: 'S' },
        { name: 'Child', key: 'C' },
        { name: 'Crib', key: 'B' },
        { name: 'Trundle bed', key: 'T' },
    ];

    private static MIN_BEDROOMS = 5;
    private static MIN_LIVING = 2;

    public static createDynamicTemplate(input: FilterArguments): FilterArguments {
        const entities = input.entities as Array<Room>;
        let csvTemplate = input.csvTemplate;
        const entityCSVModel = [];

        /**
         * Step 1: create header template
         */
        const rooms = DynamicTemplate.getMaximumRooms(entities);
        csvTemplate += 'unit_id';
        for (const key of Object.keys(rooms)) {
            let value = +_.get(rooms, `${key}.total`, null);
            value = value === 0 ? 1 : value;
            for (let i = 1; i <= value; i++) {
                csvTemplate += `,${key}_${i}`;
                entityCSVModel.push(`${key}_${i}`);
            }
        }

        /**
         * Step 2: Order entities
         */
        const units = _.groupBy(entities, 'attributes.unit_id');
        const unitsFormated = _.map(Object.keys(units), unitId => {
            const objResponse: ResponseFormat = { attributes: { unit_id: unitId } };
            const rooms = units[unitId];
            for (const room of rooms) {
                const roomType = DynamicTemplate.getRoomTypeKey(room.attributes.room_type.name);
                const roomNumber = room.attributes.room_number;
                const bedInfo: Array<string> = room.attributes.beds.map(bed => {
                    const bedSize = DynamicTemplate.getSizeKey(bed);
                    const bedType = DynamicTemplate.getTypeKey(bed);
                    return `${bedSize}${bedType}`;
                });
                objResponse.attributes[`${roomType}_${roomNumber}`] = `${bedInfo.join(',')}`;
            }
            return objResponse;
        });

        return {
            entities: unitsFormated,
            csvTemplate: `${csvTemplate}\n`,
            entityCSVModel: entityCSVModel,
        };
    }

    private static getMaximumRooms(entities: Array<Room>) {
        let rooms = {} as any;
        const entitiesByRoomType = _.groupBy(entities, 'attributes.room_type.name');

        for (const roomType of DynamicTemplate.roomTypes) {
            const bedroomsByUnit = _.groupBy(entitiesByRoomType[roomType.name], 'attributes.unit_id');
            let maxLength = 0;
            for (const key of Object.keys(bedroomsByUnit)) {
                if (bedroomsByUnit[key].length > maxLength) {
                    maxLength = bedroomsByUnit[key].length;
                }
            }

            if (roomType.key === 'bedroom' && maxLength < DynamicTemplate.MIN_BEDROOMS) {
                maxLength = DynamicTemplate.MIN_BEDROOMS;
            } else if (roomType.key === 'living' && maxLength < DynamicTemplate.MIN_LIVING) {
                maxLength = DynamicTemplate.MIN_LIVING;
            }

            rooms[roomType.key] = { total: maxLength };
        }

        return rooms;
    }

    private static getRoomTypeKey(roomName: string): string {
        return this.roomTypes.find(type => type.name === roomName)?.key ?? '';
    }

    private static getSizeKey(bed: Bed): string {
        return this.bedSizesMap.find(bsm => bsm.name === bed.bed_size.name)?.key ?? '';
    }

    private static getTypeKey(bed: Bed): string {
        return this.bedTypesMap.find(btm => btm.name === bed.bed_type.name)?.key ?? '';
    }
}
