import { DataType } from '../util';
import { greaterThanZero} from './parsers';

/**
 * Unique unit field names required by the Units Service API.
 */
export const Field = {
    UnitId: 'UnitID',
    ReserveBalance: 'ReserveBalance'
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Units/paths/~1v1~1units/post
 * @see https://docs.google.com/spreadsheets/d/1fs_noswYJqnzSHLXyQU67D0Zrb0tYWDTBAcLeQCIweU/edit?ts=5d1a9195#gid=91542833
 */
const reserveModel: Model = [
    {
        name: Field.UnitId,
        label: 'Unit Id',
        type: DataType.Integer,
        required: true,
        parse: greaterThanZero,
    },
    {
        name: Field.ReserveBalance,
        label: 'Reserve Balance',
        type: DataType.PositiveInteger,
        required: true,
    }
];

export const parseConfig: ParseConfig = {
    model: reserveModel,
    skipRows: 2,
    keepZeros: true
};
