import { Field } from './model';

const alwaysShow: string[] = [Field.UnitId];
const addressFields = alwaysShow.concat([Field.Address, Field.Address2, Field.City, Field.State, Field.Zip]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-id',
        label: 'Required Unit Id',
        editFields: [Field.UnitId],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-address',
        label: 'Invalid Address Field',
        editFields: [Field.Address],
        showFields: addressFields,
    },
    {
        key: 'invalid-address2',
        label: 'Invalid Address Field',
        editFields: [Field.Address2],
        showFields: addressFields,
    },
    {
        key: 'invalid-city',
        label: 'Invalid City Field',
        editFields: [Field.City],
        showFields: addressFields,
    },
    {
        key: 'invalid-state',
        label: 'Invalid State Field',
        editFields: [Field.State],
        showFields: addressFields,
    },
    {
        key: 'invalid-zip-code',
        label: 'Invalid Zip Code Field',
        editFields: [Field.Zip],
        showFields: addressFields,
    },
];
