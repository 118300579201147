import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const UnitBlocksView: FunctionComponent = () => (
    <UploadView
        id="unit-blocks-upload-view"
        requiredRole={Role.UnitBlocks}
        label="Unit Blocks"
        apiPath="unit/unit-blocks"
        uploadType="unit_blocks"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
    />
);
