import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ContactsView: FunctionComponent = () => (
    <UploadView
        id="contact-upload-view"
        requiredRole={Role.Contacts}
        label="Contact"
        apiPath="contacts"
        uploadType="contact"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
    />
);
