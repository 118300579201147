import { CommonField } from '../util';
import { Field, RowError } from './model';


const alwaysShow: string[] = [
    CommonField.UnitID,
    Field.RateEffectiveStartDate.name,
    Field.RateEffectiveEndDate.name,
    Field.Notes.name,
    Field.ReasonWhy.name,
    Field.RateType.name,
    Field.WeekdayRate.name,
    Field.WeekendRate.name
];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit ID is not valid.',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-rate-effective-start_date',
        label: 'Rate Effective start date is invalid.',
        editFields: [Field.RateEffectiveStartDate.name],
        showFields: [...alwaysShow],
        triggeredBy: [RowError.InvalidRateEffectiveStartDate]
    },
    {
        key: 'invalid-rate-effective-end_date',
        label: 'Rate Effective end date is invalid.',
        editFields: [Field.RateEffectiveEndDate.name],
        showFields: [...alwaysShow],
        triggeredBy: [RowError.InvalidRateEffectiveEndDate]
    },
    {
        key: 'invalid-start-date',
        label: 'Start Date is invalid.',
        editFields: [Field.StartDate.name],
        showFields: [...alwaysShow, Field.StartDate.name, Field.EndDate.name],
    },
    {
        key: 'invalid-end-date',
        label: 'End Date is invalid.',
        editFields: [Field.EndDate.name],
        showFields: [...alwaysShow, Field.StartDate.name, Field.EndDate.name],
    },
    {
        key: 'notes-required',
        label: 'Notes is required.',
        editFields: [Field.Notes.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'reason-why',
        label: 'Reason why is not valid.',
        editFields: [Field.ReasonWhy.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'rate-type',
        label: 'Rate type is not valid.',
        editFields: [Field.RateType.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'weekday-rate',
        label: 'Weekday rate should be a positive number.',
        editFields: [Field.WeekdayRate.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'weekend-rate',
        label: 'Weekend rate should be a positive number.',
        editFields: [Field.WeekendRate.name],
        showFields: [...alwaysShow],
    }
]