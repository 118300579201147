import * as moment from 'moment';
import * as _ from 'lodash';
import { FilterArguments } from '../components/upload-view';

export function filterPastDataRanges(input: FilterArguments): FilterArguments {
    let { entities, csvTemplate } = input;
    return entities
        ? {
              entities: entities.filter((d: any) => {
                  let start_date = _.get(
                      d,
                      'attributes.start_date',
                      _.get(d, 'start_date', '')
                  );
                  let end_date = _.get(
                      d,
                      'attributes.end_date',
                      _.get(d, 'end_date', '')
                  );

                  return (
                      moment.default(start_date) > moment.default() ||
                      moment.default(end_date) > moment.default()
                  );
              }),
              csvTemplate: csvTemplate,
          }
        : { entities: entities, csvTemplate: csvTemplate };
}
