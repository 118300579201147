import { parseApiDate } from '../api';
import { DataType, rowHasError, unitIdField } from '../util';
import { parseNumber, Pattern, verifyUnitIDs } from '../util/parse-field';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    RestDays: 'buffer_days',
    ReasonID: 'reason_id',
    StartDate: 'start_date',
    EndDate: 'end_date',
    Notes: 'notes',
};

export const RowError = {
    EndBeforeStart: 'end_before_start',
    StartBeforePresent: 'start_before_present',
};

const parseDateUTC = (d: string): number => {
    const match = Pattern.Date.exec(d)!;
    return Date.UTC(
        parseInt(match[1]),
        parseInt(match[2]) - 1,
        parseInt(match[3])
    );
};

export const parseRestDay = (v: string) => parseNumber(v, /^[0-9]$/);
export const parseReasonID = (v: string) => parseNumber(v, /^[2-9]|1[0-1]$/);
/**
 * Whether date is in the present — will return `true` if date is the same day
 * as the present day
 */
export const parsePresent = (v: string): ParseResult<number> => {
    const d = parseDateUTC(v);
    const now = new Date();
    const today = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate()
    );
    return [d >= today, d];
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const restDaysModel: Model = [
    unitIdField(true),
    {
        name: Field.StartDate,
        label: 'Start Date',
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.EndDate,
        label: 'End Date',
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.RestDays,
        label: 'Rest Days',
        type: DataType.PositiveInteger,
        parse: parseRestDay,
        required: true,
    },
    {
        name: Field.ReasonID,
        label: 'Reason ID',
        type: DataType.PositiveInteger,
        parse: parseReasonID,
        required: true,
    },
    {
        name: Field.Notes,
        label: 'Notes',
        type: DataType.Text,
        required: false,
    },
];

export const parseConfig: ParseConfig = {
    model: restDaysModel,
    skipRows: 2,

    onRowParse(row: ParsedRow) {
        if (!rowHasError(row, [Field.StartDate])) {
            const [valid, start] = parsePresent(row[Field.StartDate]);

            if (valid) {
                const end = parseApiDate(row[Field.EndDate]);

                if (end && end.getTime() < start) {
                    row._invalid.add(RowError.EndBeforeStart);
                }
            } else {
                row._invalid.add(RowError.StartBeforePresent);
            }
        }
    },

    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
    },
    async onRowUpdate(row, fields) {
        if (this.onRowParse) this.onRowParse(row);
        verifyUnitIDs(row);
    },
};
