import { Field, RowError } from './model';
import * as _ from 'lodash';

/**
 * Due to the fact that there is no endpoint to retrieve the list of accounting entities and its currencies at this time.
 * Moreover the fact that the accounting entities don't change that often.
 * Finally product and tech decided to hardcode the current list of accounting entities and its currency_ids.
 * This map will be used both for accountingEntityId validation as well as a guide for filling the currencyId that will later be used in the lambda request.
 */
export const currencyIdMap = [
    { accountingEntityId: '1', currencyId: 254 },
    { accountingEntityId: '4', currencyId: 254 },
    { accountingEntityId: '6', currencyId: 254 },
    { accountingEntityId: '25', currencyId: 254 },
    { accountingEntityId: '26', currencyId: 254 },
    { accountingEntityId: '27', currencyId: 79 },
    { accountingEntityId: '28', currencyId: 79 },
    { accountingEntityId: '29', currencyId: 79 },
    { accountingEntityId: '30', currencyId: 254 },
    { accountingEntityId: '31', currencyId: 254 },
    { accountingEntityId: '32', currencyId: 254 },
    { accountingEntityId: '33', currencyId: 254 },
    { accountingEntityId: '34', currencyId: 254 },
    { accountingEntityId: '35', currencyId: 254 },
    { accountingEntityId: '36', currencyId: 254 },
    { accountingEntityId: '37', currencyId: 254 },
    { accountingEntityId: '38', currencyId: 254 },
    { accountingEntityId: '39', currencyId: 254 },
    { accountingEntityId: '40', currencyId: 79 },
    { accountingEntityId: '41', currencyId: 254 },
    { accountingEntityId: '42', currencyId: 254 },
    { accountingEntityId: '43', currencyId: 254 },
    { accountingEntityId: '44', currencyId: 254 },
    { accountingEntityId: '45', currencyId: 254 },
    { accountingEntityId: '46', currencyId: 254 },
    { accountingEntityId: '47', currencyId: 254 },
    { accountingEntityId: '48', currencyId: 254 },
    { accountingEntityId: '49', currencyId: 254 },
    { accountingEntityId: '50', currencyId: 254 },
    { accountingEntityId: '51', currencyId: 254 },
    { accountingEntityId: '52', currencyId: 254 },
    { accountingEntityId: '53', currencyId: 254 },
    { accountingEntityId: '54', currencyId: 254 },
    { accountingEntityId: '55', currencyId: 254 },
    { accountingEntityId: '56', currencyId: 254 },
    { accountingEntityId: '57', currencyId: 254 },
    { accountingEntityId: '58', currencyId: 254 },
    { accountingEntityId: '59', currencyId: 254 },
    { accountingEntityId: '60', currencyId: 254 },
    { accountingEntityId: '61', currencyId: 254 },
    { accountingEntityId: '62', currencyId: 254 },
    { accountingEntityId: '63', currencyId: 254 },
    { accountingEntityId: '64', currencyId: 254 },
    { accountingEntityId: '65', currencyId: 254 },
    { accountingEntityId: '66', currencyId: 254 },
    { accountingEntityId: '67', currencyId: 254 },
    { accountingEntityId: '68', currencyId: 254 },
    { accountingEntityId: '69', currencyId: 254 },
    { accountingEntityId: '70', currencyId: 254 },
    { accountingEntityId: '71', currencyId: 79 },
    { accountingEntityId: '72', currencyId: 254 },
    { accountingEntityId: '73', currencyId: 79 },
    { accountingEntityId: '74', currencyId: 254 },
    { accountingEntityId: '75', currencyId: 254 },
    { accountingEntityId: '76', currencyId: 254 },
    { accountingEntityId: '77', currencyId: 43 },
    { accountingEntityId: '78', currencyId: 254 },
    { accountingEntityId: '79', currencyId: 254 },
    { accountingEntityId: '80', currencyId: 254 },
    { accountingEntityId: '81', currencyId: 254 },
    { accountingEntityId: '82', currencyId: 254 },
    { accountingEntityId: '83', currencyId: 254 },
    { accountingEntityId: '84', currencyId: 254 },
    { accountingEntityId: '85', currencyId: 254 },
    { accountingEntityId: '86', currencyId: 254 },
    { accountingEntityId: '87', currencyId: 254 },
    { accountingEntityId: '88', currencyId: 254 },
    { accountingEntityId: '89', currencyId: 254 },
    { accountingEntityId: '90', currencyId: 254 },
    { accountingEntityId: '91', currencyId: 254 },
    { accountingEntityId: '92', currencyId: 254 },
    { accountingEntityId: '93', currencyId: 254 },
    { accountingEntityId: '94', currencyId: 254 },
    { accountingEntityId: '95', currencyId: 254 },
    { accountingEntityId: '96', currencyId: 254 },
    { accountingEntityId: '97', currencyId: 254 },
    { accountingEntityId: '98', currencyId: 254 },
    { accountingEntityId: '99', currencyId: 254 },
    { accountingEntityId: '100', currencyId: 254 },
    { accountingEntityId: '101', currencyId: 254 },
    { accountingEntityId: '102', currencyId: 254 },
    { accountingEntityId: '103', currencyId: 254 },
    { accountingEntityId: '104', currencyId: 254 },
    { accountingEntityId: '105', currencyId: 254 },
    { accountingEntityId: '106', currencyId: 254 },
    { accountingEntityId: '107', currencyId: 254 },
    { accountingEntityId: '108', currencyId: 254 },
    { accountingEntityId: '109', currencyId: 254 },
    { accountingEntityId: '110', currencyId: 254 },
    { accountingEntityId: '111', currencyId: 254 },
    { accountingEntityId: '112', currencyId: 254 },
    { accountingEntityId: '113', currencyId: 254 },
    { accountingEntityId: '114', currencyId: 254 },
    { accountingEntityId: '115', currencyId: 254 },
    { accountingEntityId: '116', currencyId: 254 },
    { accountingEntityId: '117', currencyId: 254 },
    { accountingEntityId: '118', currencyId: 254 },
    { accountingEntityId: '119', currencyId: 254 },
    { accountingEntityId: '120', currencyId: 254 },
    { accountingEntityId: '121', currencyId: 254 },
    { accountingEntityId: '122', currencyId: 254 },
    { accountingEntityId: '123', currencyId: 254 },
    { accountingEntityId: '124', currencyId: 254 },
    { accountingEntityId: '125', currencyId: 43 },
    { accountingEntityId: '126', currencyId: 43 },
    { accountingEntityId: '127', currencyId: 43 },
    { accountingEntityId: '128', currencyId: 254 },
    { accountingEntityId: '129', currencyId: 254 },
    { accountingEntityId: '130', currencyId: 254 },
    { accountingEntityId: '131', currencyId: 254 },
    { accountingEntityId: '132', currencyId: 254 },
    { accountingEntityId: '133', currencyId: 254 },
    { accountingEntityId: '134', currencyId: 254 },
    { accountingEntityId: '135', currencyId: 254 },
    { accountingEntityId: '136', currencyId: 254 },
    { accountingEntityId: '137', currencyId: 254 },
    { accountingEntityId: '138', currencyId: 254 }
];

export function mapCurrencyIdToAE(...rows: ParsedRow[]) {

    rows.forEach(r => {
        const accountingEntityId = _.get(r, 'accounting_entity_id', null);

        if (
            _.isNil(accountingEntityId) ||
            !currencyIdMap.find(
                cim => cim.accountingEntityId == accountingEntityId
            )
        ) {
            r._invalid.add(RowError.InvalidAccountingEntityId);
        } else {
            _.set(
                r,
                'currency_id',
                currencyIdMap.find(
                    map => map.accountingEntityId == accountingEntityId
                )?.currencyId
            );
        }
    });
}

export function verifyDates(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const startDate: Date | undefined = r[Field.StartDate.name];
        const endDate: Date | undefined = r[Field.EndDate.name];

        if (startDate && endDate && endDate < startDate) {
            r._invalid.add(RowError.InvalidDates);
        }
    });
}
