import { BedRoomFields, RowError } from './model';
import { currentUser } from '../idp-auth';
import * as _ from 'lodash';
import { DynamicTemplate } from './dynamic-template';

export function parseForBackend(...rows: ParsedRow[]): Array<ParsedRow> {
    return [
        ...rows.map(
            (r): ParsedRow => {
                const rooms = {} as any;
                for (const key in r) {
                    for (const roomType of DynamicTemplate.roomTypes) {
                        if (key.toLowerCase().includes(roomType.key) && !_.isEmpty(r[key])) {
                            if (!_.isArray(rooms[roomType.key])) {
                                rooms[roomType.key] = [];
                            }
                            rooms[roomType.key].push(r[key]);
                        }
                    }
                }
                return { ...rooms, ...r };
            }
        ),
    ];
}

export function validateRowsSize(...rows: ParsedRow[]) {
    if (rows.length > 1000) {
        rows.forEach(r => {
            r._invalid.add(RowError.InvalidFile);
        });
    }
}

export function validateDuplicateUnitIds(...rows: ParsedRow[]) {
    let grouped: { [id: string]: number } = {};
    const unitIdFieldName = 'unit_id';

    for (const row of rows) {
        if (grouped[row[unitIdFieldName]]) {
            grouped[row[unitIdFieldName]]++;
        } else {
            grouped[row[unitIdFieldName]] = 1;
        }
    }

    for (const key in grouped) {
        if (grouped[key] > 1) {
            rows.filter(r => r[unitIdFieldName] === parseInt(key)).forEach(r => r._invalid.add(RowError.DuplicatedUnitIds));
            break;
        }
    }
}

export function validateUnassignedRooms(...rows: ParsedRow[]) {
    const columns = Object.values(BedRoomFields);
    const unassignedRooms = _.filter(columns, r => (r as any).name.includes('unassigned'));
    if (unassignedRooms.length > 1) {
        rows.forEach(r => {
            r._invalid.add(RowError.UnitWithMultipleUnassignedRooms);
        });
    }
}

export function fillRowFields(...rows: ParsedRow[]) {
    rows.forEach(r => {
        Object.assign(r, { ...r, done_by: currentUser().email });
    });
}

export function validateAndParseBedRooms(...rows: ParsedRow[]) {
    const columns = Object.values(BedRoomFields);
    rows.forEach(r => {
        let emptyRooms = 0;
        const maxEmptyRooms = columns.length;
        for (let value of columns) {
            const beds = r[(value as any).name];

            if (_.isNumber(beds)) {
                emptyRooms++;
                continue;
            }

            if (beds === null || beds === undefined) {
                r[(value as any).name] = '';
                emptyRooms++;
                continue;
            }

            if (isValidBed(beds)) {
                r[(value as any).name] = getParsedBeds(beds);
            } else {
                r._invalid.add(RowError.InvalidBeds);
            }
        }

        if (emptyRooms === maxEmptyRooms) {
            r._invalid.add(RowError.UnitWithEmptyRooms);
        }
    });
}

function isValidBed(bedStr: string) {
    let result = [];

    let validBeds: Array<string> = [];

    const bedKeys = ['K', 'Q', 'D', 'T'];
    const bedTypes = ['R', 'M', 'L', 'U', 'S', 'F', 'T'];

    bedKeys.forEach(bedKey => {
        bedTypes.forEach(bedType => {
            validBeds.push(bedKey + bedType);
        });
    });

    validBeds = validBeds.concat(['CC', 'BB']);

    for (const bed of bedStr.split(',')) {
        result.push(validBeds.some(vb => vb === bed) && bed.length === 2);
    }

    return !result.some(r => r === false);
}

function getParsedBeds(bedKeys: string) {
    return bedKeys
        .split(',')
        .map(bedKey => bedKey.trim())
        .filter(bedKey => bedKey !== '')
        .join(',');
}
