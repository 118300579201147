import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

const PermitsCSVModel = [
    'id',
    'permit_number',
    'display',
    'effective_date',
    'renewal_date',
    'permit_type',
    'description',
];

export const PermitsView: FunctionComponent = () => (
    <UploadView
        id="permits-upload-view"
        requiredRole={Role.Permits}
        label="Permits"
        apiPath="permits"
        uploadType="permits"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="UnitPermit"
        entityCSVModel={PermitsCSVModel}
    />
);
