import { BedRoomFields, RowError } from './model';
import { CommonField } from '../util';

const alwaysShow: string[] = [CommonField.UnitID];
const bedRoomFields = alwaysShow.concat([
    ...Object.values(BedRoomFields).map(v => {
        return (v as any)['name'];
    }),
]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit Id is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-bedroom-key',
        label: 'Invalid Bedroom Key',
        editFields: [...bedRoomFields],
        showFields: bedRoomFields,
        triggeredBy: [RowError.InvalidBeds],
    },
    {
        key: 'invalid-file',
        label: 'Invalid File, Max 1000 Rows Allowed',
        editFields: [],
        showFields: bedRoomFields,
        triggeredBy: [RowError.InvalidFile],
    },
    {
        key: 'duplicated-unit-ids',
        label: 'The file contain rows with duplicated unit ids, please fix them and try again',
        editFields: [...alwaysShow],
        showFields: alwaysShow,
        triggeredBy: [RowError.DuplicatedUnitIds],
    },
    {
        key: 'unit-with-no-beds',
        label: 'The file contain units with no beds in any room, add beds or remove the unit.',
        editFields: [...alwaysShow.concat(bedRoomFields)],
        showFields: bedRoomFields,
        triggeredBy: [RowError.UnitWithEmptyRooms],
    },
    {
        key: 'unit-with-multiple-unassigned-rooms',
        label: 'The file contains units with multiple Unassigned Bed rooms, please group all unassigned beds into one Unassigned Bed room.',
        editFields: [],
        showFields: alwaysShow,
        triggeredBy: [RowError.UnitWithMultipleUnassignedRooms],
    },
];
