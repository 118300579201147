import { DataType } from '../util';
import { greaterThanZero, onlyEvenDoubleQuotationMarks } from '../util/parse-field';

export const Field = {
    UnitId: 'id',
    Address: 'address',
    Address2: 'address_2',
    City: 'city',
    State: 'state',
    Zip: 'zip',
    PrimaryLockbox: 'primaryLockbox',
    BackupLockbox: 'backupLockbox',
    LockboxNotes: 'lockboxNotes',
    CheckInTime: 'check_in_time',
    CheckOutTime: 'check_out_time',
    GateCode: 'attrGateCode',
    AlarmCode: 'attrAlarmCode',
    GettingThereNotes: 'drivingDirection',
    VehicleRegistrationRequired: 'registration',
    NeedToKnowASAP: 'warningEmail',
    BeachWaterAccess: 'beachAccess',
    MinimumAgeRequirement: 'min_booking_age',
    UnitSpecificContractTerms: 'contractTerms',
    RequireAcceptAcknowledgementOfUnitTerms: 'acknowledge',
    TotalVehicles: 'parkingTotalCars',
    GarageParking: 'garageParking',
    ParkingNotes: 'parkingNotes',
};

const model: Model = [
    {
        name: Field.UnitId,
        label: 'Unit Id',
        type: DataType.Integer,
        required: true,
        parse: greaterThanZero,
    },
    {
        name: Field.Address,
        label: 'Address',
        type: DataType.Text,
        required: true,
        parse: onlyEvenDoubleQuotationMarks,
    },
    {
        name: Field.Address2,
        label: '2nd Address Line',
        type: DataType.Text,
        required: false,
        parse: onlyEvenDoubleQuotationMarks,
    },
    {
        name: Field.City,
        label: 'City',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.State,
        label: 'State',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.Zip,
        label: 'Zip',
        type: DataType.Text,
        maxLength: 50,
        required: true,
    },
    {
        name: Field.PrimaryLockbox,
        label: 'PrimaryLockbox',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.BackupLockbox,
        label: 'BackupLockbox',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.LockboxNotes,
        label: 'Unlock the Door Notes',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.CheckInTime,
        label: 'Check-in Time',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.CheckOutTime,
        label: 'Check-out Time',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.GateCode,
        label: 'Gate Code',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.AlarmCode,
        label: 'Alarm Code',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.GettingThereNotes,
        label: 'Getting There Notes',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.VehicleRegistrationRequired,
        label: 'Vehicle Registration Required',
        type: DataType.Boolean,
        required: false,
    },
    {
        name: Field.NeedToKnowASAP,
        label: 'Need to Know ASAP',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.BeachWaterAccess,
        label: 'Beach/Water Access',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.MinimumAgeRequirement,
        label: 'Minimum Age Requirement',
        type: DataType.Integer,
        required: false,
    },
    {
        name: Field.UnitSpecificContractTerms,
        label: 'Unit Specific Contract Terms',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.RequireAcceptAcknowledgementOfUnitTerms,
        label: 'Require/Accept Acknowledgement of Unit Terms',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.TotalVehicles,
        label: 'Total Vehicles',
        type: DataType.Integer,
        required: false,
    },
    {
        name: Field.GarageParking,
        label: 'Garage Parking',
        type: DataType.Integer,
        required: false,
    },
    {
        name: Field.ParkingNotes,
        label: 'Parking Notes',
        type: DataType.Text,
        required: false,
    },
];

export const parseConfig: ParseConfig = {
    model: model,
    skipRows: 2,
    keepZeros: true,
};
