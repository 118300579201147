import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const PropertyTaxView: FunctionComponent = () => (
    <UploadView
        id="property-tax-upload-view"
        requiredRole={Role.PropertyTax}
        label="Property Tax"
        apiPath="property-tax"
        uploadType="property_tax"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
    />
);
