import { DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    RecordId: {
        name: 'record_id',
        label: 'Record Id',
    },
    FinanceReportingLocationCode: {
        name: 'finance_reporting_location_code',
        label: 'Finance Reporting Location Code',
    },
    StartDate: {
        name: 'start_date',
        label: 'Start Date',
    },
    EndDate: {
        name: 'end_date',
        label: 'End Date',
    },
};

export const RowError = {
    InvalidStartDate: 'invalid-start-date',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const unitBlocksModel: Model = [
    unitIdField(true),
    {
        name: Field.RecordId.name,
        label: Field.RecordId.label,
        type: DataType.PositiveInteger,
        required: false,
    },
    {
        name: Field.FinanceReportingLocationCode.name,
        label: Field.FinanceReportingLocationCode.label,
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.StartDate.name,
        label: Field.StartDate.label,
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.EndDate.name,
        label: Field.EndDate.label,
        type: DataType.Date,
        required: false,
    },
];

export const parseConfig: ParseConfig = {
    model: unitBlocksModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
    },
};
