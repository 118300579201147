import './template-generator.scss';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Button, Checkbox, Icon, Spinner, ToggleButtons } from '../components';
import { lookupAmenities, amenityPropertiesForID } from './api';
import { headerDelimiter } from './constants';

type HandleAmenity = (amenityID: number) => void;
type HandleProperty = (amenityID: number, propertyID: string | number) => void;

export const SearchInput: FunctionComponent<{
    id: string;
    onChange: (v: string) => void;
}> = ({ id, onChange }) => (
    <div className="search-input">
        <input id={id} type="text" placeholder="Search or Filter" onChange={e => onChange(e.target.value)} />
        <Icon className="input-icon" name="search" />
    </div>
);

export const AmenityProperty: FunctionComponent<{
    amenity: Amenity;
    property: AmenityProperty;
    onSelect: HandleProperty;
}> = ({ amenity, property, onSelect }) => {
    const id = `${amenity.id}-${property.property_id}`;
    const select = () => onSelect(amenity.id, property.property_id);
    return (
        <div key={id}>
            <Checkbox id={amenity.id.toString()} checked={property.isSelected} onClick={select} />
            <label onClick={select}>{property.property_name}</label>
        </div>
    );
};

export const AmenityRow: FunctionComponent<{
    amenity: Amenity;
    onSelectAmenity: HandleAmenity;
    onSelectProperty: HandleProperty;
}> = ({ amenity, onSelectAmenity, onSelectProperty }) => (
    <tr key={amenity.id}>
        <td>
            <div className="selector">
                <Checkbox id={amenity.id.toString()} checked={amenity.isSelected} onClick={() => onSelectAmenity(amenity.id)} />
                <label onClick={() => onSelectAmenity(amenity.id)}>{amenity.attributes.display_name}</label>
            </div>
        </td>
        <td>
            {!amenity.isSelected && <Button id={amenity.id} label={'Select Amenity'} onClick={() => onSelectAmenity(amenity.id)} type="secondary" />}
            {amenity.isSelected && (
                <div className="amenity-properties">
                    {amenity.properties.size > 0 &&
                        Array.from(amenity.properties.values()).map(property => (
                            <AmenityProperty
                                key={`${amenity.id}-${property.property_id}`}
                                amenity={amenity}
                                property={property}
                                onSelect={onSelectProperty}
                            />
                        ))}
                </div>
            )}
        </td>
    </tr>
);

export const AmenityTable: FunctionComponent<{
    items: Map<number, Amenity>;
    allSelected: boolean;
    allPropertiesSelected: boolean;
    onSelectAmenity: HandleAmenity;
    onSelectProperty: HandleProperty;
    onSelectAllAmenities: (s: boolean) => void;
    onSelectAllProperties: (s: boolean) => void;
}> = ({ items, allPropertiesSelected, allSelected, onSelectAmenity, onSelectProperty, onSelectAllAmenities, onSelectAllProperties }) => (
    <table id="amenity-table">
        <thead>
            <tr>
                <th id="checkbox-name">
                    <div>
                        <Checkbox title={allSelected ? 'Unselect all' : 'Select all'} checked={allSelected} onClick={onSelectAllAmenities} />
                        Name
                    </div>
                </th>
                <th id="checkbox-properties">
                    <div>
                        <Checkbox
                            title={allPropertiesSelected ? 'Unselect all' : 'Select all'}
                            checked={allPropertiesSelected}
                            onClick={onSelectAllProperties}
                        />
                        Properties
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            {Array.from(items.values())
                .filter(a => a.isVisible === true)
                .map(a => (
                    <AmenityRow key={a.id} amenity={a} onSelectAmenity={onSelectAmenity} onSelectProperty={onSelectProperty} />
                ))}
        </tbody>
    </table>
);

/** UX to multi-select categories */
export const CategorySelector: FunctionComponent<{
    onChange: (categories: string[]) => void;
    categories: string[];
}> = ({ onChange, categories }) => {
    const [selected, setSelected] = useState<string[]>([]);

    /** @param c Clicked category name */
    const toggle = (c: string) => {
        const updated = selected.includes(c) ? selected.filter(item => item !== c) : [c, ...selected];
        setSelected(updated);
        onChange(updated);
    };

    const onSelectAll = () => {
        setSelected([]);
        onChange([]);
    };

    return (
        <div className="category-selector">
            <button id="select-all-categories" key="select-all" className={selected.length === 0 ? ' active' : undefined} onClick={onSelectAll}>
                All
            </button>
            {categories.map(name => (
                <button
                    id={name.replace(/\s/g, '-').toLowerCase()}
                    key={name}
                    className={selected.includes(name) ? ' active' : undefined}
                    onClick={() => toggle(name)}
                >
                    {name}
                </button>
            ))}
        </div>
    );
};

/** Retrieve all amenities, their properties and categories */
async function getLookupList(): Promise<[Map<number, Amenity>, Set<string>]> {
    const res = await lookupAmenities();
    const categories: Set<string> = new Set();
    const amenities: Map<number, Amenity> = new Map();
    if (!res) {
        console.error('API returned no amenities');
        return [amenities, categories];
    }

    res.forEach(async a => {
        const category = a.attributes.category;
        const amenityProps: Map<string | number, AmenityProperty> = new Map();
        const all = await amenityPropertiesForID(a.id, false);

        //console.log("a", a)
        all.forEach(p => {
            p.isSelected = false;
            amenityProps.set(p.property_id, p);
        });

        a.properties = amenityProps;
        if (!categories.has(category)) categories.add(category);
        a.isSelected = false;
        a.isVisible = true;

        amenities.set(a.id, a);
    });
    return [amenities, categories];
}

/** Main view for generating an uploadable template */
export const TemplateBuilder: FunctionComponent<{
    onCancel: () => void;
    label: string;
}> = ({ onCancel, label }) => {
    const [amenities, setAmenities] = useState<Map<number, Amenity>>(new Map());
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [matchText, setMatchText] = useState('');
    const [toggleFilter, setToggleFilter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [allPropertiesSelected, setAllPropertiesSelected] = useState(false);

    useEffect(() => {
        async function load() {
            const [a, c] = await getLookupList();
            setAmenities(a);
            setCategories(Array.from(c));
            setIsLoading(false);
        }
        setIsLoading(true);
        load();
    }, []);

    useEffect(() => {
        /** Filtered amenity IDs */
        const visible = Array.from(amenities.values())
            .filter(a => selectedCategories.length === 0 || selectedCategories.includes(a.attributes.category))
            // If toggle filter is 1, only show selected items
            // If toggle filter is 2, only show non-selected items
            // If toggle filter is 3, only show required items
            .filter(a => (toggleFilter === 1 ? a.isSelected === true : toggleFilter === 2 ? a.isSelected === false : true))
            .filter(a => (toggleFilter === 3 ? a.isRequired === true : true))
            .filter(a => matchText === '' || a.attributes.display_name.toLowerCase().includes(matchText))
            .map(a => a.id);

        amenities.forEach((a, id) => {
            a.isVisible = visible.includes(id);
        });
        setAmenities(new Map(amenities));
    }, [matchText, selectedCategories, toggleFilter]);

    /**
     * Update header checkbox to match whether all visible amenities are
     * checked.
     */
    const updateAllSelected = () => {
        let selected = true;
        amenities.forEach(a => {
            if (selected && a.isVisible && !a.isSelected) selected = false;
        });
        setAllSelected(selected);
    };

    useEffect(updateAllSelected, [amenities]);

    /** Select single amenity */
    const handleAmenitySelect = (key: number) => {
        const a = amenities.get(key);
        if (a) {
            a.isSelected = !a.isSelected;
            setAmenities(new Map(amenities));
        }
    };

    const handleToggleButton = (key: string) => {
        let toggleValue = 0;
        if (key === 'Selected') {
            toggleValue = 1;
        } else if (key === 'Unselected') {
            toggleValue = 2;
        } else if (key === 'Required') {
            toggleValue = 3;
        }
        setToggleFilter(toggleValue);
    };

    const handlePropertySelect = (amenityID: number, propertyID: string | number) => {
        const a = amenities.get(amenityID);
        if (!a) return;
        const p = a.properties.get(propertyID);
        if (!p) return;

        p.isSelected = !p.isSelected;
        setAmenities(new Map(amenities));
    };

    /** Header checkbox toggles amenity checkboxes for all visible amenities */
    const onSelectAllAmenities = (selected: boolean) => {
        setAllSelected(selected);

        amenities.forEach(a => {
            if (a.isVisible) a.isSelected = selected;
        });
        setAmenities(new Map(amenities));
    };

    /** Header checkbox toggles amenity checkboxes for all visible properties */
    const onSelectAllProperties = (selected: boolean) => {
        setAllPropertiesSelected(selected);

        amenities.forEach(a => {
            if (a.isVisible) {
                a.properties.forEach(p => {
                    p.isSelected = selected;
                });
            }
        });
        setAmenities(new Map(amenities));
    };

    const handleTemplateDownload = () => {
        const selected = Array.from(amenities.values()).filter(a => a.isSelected);
        const header = {
            key: ['Unit ID'],
            type: ['Required number'],
            name: ['unit_id'],
        };
        const fieldTypeText = (type: AmenityValueType): string => {
            switch (type) {
                case 'textarea':
                    return 'text';
                default:
                    return type;
            }
        };
        const concactTypeAndTextHelp = (type: string, helpText?: string): string => {
            if (helpText) return `"${type} - ${helpText}"`;

            return type;
        };

        selected.forEach(a => {
            header.key.push(a.id.toString());
            header.type.push('number');
            const attributeName = a.attributes.display_name.replaceAll(',', '-');
            header.name.push(attributeName);

            Array.from(a.properties.values())
                .filter(p => p.isSelected)
                .forEach(p => {
                    header.key.push(`${a.id}${headerDelimiter}${p.property_id}`);
                    header.type.push(`${concactTypeAndTextHelp(fieldTypeText(p.field_type), p.helpText)}`);
                    header.name.push(`${attributeName} - ${p.property_name.replaceAll(',', '-')}`);
                });
        });

        header.key.push('Created By');
        header.type.push('Required number');
        header.name.push('created_by');

        const csvContent = 'data:text/csv;charset=utf-8,' + header.key.join(',') + '\n' + header.type.join(',') + '\n' + header.name.join(',');
        const encodedContent = encodeURI(csvContent);
        const link = document.createElement('a');

        link.setAttribute('href', encodedContent);
        link.setAttribute('download', `${label.toLowerCase()}_template.csv`);
        document.body.appendChild(link);
        link.click();
    };

    return (
        <div id="template-generator-view">
            <button className="back-button" onClick={onCancel}>
                <Icon name="keyboard_backspace" />
                Back
            </button>
            <div>
                <h1>New {label} Upload</h1>
                <div>
                    <strong>Select {label}</strong>
                    <div>
                        <p>
                            Choose the {label.toLowerCase()} present for the unit(s). Filter by category or scroll through the list and select the
                            amenity you wish to add. Uncheck an amenity to remove it from the selected list.
                        </p>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div id={`template-generator-status-loader`} className="center-loading-spinner flex-column">
                    <div>
                        <p>Retrieving {label} Options</p>
                    </div>
                    <div>
                        <Spinner size={50} />
                    </div>
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="filter-bar">
                        <div className="search-toggle">
                            <SearchInput id="template-generator-search" onChange={setMatchText} />
                            <ToggleButtons buttonLabels={['All', 'Selected', 'Unselected', 'Required']} onChange={handleToggleButton} />
                        </div>
                        <Button id="download-template" label="Download Template" onClick={handleTemplateDownload} />
                    </div>
                    <CategorySelector onChange={setSelectedCategories} categories={categories} />

                    <AmenityTable
                        items={amenities}
                        allSelected={allSelected}
                        allPropertiesSelected={allPropertiesSelected}
                        onSelectAmenity={handleAmenitySelect}
                        onSelectProperty={handlePropertySelect}
                        onSelectAllAmenities={onSelectAllAmenities}
                        onSelectAllProperties={onSelectAllProperties}
                    />
                </>
            )}
        </div>
    );
};
