import './requires-role.scss';
import React, { useContext, FunctionComponent } from 'react';
import { Icon } from './icon';
import { Context } from '../context';
import { Role } from '../constants';

const defaultMessage = 'Your account has not been configured to view this information';

/**
 * Render child content if user has at least minimum role. Note that this is
 * only UX sugar, not actual security. Actual role enforcement must occur in
 * the API.
 */
export const RequiresRole: FunctionComponent<{
    /** Minimum role ID to have access to child content */
    requiredRole: number;
    message?: string;
}> = ({ children, requiredRole, message }) => {
    const ctx = useContext(Context);
    const say = message
        ? message
        : defaultMessage

    return (ctx.role.includes(requiredRole) || ctx.role.includes(Role.SuperUser)) ? (
        <>{children}</>
    ) : (
        <div className="role-error">
            <Icon name="security" />
            {say}
        </div>
    );
};
