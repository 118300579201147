import './uploader.scss';
import React, {
    useCallback,
    useEffect,
    useState,
    FunctionComponent,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from './icon';
import { Button } from './button';
import { downloadCSV, parseCSV, FileError } from '../util/';
import { Spinner } from './spinner';
import _ from "lodash";

export const Uploader: FunctionComponent<{
    /**
     * If no parse configuration is given then basic File reference will be sent
     * to `onUnparsedUpload`
     */
    parseConfig?: ParseConfig;
    onUpload?: (file: ParsedFile) => void;
    onUnparsedUpload?: (file: File) => Promise<void>;
    /** Optionally override "Upload & Validate" button label */
    buttonLabel?: string;
    /** Optional error set by parent */
    fileError?: FileError;
    templateFlowEnabled?: boolean;
    additionalData?: any;
    idView?: string
}> = ({
    parseConfig,
    onUpload,
    onUnparsedUpload,
    buttonLabel,
    fileError,
    templateFlowEnabled,
    additionalData,
    idView
}) => {
    const [pendingFile, setPendingFile] = useState<File | undefined>();
    const [error, setError] = useState<FileError | undefined>();
    const [keepZeros, setKeepZeros] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const onDrop = useCallback((files: File[]) => {
        setPendingFile(files[0]);
    }, []);

    // do not limit the accept MIME type because that prevents Windows upload
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        multiple: false,
        minSize: 32,
    });

    const submit = async () => {
        if (!pendingFile) return;
        if (!parseConfig) {
            // emit File object without parsing if no parse configuration is given
            if (onUnparsedUpload === undefined) {
                console.error('Missign parse configuration');
                return;
            }
            onUnparsedUpload(pendingFile);
            setPendingFile(undefined);
            return;
        } else if (onUpload === undefined) {
            console.error('Missing parsed upload handler');
            return;
        }

        // otherwise read and parse the file
        setShowSpinner(true);
        const reader = new FileReader();

        reader.onload = async (e: ProgressEvent<FileReader>) => {
            if (!e.target) return;

            try {
                const parsed = await parseCSV(
                    pendingFile.name,
                    e.target.result as string,
                    parseConfig,
                    additionalData
                );
                onUpload(parsed);
            } catch (e) {
                console.error(e);
                setError(e);
            }
            setShowSpinner(false);
            setPendingFile(undefined);
        };
        reader.readAsText(pendingFile);
    };

    const keepZerosChange = () => {
        setKeepZeros(!keepZeros);
        if (parseConfig) parseConfig.stripZeros = !parseConfig.keepZeros;
    };

    const cancel = () => setPendingFile(undefined);
    const closeError = () => setError(undefined);

    // show parent error message if given
    useEffect(() => setError(fileError), [fileError]);

    // https://vacasait.atlassian.net/browse/UJM-1313
    if (!buttonLabel) buttonLabel = 'Upload';

    const disabledUploadButton = !_.isNil(idView) ? idView === "unit-upload-view" && (_.isNil(additionalData?.acqId) || additionalData.acqId === "0") ? true : false : false;

    return (
        <div className="uploader">
            {templateFlowEnabled ? (
                <React.Fragment>
                    <h2>Step 2</h2>
                    <p>
                        Update the data in the CSV template from step 1. Save
                        your changes. Then upload below.
                    </p>
                </React.Fragment>
            ) : (
                <label>CSV File</label>
            )}
            {parseConfig && parseConfig.showZeroOption && (
                <div>
                    <label className="zero-fees">
                        <input
                            id="zero-the-zeros"
                            type="checkbox"
                            checked={keepZeros}
                            onChange={keepZerosChange}
                        />
                        Leave zero fees as given in the CSV
                    </label>
                </div>
            )}
            {pendingFile ? (
                <>
                    {showSpinner ? (
                        <div>
                            <p>Validating File</p>
                            <Spinner />
                        </div>
                    ) : (
                        <div className="confirm-file">
                            <div className="pending-file">
                                <Icon name="insert_drive_file" />
                                {pendingFile.name}
                                <Icon
                                    onClick={cancel}
                                    className="cancel-button"
                                    name="highlight_off"
                                />
                            </div>
                            <div className="upload-button">
                                <Button
                                    id="validate-button"
                                    onClick={submit}
                                    label={buttonLabel}
                                    disabled={disabledUploadButton}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : error ? (
                <div className="failure" id="upload-failure">
                    <Icon onClick={closeError} className="close" name="close" />
                    <Icon className="error" name="error" />
                    <div>
                        {error.fileName && <h4>{error.fileName}</h4>}
                        {error.message}
                        {error.fileConfig && (
                            <div>
                                <Button
                                    label={'Download edited file'}
                                    onClick={() =>
                                        downloadCSV(
                                            // @ts-ignore
                                            error.fileConfig.parsedFile,
                                            // @ts-ignore
                                            error.fileConfig.model,
                                            null,
                                            false
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="file-drop" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        isDragReject ? (
                            <>
                                <Icon name="error" />
                                <p>Not a valid .csv file</p>
                            </>
                        ) : (
                            <>
                                <Icon name="cloud_upload" />
                                <p id="upload-link">
                                    Drop file here or click to select a file
                                </p>
                            </>
                        )
                    ) : (
                        <>
                            <Icon name="cloud_upload" />
                            <p>
                                Drop any .csv or&nbsp;
                                <span id="upload-link" className="link">
                                    browse your files
                                </span>
                            </p>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
