import './checkbox.scss';
import React from 'react';
import { Icon } from './icon';

export const Checkbox: React.FunctionComponent<{
    id?: string;
    checked?: boolean;
    title?: string;
    onClick: (checked: boolean) => void;
}> = ({ id, onClick, title, checked = false }) => (
    <div
        id={id}
        title={title}
        className={'checkbox' + (checked ? ' checked' : '')}
        onClick={() => onClick(!checked)}
    >
        <Icon name={'done'} />
    </div>
);
