import { CommonField } from '../util/';
import * as _ from 'lodash';
import {
    headerDelimiter,
    AmenityID,
    MAX_DOGS_PROPERTY_ID,
    NO_DOGS_DECISION_PROPERTY_ID,
    AMENITY_PROPERTY_MAX_DOGS_OPTIONS,
    AMENITY_PROPERTY_NO_DOGS_DECISION_OPTIONS,
    RowError,
    AMENITY_INTERNET_SPEED_OPTIONS,
    INTERNET_SPEED_PROPERTY_ID,
    HIGH_SPEED_PROPERTY_ID,
    LOW_SPEED_OPTIONS,
} from './constants';

const alwaysShow: string[] = [CommonField.UnitID];

/**
 * Specification for fixing fields always inluded in the template. For
 * dynamically included columns, `ParseConfig.addFixSpecs()` in `model.ts` is
 * applied.
 */
export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-unit-id',
        label: 'Invalid Unit ID',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-employee-id',
        label: 'Invalid Employee ID',
        editFields: [CommonField.CreatedBy],
        showFields: alwaysShow.concat([CommonField.CreatedBy]),
    },
    {
        key: 'invalid-no-dog-decision',
        label: 'Invalid "No Dog Decision", it should be one of the followings: \n' + AMENITY_PROPERTY_NO_DOGS_DECISION_OPTIONS.join(',\n'),
        triggeredBy: [RowError.InvalidNoDogDecision],
        editFields: [`${AmenityID.DogFriendly}${headerDelimiter}${NO_DOGS_DECISION_PROPERTY_ID}`],
        showFields: [...alwaysShow, `${AmenityID.DogFriendly}${headerDelimiter}${NO_DOGS_DECISION_PROPERTY_ID}`],
    },
    {
        key: 'invalid-max-dogs-property',
        label: 'Invalid "Max Dogs Property", it should be a number between 1 and 10',
        triggeredBy: [RowError.InvalidMaxDogsProperty],
        editFields: [`${AmenityID.DogFriendly}${headerDelimiter}${MAX_DOGS_PROPERTY_ID}`],
        showFields: [...alwaysShow, `${AmenityID.DogFriendly}${headerDelimiter}${MAX_DOGS_PROPERTY_ID}`],
    },
    {
        key: 'invalid-internet-speed-property',
        label: 'Invalid "Internet Speed", it should be one of the followings: \n' + AMENITY_INTERNET_SPEED_OPTIONS.join(',\n'),
        triggeredBy: [RowError.InvalidInternetSpeed],
        editFields: [`${AmenityID.Internet}${headerDelimiter}${INTERNET_SPEED_PROPERTY_ID}`],
        showFields: [...alwaysShow, `${AmenityID.Internet}${headerDelimiter}${INTERNET_SPEED_PROPERTY_ID}`],
    },
];

export function verifyAmenity(...rows: ParsedRow[]) {
    rows.forEach(row => {
        //Dog Friendly
        if (!_.isNil(row[AmenityID.DogFriendly])) {
            if (
                row[AmenityID.DogFriendly] === 1 &&
                !AMENITY_PROPERTY_MAX_DOGS_OPTIONS.includes(row[`${AmenityID.DogFriendly}${headerDelimiter}${MAX_DOGS_PROPERTY_ID}`])
            ) {
                row._invalid.add(RowError.InvalidMaxDogsProperty);
            }

            if (row[AmenityID.DogFriendly] === 1 && !_.isNil(row[`${AmenityID.DogFriendly}${headerDelimiter}${NO_DOGS_DECISION_PROPERTY_ID}`])) {
                row[`${AmenityID.DogFriendly}${headerDelimiter}${NO_DOGS_DECISION_PROPERTY_ID}`] = 'No';
            }

            if (row[AmenityID.DogFriendly] === 0 && !_.isNil(row[`${AmenityID.DogFriendly}${headerDelimiter}${MAX_DOGS_PROPERTY_ID}`])) {
                row[`${AmenityID.DogFriendly}${headerDelimiter}${MAX_DOGS_PROPERTY_ID}`] = 0;
            }

            if (
                row[AmenityID.DogFriendly] === 0 &&
                !AMENITY_PROPERTY_NO_DOGS_DECISION_OPTIONS.includes(row[`${AmenityID.DogFriendly}${headerDelimiter}${NO_DOGS_DECISION_PROPERTY_ID}`])
            ) {
                row._invalid.add(RowError.InvalidNoDogDecision);
            }
        }
        //Internet
        if (!_.isNil(row[AmenityID.Internet])) {
            const internetSpeedOption: string = row[`${AmenityID.Internet}${headerDelimiter}${INTERNET_SPEED_PROPERTY_ID}`];
            if (internetSpeedOption) {
                if (row[AmenityID.Internet] === 1 && !AMENITY_INTERNET_SPEED_OPTIONS.includes(internetSpeedOption)) {
                    row._invalid.add(RowError.InvalidInternetSpeed);
                }
                if (row[AmenityID.Internet] === 1 && AMENITY_INTERNET_SPEED_OPTIONS.includes(internetSpeedOption)) {
                    //When internet speed is greater than 25 mb we should mark as high speed
                    if (LOW_SPEED_OPTIONS.includes(internetSpeedOption)) {
                        row[`${AmenityID.Internet}${headerDelimiter}${HIGH_SPEED_PROPERTY_ID}`] = '0';
                    } else {
                        row[`${AmenityID.Internet}${headerDelimiter}${HIGH_SPEED_PROPERTY_ID}`] = '1';
                    }
                }
            }
        }
    });
}
