import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ContractsView: FunctionComponent = () => (
    <UploadView
        id="contract-upload-view"
        requiredRole={Role.Contracts}
        label="Contract"
        apiPath="contracts"
        uploadType="contract"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
    />
);
