import { Field, RowError } from './model';
export const PERMIT_RULE_CODE = 'CMPLNC-PERMITS';
export const UNIT_STAY_RULE_CODE = 'UNITSTAYRESTRICTION';
export const CHECK_IN_REQUIREMENTS = 'CHECK-IN-REQUIREMENTS';

const isPermitRuleCode = (ruleCode: string) => ruleCode === PERMIT_RULE_CODE;
const isUnitStayRuleCode = (ruleCode: string) => ruleCode === UNIT_STAY_RULE_CODE;
export const isCheckInRuleCode = (ruleCode: string) => ruleCode === CHECK_IN_REQUIREMENTS;

export function validateRuleCode(...rows: ParsedRow[]) {
    rows.forEach(r => {
        if (isPermitRuleCode(r[Field.RuleCode.name]) || isUnitStayRuleCode(r[Field.RuleCode.name])) {
            r._invalid.add(RowError.InvalidRuleCode);
        }
    });
}

export function formatValueCode(...rows: ParsedRow[]) {
    rows.forEach(r => {
        r[Field.ValueCode.name] = r[Field.ValueCode.name].trim().toUpperCase();
    });
}
