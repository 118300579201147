import { amenityPropertiesForID, lookupAmenities } from './api';
import { CommonField, unitIdField, createdByField } from '../util/model';
import { DataType, Pattern } from '../util';
import { IncludeProperty, headerDelimiter, AmenityID, INTERNET_SPEED_PROPERTY_ID, HIGH_SPEED_PROPERTY_ID } from './constants';
import { verifyAmenity } from './fix-specs';

export const Field = {
    Amenities: 'amenities',
};

/**
 * Parse amenity and property ID from colon-delimited value. Amenity ID is
 * always numeric but property ID can also be a string.
 */
const parsePropertyID = (value: string) => value.split(headerDelimiter).map((v, i) => (i === 0 ? parseInt(v) : v)) as [number, string | number];

/** Infer validation type from field type */
const validationType = (t: AmenityValueType): number => {
    switch (t) {
        case 'binary':
            return DataType.Bit;
        case 'number':
            return DataType.Integer;
        case 'numeric':
            return DataType.Float;
        case 'dayofweek':
            return DataType.DayOfWeek;
        default:
            return DataType.Text;
    }
};

/**
 * Create model based on specially formatted headers
 *
 * @param amenities All available amenities
 * @param headerFields CSV header cells
 */
export async function inferModel(amenities: Amenity[], headerFields: string[] | undefined): Promise<Model> {
    const model: Model = [unitIdField(true)];

    if (!headerFields) return model;

    // ignore first column — it should always be unit ID
    headerFields.shift();
    // ignore last column — it should be employee ID
    headerFields.pop();

    for (const header of headerFields) {
        if (header.includes(headerDelimiter)) {
            const [amenityID, propertyID] = parsePropertyID(header);
            const a = amenities.find(a => a.id === amenityID);

            if (a === undefined) {
                throw Error(`Invalid amenity in CSV ${header}`);
            }

            const amenityProperties = await amenityPropertiesForID(a.id);
            // allow coercive equality since property ID can be string or number
            // eslint-disable-next-line eqeqeq
            const p = amenityProperties.find(p => p.property_id == propertyID);

            if (p === undefined) {
                // assume invalid if no property ID
                console.error(amenityProperties);
                throw Error(`Invalid amenity property in CSV ${header}`);
            }

            let dataType = DataType.Text;
            let maxLength: number | undefined;

            switch (p.property_id) {
                case IncludeProperty.ProviderID:
                    maxLength = 64; // a guess
                    break;
                case IncludeProperty.Notes:
                    maxLength = 255;
                    break;
                default:
                    dataType = validationType(p.field_type);
                    break;
            }

            model.push({
                name: header,
                label: a.attributes.display_name + ' ' + p.property_name,
                type: dataType,
                maxLength,
            });
        } else {
            const amenityID = parseInt(header);
            const a = amenities.find(a => a.id === amenityID);

            if (a === undefined) {
                throw Error(`Invalid amenity header CSV ${header}`);
            }

            model.push({
                name: header,
                label: a.attributes.display_name,
                type: validationType(a.attributes.value_type),
            });
        }
    }
    model.push(createdByField(true));

    return model;
}

export const parseConfig: ParseConfig = {
    model: [],
    skipRows: 2, // 3 header rows but first is shifted in beforeParse()
    // UJM-1661: stop validating unit IDs:
    //onComplete: async file => verifyUnitIDs(...file.rows),

    /**
     * Build dynamic model based on CSV headers
     */
    async beforeParse(rows: CSV) {
        let indexSpeed = rows[0].indexOf(AmenityID.Internet + headerDelimiter + INTERNET_SPEED_PROPERTY_ID);
        let indexHighSpeed = rows[0].indexOf(AmenityID.Internet + headerDelimiter + HIGH_SPEED_PROPERTY_ID);
        if (indexSpeed != -1 && indexHighSpeed == -1) {
            indexSpeed++;
            rows.map((r, i) => {
                if (i == 0) {
                    r.splice(indexSpeed, 0, AmenityID.Internet + headerDelimiter + HIGH_SPEED_PROPERTY_ID);
                } else if (i == 1) {
                    r.splice(indexSpeed, 0, '');
                } else if (i == 2) {
                    r.splice(indexSpeed, 0, 'Internet - High Speed');
                } else {
                    r.splice(indexSpeed, 0, '0');
                }
            });
        }
        const amenityList = await lookupAmenities();
        // Update cached model for use in fix-spec generation
        this.model = await inferModel(amenityList, rows.shift());
    },

    /**
     * Combine amenity properties into child object
     */
    async beforeUpload(file: ParsedFile) {
        if (file.rows.length === 0) return;

        /** CSV fields that should be preserved rather than combined */
        const preserve = [Field.Amenities, CommonField.UnitID, CommonField.CreatedBy];
        /** Map amenity and property IDs to CSV field names */
        const mapping: Record<string, AmenityFieldMap> = {};

        /** Retrieve amenity field map for amenity field name */
        const amenityMap = (key: string): AmenityFieldMap => {
            if (!mapping[key]) {
                mapping[key] = {
                    id: parseInt(key),
                    properties: {},
                    fields: {},
                };
            }
            return mapping[key];
        };

        /** Create payload amenity from CSV values */
        const makeAmenity = (row: ParsedRow, fieldName: string, a: AmenityFieldMap): ParsedAmenity =>
            addFields(row, a, {
                amenity_id: a.id,
                amenity_properties: makeProps(row, a),
                amenity_value: parseInt(row[fieldName]),
            });

        /** Add payload amenity fields from CSV values */
        const addFields = (row: ParsedRow, a: AmenityFieldMap, parsed: ParsedAmenity): ParsedAmenity => {
            Object.keys(a.fields)
                .filter(name => row[name]) // ignore non-values
                .forEach(name => (parsed[a.fields[name]] = row[name]));

            return parsed;
        };

        /** Create amenity properties from CSV values */
        const makeProps = (row: ParsedRow, a: AmenityFieldMap): ParsedProperty[] =>
            Object.keys(a.properties)
                .filter(propField => row[propField]) // ignore non-values
                .map(propField => ({
                    amenity_id: a.id,
                    property_value: row[propField],
                    idamenities_properties: a.properties[propField],
                }));

        // build map between CSV fields and payload fields based on model
        this.model.forEach(s => {
            if (s.name.includes(headerDelimiter)) {
                // property
                const [amenityField, propField] = s.name.split(headerDelimiter);
                const amenity = amenityMap(amenityField);

                if (Pattern.PositiveInteger.test(propField)) {
                    amenity.properties[s.name] = parseInt(propField);
                } else {
                    // pseudo-property
                    amenity.fields[s.name] = propField as AmenityFixedProps;
                }
            } else if (!preserve.includes(s.name)) {
                // amenity
                amenityMap(s.name);
            }
        });

        file.rows.forEach(row => {
            row[Field.Amenities] = Object.keys(mapping)
                //.filter(amenityField => row[amenityField]) // ignore non-values
                .map(amenityField => makeAmenity(row, amenityField, mapping[amenityField]));

            Object.keys(row)
                .filter(key => !preserve.includes(key))
                .forEach(key => delete row[key]);
        });
    },

    async beforeDownload(rows: ParsedRow[]) {
        return;
    },

    async onComplete(file: ParsedFile) {
        verifyAmenity(...file.rows);
    },

    /**
     * Generate one-off fix specifications for every amenity and amenity
     * property. Maybe it would be nicer to make all of an amenity properties
     * part of the same fix spec ...
     */
    addFixSpecs() {
        return this.model
            .filter(s => ![CommonField.UnitID, CommonField.CreatedBy].includes(s.name))
            .map(s => ({
                key: `invalid-${s.name}`,
                label: `Invalid ${s.label ?? s.name}`,
                showFields: [CommonField.UnitID, s.name],
                editFields: [s.name],
            }));
    },
};
