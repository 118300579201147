import { csvToArray } from './parse-file';
import { getTemplateCSV } from '../api';

/* Cached CSV template content */
export const template: Map<string, string> = new Map();

/**
 * Retrieve text of CSV template for the upload type defined by its `apiPath`.
 * @param apiPath API path for particular upload type
 */
export async function loadTemplate(templateName: string, fields: FieldSpec[]): Promise<string | null> {
    if (template.has(templateName)) return template.get(templateName)!;

    const csv = await getTemplateCSV(templateName);

    if (!csv) return null;

    /** Template parsed for validation but the cached value is the CSV text */
    const t = csvToArray(csv);

    if (!Array.isArray(t) || !Array.isArray(t[0])) {
        throw new Error(`Unable to parse template ${csv}`);
    }

    if (t.length > 1) {
        // ensure all template rows have same number of columns
        const colCount = t[0].length;
        for (let i = 1; i < t.length; i++) {
            if (t[i].length !== colCount) {
                throw new RangeError(`${templateName} template row ${i + 1} has different column count than first row (${colCount})`);
            }
        }
    }

    // assign to cache
    template.set(templateName, csv);

    return csv;
}
