import {parseNumber} from "../util";

/** validate number > 0 **/
const greaterThanZeroPattern = /^[1-9][0-9]*$/;

const validLTDExclude: number[] = [0, -1, 1, 2, 3];
const validstandardDiscount: number[] = [0, -1, 1];
const validTurnover: number[] = [0, 1,2,3,4,5,6,7];

/**
 * Parse Number > 0
 */
export const greaterThanZero = (value: string) => parseNumber(value, greaterThanZeroPattern);

export const LTDExcludeValidation = (value: number): ParseResult<any> => {
    const valid = validLTDExclude.includes(+value);
    return [valid, valid ? value : 0];
}

export const StandardDiscountsValidation = (value: number): ParseResult<any> => {
    const valid = validstandardDiscount.includes(+value);
    return [valid, valid ? value : 0];
}

export const TurnoverValidation = (value: number): ParseResult<any> => {
    const valid = validTurnover.includes(+value);
    return [valid, valid ? value : 0];
}

/**
 * Until 20 characters long
 * Not 0 or letters allowed
 * @param value
 * @constructor
 */
export const MaxCharacterValidation = (value: string): ParseResult<any> => {
    if (value.toString().length > 20)
        return [false, 0];
    return greaterThanZero(value);
}