import React, { useState, FunctionComponent } from 'react';
import './fix-table.scss';
import { DataInput } from './data-input';
import { DataType, formatCurrency, rowHasError } from '../util';

/**
 * Display inputs for an `editFields` subset of fields that may actually have
 * errors. This is to match the UX specification that has differently configured
 * visual sub-tables for various kinds of errors.
 */
const FixRow: FunctionComponent<{
    /** Row with errors */
    row: ParsedRow;
    config: ParseConfig;
    /** Specific columns (subset) to display */
    columns: FieldSpec[];
    /** Names of editable fields */
    fixSpec: FixSpec;
    onRowChange: () => Promise<void>;
}> = ({
    row,
    config,
    columns,
    fixSpec,
    onRowChange = () => Promise.resolve(),
}) => {
    const [valid, setValid] = useState(false);
    /**
     * Update field value and re-validate row
     */
    const onChange = async (name: string, value: any) => {
        row[name] = value;
        // TODO: real-time validation disabled until time can be spent to handle all corner cases

        // validate row after change but only check fields shown in the current
        // sub-table so user isn't shown an error for reasons they can't see
        // await updateRowErrors(
        //     row,
        //     columns.filter(f => editFields.includes(f.name)),
        //     // onRowUpdate may reference config instance as "this"
        //     config.onRowUpdate.bind(config)
        // );
        // await onRowChange();
        // setValid(!rowHasError(row, editFields));
    };

    return (
        <tr className={valid ? 'valid' : 'invalid'}>
            <td id="row-index" className="index">
                {/*Fix visual where it was displaying row 0 instead of 1*/}
                {row._index + 1}
            </td>
            {columns.map(c => {
                /** React cache key */
                const key = `${fixSpec.key}-${row._index}-${c.name}`;
                const editable = fixSpec.editFields.includes(c.name);
                let value = row[c.name];
                let textHint = '';

                if (c.type === DataType.Float) {
                    value = formatCurrency(value);
                } else if (!editable && c.table && c.table.summarize) {
                    // display sub-table summary
                    value = c.table.summarize(value);
                }

                if (row._totals_off?.has(c.name)) {
                    const offBy = row._totals_off.get(c.name)!;
                    const offByText = formatCurrency(offBy);

                    textHint = `Differs by ${
                        Math.sign(offBy) === 1 ? `+${offByText}` : offByText
                    }`;
                }

                return editable ? (
                    <td key={key} className="edit">
                        <DataInput
                            cellKey={key}
                            onChange={v => onChange(c.name, v)}
                            value={value}
                            spec={c}
                        />
                        {textHint && (
                            <div className="pillbox-invalid">{textHint}</div>
                        )}
                    </td>
                ) : (
                    // non-editable field
                    <td key={key}>{value}</td>
                );
            })}
        </tr>
    );
};

/**
 * Display field inputs for a `showFields` subset of `config`ured fields. These
 * inputs should allow corrections to invalid data which may then be
 * re-validated.
 */
export const FixTable: FunctionComponent<{
    file: ParsedFile;
    config: ParseConfig;
    fixSpec: FixSpec;
}> = ({ file, config, fixSpec }) => {
    const triggers = fixSpec.triggeredBy ?? fixSpec.editFields;
    /** All rows with errors in the fields that can be edited */
    const errorRows = file.rows.filter(f => rowHasError(f, triggers));

    // nothing to be done if there are no errors or they aren't fixable
    if (errorRows.length === 0) return null;

    const onRowChange = async () => {
        // should be triggered after onRowUpdate() so pertinent errors will
        // already have been reset
        if (config.onUpdate) await config.onUpdate(file, fixSpec);
    };

    /** Specifications for specific fields to be shown */
    const columns: FieldSpec[] = fixSpec.showFields.map(n => {
        const spec = config.model.find(m => m.name === n);
        if (spec) return spec;
        throw Error(`Field name ${n} is not defined`);
    });

    return (
        <table className="fix-table">
            <thead>
                <tr>
                    <th>Row</th>
                    {columns.map(c => (
                        <th key={c.name}>{c.label || c.name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {errorRows.map(r => (
                    <FixRow
                        row={r}
                        key={`row-${fixSpec.key}-${r._index}`}
                        config={config}
                        columns={columns}
                        fixSpec={fixSpec}
                        onRowChange={onRowChange}
                    />
                ))}
            </tbody>
        </table>
    );
};
