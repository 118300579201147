import { Field, RowError } from './model';
import { CommonField } from '../util/';

const alwaysShow: string[] = [
    CommonField.UnitID,
    Field.ListingChannelReservationID,
];

const moneyFields = alwaysShow.concat([
    Field.Rent,
    Field.Fees,
    Field.Taxes,
    Field.Paid,
    Field.Total,
    Field.CurrencyUsed,
]);

const contactFields = alwaysShow.concat([
    CommonField.FirstName,
    CommonField.LastName,
    CommonField.Email,
    CommonField.Phone,
    Field.Phone2,
]);

const basicFields = alwaysShow.concat([Field.SourceID, Field.Type]);
const holdTypeFields = alwaysShow.concat([Field.Type, Field.HoldType]);
const InternalAndExternalFields = alwaysShow.concat([Field.Type, Field.HoldType, Field.InternalNotes, Field.ExternalNotes]);
const dateFields = alwaysShow.concat([Field.Arrival, Field.Departure]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-unit-id',
        label: 'Unit ID not found',
        editFields: [CommonField.UnitID],
        showFields: basicFields,
    },
    {
        key: 'mismatched-total',
        label: 'Total does not match',
        editFields: [Field.Total, Field.Rent, Field.Taxes, Field.Fees],
        triggeredBy: [RowError.InvalidTotal],
        showFields: moneyFields,
    },
    {
        key: 'missing-tax-or-fee',
        label: 'Fee ID / Tax ID has missing / incomplete pair',
        editFields: [Field.Taxes, Field.Fees],
        showFields: moneyFields,
    },
    {
        key: 'missing-notes',
        label: 'Notes value is missing',
        editFields: [Field.Notes],
        showFields: alwaysShow.concat([Field.Notes]),
    },
    {
        key: 'overlapping-dates',
        label: 'Dates overlap',
        editFields: [Field.Arrival, Field.Departure],
        triggeredBy: [RowError.DateConflict],
        showFields: dateFields,
    },
    {
        key: 'invalid-dates',
        label: 'Invalid dates',
        editFields: [Field.Arrival, Field.Departure],
        showFields: dateFields,
    },
    {
        key: 'invalid-guest-count',
        label: 'Adults / Children / Pets value not valid',
        editFields: [Field.Adults, Field.Children, Field.Pets],
        showFields: alwaysShow.concat([
            Field.Adults,
            Field.Children,
            Field.Pets,
        ]),
    },
    {
        key: 'invalid-currency-code',
        label: 'Booked Currency Code does not match',
        editFields: [Field.CurrencyUsed],
        showFields: moneyFields,
    },
    {
        key: 'invalid=email',
        label: 'Email value not valid',
        editFields: [CommonField.Email],
        showFields: contactFields,
    },
    {
        key: 'invalid-cluster-clean-or-auto-pay-choice',
        label: 'Cluster / Auto Pay / Clean After Stay value not valid',
        editFields: [Field.Cluster, Field.AutoPay, Field.CleanAfterStay],
        showFields: alwaysShow.concat([Field.Cluster, Field.AutoPay, Field.CleanAfterStay]),
    },
    {
        key: 'invalid-type',
        label: 'Type value not valid',
        editFields: [Field.Type],
        showFields: basicFields,
    },
    {
        key: 'invalid-subtype',
        label: 'Hold Type value not valid',
        editFields: [Field.HoldType],
        showFields: holdTypeFields,
    },
    {
        key: 'invalid-listing-channel-reservation-id',
        label: 'Listing Channel Reservation ID not valid',
        editFields: [Field.ListingChannelReservationID],
        showFields: basicFields,
    },
    {
        key: 'invalid-name',
        label: 'First or last name not valid',
        editFields: [CommonField.FirstName, CommonField.LastName],
        showFields: contactFields,
    },
    {
        key: 'invalid-phone',
        label: 'Phone value not valid',
        editFields: [CommonField.Phone, Field.Phone2],
        showFields: contactFields,
    },
    {
        key: 'invalid-paid',
        label: 'Paid value not valid',
        editFields: [Field.Paid],
        showFields: moneyFields,
    },
    {
        key: 'invalid-employee-id',
        label: 'Invalid Employee ID',
        editFields: [CommonField.CreatedBy],
        showFields: alwaysShow.concat([CommonField.CreatedBy]),
    },
    {
        key: 'invalid-internal-external-notes',
        label: 'Invalid Internal Notes',
        editFields: [Field.Type, Field.HoldType, Field.InternalNotes, Field.ExternalNotes],
        showFields: InternalAndExternalFields,
    },
    {
        key: 'invalid-source-booking-date',
        label: 'Invalid Booking Date',
        editFields: [Field.BookingDate],
        showFields: alwaysShow.concat([Field.BookingDate]),
    },
];
