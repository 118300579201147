import React, { FunctionComponent } from 'react';
import './spinner.scss';

/**
 * @see https://codepen.io/mrrocks/pen/EiplA
 */
export const Spinner: FunctionComponent<{
    /** Circle circumference */
    size?: number;
    /** Circle line thickness */
    thickness?: number;
}> = ({ size, thickness }) => {
    if (!size) size = 66;
    if (!thickness) thickness = 6;

    return (
        <svg
            className="spinner"
            width={`${size - 1}px`}
            height={`${size - 1}px`}
            viewBox={`0 0 ${size} ${size}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                className="path"
                fill="none"
                strokeWidth={thickness}
                strokeLinecap="round"
                cx={size / 2}
                cy={size / 2}
                r={(size - thickness) / 2}
            ></circle>
        </svg>
    );
};
