import { getURL, parseJSON, userHeaders } from '../api';

// unecessary to expire these cached values since the page in all likelihood
// will be reloaded before they're ever changed in admin
const cache = {
    programs: [] as Program[],
    programFields: new Map<number | string, ProgramField[]>(),
};

/** Complete list of Vacasa programs */
export async function getPrograms(): Promise<Program[] | null> {
    if (cache.programs.length > 0) return cache.programs;
    // otherwise fetch
    const url = getURL('unit/programs/programs-list');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        const maybe = await parseJSON<Program[]>(res, url);
        if (maybe) cache.programs = maybe;
        return maybe;
    } else {
        console.error('Failed to get list of programs');
        return null;
    }
}

export async function getProgramFields(
    programID: string | number
): Promise<ProgramField[] | null> {
    if (cache.programFields.has(programID)) {
        return cache.programFields.get(programID)!;
    }
    // otherwise fetch
    const url = getURL(`unit/programs/program-fields/${programID}`);
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        const maybe = await parseJSON<ProgramField[]>(res, url);
        if (maybe) cache.programFields.set(programID, maybe);
        return maybe;
    } else {
        console.error('Failed to get list of programs');
        return null;
    }
}
