import {parseNumber} from "../util";

/** validate number > 0 **/
const greaterThanZeroPattern = /^[1-9][0-9]*$/;

/** Hard-coded unit type ids **/
const validUnitTypesIds: number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];


/**
 * Parse Number > 0
 */
export const greaterThanZero = (value: string) => parseNumber(value, greaterThanZeroPattern);

export const onlyValidUnitTypes = (value: number): ParseResult<any> => {
    const valid = validUnitTypesIds.includes(+value);
    return [valid, valid ? value : 0];
}
