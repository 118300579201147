import { Field } from './model';
import { CommonField } from '../util';
import { RowError } from '../reservation-rules/model';

const alwaysShow: string[] = [
    CommonField.UnitID,
    Field.RuleCode.name,
    Field.ValueCode.name,
    Field.StartDate.name,
    Field.EndDate.name,
    Field.Notes.name,
];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit ID is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'rule-code-is-required',
        label: 'Rule Code is required',
        editFields: [Field.RuleCode.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'value-code-is-required',
        label: 'Value Code is required',
        editFields: [Field.ValueCode.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'start-date-is-required',
        label: 'Start date is required',
        editFields: [Field.StartDate.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'invalid-rule-code',
        label: 'Rule code is not allowed',
        triggeredBy: [RowError.InvalidRuleCode],
        editFields: [Field.RuleCode.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'required-rule-attributes',
        label: 'Unit Rule Attributes is required for Check-in Rule. Edit the field in .csv file',
        editFields: [Field.UnitRuleAttributes.name],
        triggeredBy: [RowError.RequiredUnitRuleAttribute],
        showFields: [ CommonField.UnitID, Field.RuleCode.name, Field.ValueCode.name, Field.UnitRuleAttributes.name],
    },
    {
        key: 'required_notes',
        label: 'Unit Rule Notes is required',
        editFields: [Field.Notes.name],
        showFields: [...alwaysShow],
    },
];
