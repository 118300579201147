import { DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import {
    validatePermitType,
    validatePermitDescription,
    validateHasRenewalDate,
    validatePermitQuantity,
    validatePermitId,
    validateEffectiveDate,
} from './parsers';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    RecordId: {
        name: 'record_id',
        label: 'Record Id',
    },
    PropertyPermitId: {
        name: 'property_permit_id',
        label: 'Property Permit Id',
    },
    DisplayPublicPermitId: {
        name: 'display_public_permit_id',
        label: 'Display Public Permit Id',
    },
    PermitEffectiveDate: {
        name: 'permit_effective_date',
        label: 'Permit Effective Date',
    },
    PermitRenewalDate: {
        name: 'permit_renewal_date',
        label: 'Permit Renewal Date',
    },
    PermitType: {
        name: 'permit_type',
        label: 'Permit Type',
    },
    PermitDescription: {
        name: 'permit_description',
        label: 'Permit Description',
    },
};

export const RowError = {
    InvalidPermitType: 'invalid_permit_type',
    PermitDescriptionRequired: 'permit_description_required',
    InvalidRenewalDate: 'invalid_renewal_date',
    MaxPermitQuantityPerUnit: 'max_permit_quantity_per_unit',
    InvalidPermitId: 'invalid_permit_id',
    InvalidEffectiveDate: 'invalid_effective_date',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const permitsModel: Model = [
    unitIdField(),
    {
        ...Field.RecordId,
        type: DataType.Integer
    },
    {
        name: Field.PropertyPermitId.name,
        label: Field.PropertyPermitId.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.DisplayPublicPermitId.name,
        label: Field.DisplayPublicPermitId.label,
        type: DataType.Boolean,
        required: true,
    },
    {
        name: Field.PermitEffectiveDate.name,
        label: Field.PermitEffectiveDate.label,
        type: DataType.Date,
    },
    {
        name: Field.PermitRenewalDate.name,
        label: Field.PermitRenewalDate.label,
        type: DataType.Date,
    },
    {
        name: Field.PermitType.name,
        label: Field.PermitType.label,
        type: DataType.Integer,
        required: true,
    },
    {
        name: Field.PermitDescription.name,
        label: Field.PermitDescription.label,
        type: DataType.Text,
    },
];

export const parseConfig: ParseConfig = {
    model: permitsModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        validatePermitType(...file.rows);
        validatePermitDescription(...file.rows);
        validateHasRenewalDate(...file.rows);
        validatePermitQuantity(...file.rows);
        validatePermitId(...file.rows);
        validateEffectiveDate(...file.rows);
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
        validatePermitType(row);
        validatePermitDescription(row);
        validateHasRenewalDate(row);
        validatePermitId(row);
        validateEffectiveDate(row);
    },
};
