import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ReserveView: FunctionComponent = () => (
    <UploadView
        id="reserve-view"
        requiredRole={Role.OwnerReserve}
        label="Owners Reserve"
        apiPath="unit/reserve"
        uploadType="unit_reserve_balance"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="unit_reserve_balance"
        entityCSVModel={ReserveBalanceUpdateCSVModel}
    />
);

const ReserveBalanceUpdateCSVModel: Array<string> = [
    'ReserveBalance',
];

