import { DataType, isNullorEmpty, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import { formatValueCode, isCheckInRuleCode, validateRuleCode } from './parsers';

/**
 * Unique field names required by the Unit service API.
 */
export const Field = {
    RecordId: {
        name: 'record_id',
        label: 'Record ID'
    },
    RuleCode: {
        name: 'rule_code',
        label: 'Rule Code',
    },
    ValueCode: {
        name: 'value_code',
        label: 'Value Code',
    },
    Notes: {
        name: 'notes',
        label: 'Notes'
    },
    StartDate: {
        name: 'start_date',
        label: 'Start Date',
    },
    EndDate: {
        name: 'end_date',
        label: 'End Date',
    },
    UnitRuleAttributes: {
        name: 'unit_rule_value_attributes',
        label: 'Unit Rule Attributes',
    }
};

export const RowError = {
    InvalidStartDate: 'invalid-start-date',
    InvalidRuleCode: 'invalid_rule_code',
    RequiredUnitRuleAttribute: 'required_unit_rule_value_attributes',
    RequiredNotes: 'required_notes'

};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const resRulesModel: Model = [
    unitIdField(true),
    {
        name: Field.RecordId.name,
        label: Field.RecordId.label,
        type: DataType.Text,
        required: false
    },
    {
        name: Field.RuleCode.name,
        label: Field.RuleCode.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.ValueCode.name,
        label: Field.ValueCode.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.Notes.name,
        label: Field.Notes.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.StartDate.name,
        label: Field.StartDate.label,
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.EndDate.name,
        label: Field.EndDate.label,
        type: DataType.Date,
        required: false,
    },
    {
        name: Field.UnitRuleAttributes.name,
        label: Field.UnitRuleAttributes.label,
        type: DataType.Table,
        table: {
            fields: [
                {
                    name: 'requirement',
                    label: 'Requirement',
                    type: DataType.Text,
                    required: true
                },
                {
                    name: 'value',
                    label: 'Value',
                    type: DataType.Boolean,
                    required: true
                }
            ]
        },
        required: false,
    }
];

function parseCheckinRuleAttributes(checkinRuleAttribute: Array<{requirement: string, value: boolean}>) {
    let attributes: any = {}
    checkinRuleAttribute.map(attr => {
        attributes[attr['requirement']] =  attr['value'];
    })
    return attributes;
}



export const parseConfig: ParseConfig = {
    model: resRulesModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        validateRuleCode(...file.rows);
        formatValueCode(...file.rows);
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
        validateRuleCode(row);
        formatValueCode(row);
    },
    async beforeUpload(file) {
        file.rows.forEach(row => {
            if (isCheckInRuleCode(row[Field.RuleCode.name])) {
                if (!isNullorEmpty(row[Field.UnitRuleAttributes.name]))
                    row[Field.UnitRuleAttributes.name] = parseCheckinRuleAttributes(row[Field.UnitRuleAttributes.name])
            }
        });
    },
    async onRowParse(row) {
        if (isCheckInRuleCode(row[Field.RuleCode.name])) {
            if (isNullorEmpty(row[Field.UnitRuleAttributes.name]))
                row._invalid.add(RowError.RequiredUnitRuleAttribute);
        }
    }
};
