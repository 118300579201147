import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from '../constants';
import { DynamicTemplate } from './dynamic-template';

export const BedToRoomView: FunctionComponent = () => (
    <UploadView
        id="bed-to-room-upload-view"
        requiredRole={Role.BedToRoom}
        label="Bed To Room"
        apiPath="unit/bed-to-room"
        uploadType="bed_to_room"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="Room"
        customFilter={DynamicTemplate.createDynamicTemplate}
    />
);
