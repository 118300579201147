import { DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import { validateEndDate, validateOverride, validateOverrideEffectiveDate, validateOverrideEndDate, validateOverrideNotes, validateRequiredPermits, validateRuleCode, validateStartDate } from './parsers';

/**
 * Unique field names required by the Unit service API.
 */
export const Field = {
    RecordId: {
        name: 'record_id',
        label: 'Record ID'
    },
    RuleCode: {
        name: 'rule_code',
        label: 'Rule Code',
    },
    ValueCode: {
        name: 'value_code',
        label: 'Value Code',
    },
    Notes: {
        name: 'notes',
        label: 'Notes'
    },
    StartDate: {
        name: 'start_date',
        label: 'Start Date',
    },
    EndDate: {
        name: 'end_date',
        label: 'End Date',
    },
    RequiredPermits: {
        name: 'unit_rule_value_attributes.number_permits_req',
        label: 'Required Permits'
    },
    Override: {
        name: 'unit_rule_value_attributes.override_requirement',
        label: 'Override'
    },
    OverrideEffectiveDate: {
        name: 'unit_rule_value_attributes.override_effective_date',
        label: 'Override Effective Date'
    },
    OverrideEndDate: {
        name: 'unit_rule_value_attributes.override_end_date',
        label: 'Override End Date'
    },
    OverrideNotes: {
        name: 'unit_rule_value_attributes.override_notes',
        label: 'Override Notes'
    },    
};

export const RowError = {
    InvalidRequiredPermits: 'invalid_required_permits',
    InvalidRuleCode: 'invalid_rule_code',
    InvalidEndDate: 'invalid_end_date',
    InvalidStartDate: 'invalid_start_date',
    StartDateRequired: 'start_date_required',
    InvalidOverride: 'invalid_override',
    InvalidOverrideEffectiveDate: 'invalid_override_effective_date',
    OverrideEffectiveDateRequired: 'override_effective_date_required',
    InvalidOverrideEndDate: 'invalid_override_end_date',
    InvalidOverrideNotes: 'invalid_override_notes',
    OverrideNotesRequired: 'override_notes_required'
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 */
const resRulesModel: Model = [
    unitIdField(true),
    {
        name: Field.RecordId.name,
        label: Field.RecordId.label,
        type: DataType.Text,
        required: false
    },
    {
        name: Field.RuleCode.name,
        label: Field.RuleCode.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.ValueCode.name,
        label: Field.ValueCode.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.Notes.name,
        label: Field.Notes.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.StartDate.name,
        label: Field.StartDate.label,
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.EndDate.name,
        label: Field.EndDate.label,
        type: DataType.Date,
        required: false,
    },
    {
        name: Field.RequiredPermits.name,
        label: Field.RequiredPermits.label,
        type: DataType.PositiveInteger,
        required: false,
    },
    {
        name: Field.Override.name,
        label: Field.Override.label,
        type: DataType.Boolean,
        required: false,
    },
    {
        name: Field.OverrideEffectiveDate.name,
        label: Field.OverrideEffectiveDate.label,
        type: DataType.Date,
        required: false,
    },
    {
        name: Field.OverrideEndDate.name,
        label: Field.OverrideEndDate.label,
        type: DataType.Date,
        required: false,
    },
    {
        name: Field.OverrideNotes.name,
        label: Field.OverrideNotes.label,
        type: DataType.Text,
        required: false,
    }    
];

export const parseConfig: ParseConfig = {
    model: resRulesModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        validateRequiredPermits(...file.rows);
        validateRuleCode(...file.rows);
        validateStartDate(...file.rows);
        validateEndDate(...file.rows);
        validateOverride(...file.rows);
        validateOverrideEffectiveDate(...file.rows);
        validateOverrideEndDate(...file.rows);
        validateOverrideNotes(...file.rows);
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
        validateRequiredPermits(row);
        validateRuleCode(row);
        validateStartDate(row);
        validateRuleCode(row);
        validateOverride(row);
        validateOverrideEffectiveDate(row);
        validateOverrideEndDate(row);
        validateOverrideNotes(row);
    },
};
