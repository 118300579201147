import {parseNumber} from "../util";


/** validate numeber > 0 **/
const greaterThanZeroPattern = /^[1-9][0-9]*$/;

/**
 * Parse Number > 0
 */
export const greaterThanZero = (value: string) => parseNumber(value, greaterThanZeroPattern);
