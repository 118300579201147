import { DataType } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import {
    validateAndParseBedRooms,
    validateRowsSize,
    validateDuplicateUnitIds,
    fillRowFields,
    parseForBackend,
    validateUnassignedRooms,
} from './parsers';
import * as _ from 'lodash';

export let BedRoomFields = {};

export const RowError = {
    InvalidBeds: 'invalid_beds',
    InvalidFile: 'invalid_file',
    DuplicatedUnitIds: 'duplicated_unit_ids',
    UnitWithEmptyRooms: 'unit_with_empty_rooms',
    UnitWithMultipleUnassignedRooms: 'unit_with_multiple_unassigned_rooms',
};

function inferModel(headerFields: Array<string>): Model {
    BedRoomFields = {};
    return headerFields.map((hf: string) => {
        const fieldObject = { name: hf, label: hf.replace(/_/g, ' ') };

        _.set(BedRoomFields, hf, fieldObject);
        return {
            ...fieldObject,
            type: hf === 'unit_id' ? DataType.Integer : DataType.Text,
        };
    });
}

export const parseConfig: ParseConfig = {
    async beforeParse(rows: CSV) {
        this.model = inferModel(rows[1]);
    },

    model: [],
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        fillRowFields(...file.rows);
        verifyUnitIDs(...file.rows);
        validateAndParseBedRooms(...file.rows);
        validateRowsSize(...file.rows);
        validateDuplicateUnitIds(...file.rows);
        validateUnassignedRooms(...file.rows);
        file.rows = parseForBackend(...file.rows);
    },
    async onRowUpdate(row, fields) {
        validateAndParseBedRooms(row);
        verifyUnitIDs(row);
    },
};
