import { DataType } from '../util';
import { greaterThanZero } from '../util/parse-field';
import { verifyTaxIDandEIN, verifyRequired } from './parsers';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_ENDPOINT = 'YYYY-MM-DD';

export const Field = {
    ContactEmail: 'contactEmail',
    TaxEntityName: 'taxEntityName',
    TaxId: 'taxId',
    TaxFormCodeId: 'taxFormCodeId',
    TaxClassificationType: 'taxClassificationType',
    TaxClassification: 'taxClassification',
    TaxClassificationOther: 'taxClassificationOther',
    ExemptPayeeCode: 'exemptPayeeCode',
    ExemptFatcaCode: 'exemptFatcaCode',
    Ein: 'ein',
    Signature: 'signature',
    SignDate: 'signDate',
    IpAddress: 'ipAddress',
    CreatedBy: 'createdBy',
};

export const RowError = {
    InvalidTaxIDandEIN: 'invalid_tax_id_and_ein',
    EmptyTaxIDandEIN: 'empty_tax_id_and_ein',
    RequiredTaxClassificationType: 'required_tax_classification_type',
    RequiredTaxClassification: 'requires_tax_classification',
    RequiredTaxClassificationOther: 'requires_tax_classification_other',
    RequiredExemptPayeeCode: 'requires_exempt_payee_code',
    RequiredExemptFatcaCode: 'requires_exempt_fatca_code',
    RequiredSignature: 'requires_signature',
    RequiredSignDate: 'requires_signdate',
    InvalidSignDateFormat: 'invalid_signdate_format',
    RequiredIpAddress: 'requires_ip_address',
    RequiredCreatedBy: 'requires_created_by',
};

const model: Model = [
    {
        name: Field.ContactEmail,
        label: 'Owner E-mail',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.TaxEntityName,
        label: 'Tax Entity Name',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.TaxId,
        label: 'Tax ID(SSN)',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.TaxFormCodeId,
        label: 'Tax Form Code',
        type: DataType.Integer,
        required: true,
        parse: greaterThanZero,
    },
    {
        name: Field.TaxClassificationType,
        label: 'Tax Classification Type',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.TaxClassification,
        label: 'Tax Classification',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.TaxClassificationOther,
        label: 'Tax Tax Classification Other',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.ExemptPayeeCode,
        label: 'Exempt Payee Code',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.ExemptFatcaCode,
        label: 'Exempt FATCA Code',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.Ein,
        label: 'EIN',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.Signature,
        label: 'Signature',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.SignDate,
        label: 'Sign Date',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.IpAddress,
        label: 'Ip Address',
        type: DataType.Text,
        required: false,
    },
    {
        name: Field.CreatedBy,
        label: 'Created By',
        type: DataType.Text,
        required: true,
    },
];

export const parseConfig: ParseConfig = {
    model: model,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyRequired(...file.rows);
        verifyTaxIDandEIN(...file.rows);
    },
};
