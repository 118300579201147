import { Field, RowError } from './model';
import { CommonField } from '../util';

const alwaysShow: string[] = [CommonField.UnitID];
const slugFields = alwaysShow.concat([
    Field.UnitBlockTypeId.name,
    Field.Note.name,
]);
const daysFields = alwaysShow.concat([Field.DaysOut.name]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit Id is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'unit-id-is-required',
        label: 'Unit Id is not valid',
        editFields: [Field.UnitBlockId.name],
        showFields: alwaysShow,
    },
    {
        key: 'days-out-is-not-valid',
        label: 'Days out is not valid',
        editFields: [Field.DaysOut.name],
        triggeredBy: [RowError.InvalidDaysOut],
        showFields: daysFields,
    },
    {
        key: 'invalid-unit-blocks-type-id',
        label: 'Invalid Unit Blocks Type Id',
        editFields: [Field.UnitBlockTypeId.name],
        triggeredBy: [RowError.InvalidBlockTypeId],
        showFields: slugFields,
    },
    {
        key: 'note-is-required',
        label: 'Note is required',
        editFields: [Field.Note.name],
        showFields: slugFields,
    },
];
