import { rowHasError } from './index';
import { lookupUnitIDs } from './api';

export type Loader = () => Promise<null | Cacheable[]>;

const maxAgeMinutes = 60;

/**
 * Manage cache and validation of fields that contain only a foreign key, the
 * `id` of some other entity
 */
export class LookupCache {
    /** CSV row field key that should refer to one of the IDs */
    private fieldKey: string;
    /** Method to load IDs from data source */
    private loader: Loader;
    /** Timestamp when IDs were last loaded */
    private asOf = 0;
    /** Set of valid IDs */
    private ids = new Set();

    constructor(fieldKey: string, loader: Loader) {
        this.fieldKey = fieldKey;
        this.loader = loader;
    }

    /** Load all valid ids */
    private async refresh() {
        const data = await this.loader();

        if (!data)
            throw new Error(`Unable to retrieve IDs for ${this.fieldKey}`);

        this.ids.clear();

        data.map(c => c.id)
            .map(Number)
            .forEach(id => this.ids.add(id));

        this.asOf = new Date().getTime();
    }

    /**
     * Ensure value at position `fieldKey` in all `rows` is one of the valid
     * `ids` values
     */
    async validate(rows: ParsedRow[]) {
        this.removeExpired();
        if (this.ids.size === 0) await this.refresh();

        // only process rows that don't already have an error in the same field
        rows.filter(r => !rowHasError(r, [this.fieldKey])).forEach(row => {
            const value: any = row[this.fieldKey];
            if (!this.ids.has(value)) row._invalid.add(this.fieldKey);
        });
    }

    /** Remove cached entity IDs older than an hour */
    private removeExpired() {
        const minTime = new Date().getTime() - maxAgeMinutes * 60 * 1000;

        if (this.asOf && this.asOf < minTime) this.ids.clear();
    }
}

/** Cache API unit response per unit ID */
export const unitIdLookupCache: Map<number, UnitStatus> = new Map();

/**
 * Remove unit ID statuses older than an hour
 */
export function removeStale() {
    const minTime = new Date().getTime() - maxAgeMinutes * 60 * 1000;

    unitIdLookupCache.forEach((unit, key) => {
        if (unit.asOf < minTime) unitIdLookupCache.delete(key);
    });
}

/**
 * Ensure all entity IDs are known good or bad
 */
export async function ensureCached(entityIDs: number[]) {
    removeStale();
    /** entity IDs that aren't in cache */
    const needLookup = entityIDs.filter(id => !unitIdLookupCache.has(id));
    if (needLookup.length === 0) return;
    const status = await lookupUnitIDs(needLookup);

    if (!status || !Array.isArray(status)) {
        throw Error('Unrecognized unit lookup response');
    }
    const now = new Date().getTime();

    status.forEach(u => {
        unitIdLookupCache.set(parseInt(u.unit_id), {
            valid: true,
            asOf: now,
            currencyCode: u.attributes.currency_code,
        });
    });

    // any queried unit ID not in the response is invalid
    needLookup
        .filter(id => !status.find(u => parseInt(u.unit_id) === id))
        .forEach(id => {
            unitIdLookupCache.set(id, {
                valid: false,
                asOf: now,
            });
        });
}
