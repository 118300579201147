import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from '../constants';
import { filterPastDataRanges } from './custom-filters';

export const RestDaysView: FunctionComponent = () => (
    <UploadView
        id="rest-days-upload-view"
        requiredRole={Role.RestDays}
        label="Rest Days"
        apiPath="unit/rest-days"
        uploadType="rest_days"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
        templateFlowEnabled={true}
        isConnectEntity={true}
        entityName="unit_reservation_buffers"
        entityCSVModel={RestDayCSVModel}
        customFilter={filterPastDataRanges}
    />
);

const RestDayCSVModel: Array<string> = [
    'start_date',
    'end_date',
    'buffer_days',
    'unit_reservation_buffer_reason_id',
    'notes'
];
