import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from '../constants';

export const AccountingEntityView: FunctionComponent = () => (
    <UploadView
        id="accounting-entity-upload-view"
        requiredRole={Role.AccountingEntity}
        label="Accounting Entity"
        apiPath="accounting-entity"
        uploadType="accounting_entity"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
        templateFlowEnabled={true}
        isConnectEntity={true}
        entityName="accounting_entities"
        entityCSVModel={AccountingEntityCSVModel}
        unitIdsLimit={1000}
    />
);

const AccountingEntityCSVModel: Array<string> = [
    'id',
    'accounting_entity_id',
    'start_date',
    'end_date',
];
