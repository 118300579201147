import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from '../constants';

export const DamageInsuranceView: FunctionComponent = () => (
    <UploadView
        id="damage-insurance-upload-view"
        requiredRole={Role.DamageInsurance}
        label="Program: Damage Insurance"
        apiPath="unit/damage-insurance"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        uploadType="program"
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="UnitProgram"
        entityCSVModel={DamageInsuranceCSVModel}
    />
);

const DamageInsuranceCSVModel: Array<string> = [
    'id',
    'start_date',
    'end_date',
    'is_draft',
    'notes',
    'owner_notes',
    'payer',
    'opt-in-received',
    'opt-in-effective',
    'poi-effective',
    'poi-received',
    'opt-out-received',
    'opt-out-effective',
];
