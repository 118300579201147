import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from '../constants';

export const RatesInfoUpdateView: FunctionComponent = () => (
    <UploadView
        id="rates-info-update-upload-view"
        requiredRole={Role.RatesAndHKFees}
        label="Rates/Housekeeping Fees update"
        apiPath="rates-info-update"
        uploadType="unit_rates_info"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={false}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="unit_rates_info"
        entityCSVModel={RatesInfoUpdateCSVModel}
    />
);

const RatesInfoUpdateCSVModel: Array<string> = [
    'minminstay',
    'custom_minstay_notes',
    'VacasaMinRate',
    'OwnerMinRate',
    'HoaMinRate',
    'VacasaLTRMinRate',
    'OwnerLTRMinRate',
    'HoaLTRMinRate',
    'custom_rates_notes',
    'LTDexclude',
    'StandardDiscountsExclude',
    'CleaningFee',
    'OwnerCleaningFee',
    'fee_note',
    'HousekeeperPayment',
    'HousekeeperPieceRate',
    'DeepCleanContractorPieceRate',
    'DeepCleanVacasaHousekeeperPieceRate',
    'TurnoverDay',
];
