import { CommonField, isNil } from '../util';
import { Field, RowError } from './model';

export function validatePropertyTaxIdType(...rows: ParsedRow[]) {
    rows.forEach(r => {
        if (
            r[Field.PropertyTaxIdType.name] < 1 ||
            r[Field.PropertyTaxIdType.name] > 4
        ) {
            r._invalid.add(RowError.InvalidPropertyTaxType);
        }
    });
}

export function validatePropertyTaxIdDescription(...rows: ParsedRow[]) {
    rows.forEach(r => {
        if (
            r[Field.PropertyTaxIdType.name] === 1 &&
            !r[Field.PropertyTaxIdDescription.name]
        ) {
            r._invalid.add(RowError.PropertyTaxIdDescriptionRequired);
        }
    });
}

export function validateHasRenewalDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        if (
            r[Field.PropertyTaxIdRenewalDate.name] === '' ||
            r[Field.PropertyTaxIdRenewalDate.name] === undefined
        ) {
            r[Field.PropertyTaxIdRenewalDate.name] = null;
        }
    });
}

export function validatePropertyTaxId(...rows: ParsedRow[]) {
    rows.forEach(r => {
        let regExp = new RegExp(/[^A-Za-z0-9-:#/.,)( ]+/);

        if (regExp.test(r[Field.PropertyTaxId.name])) {
            r._invalid.add(RowError.InvalidPropertyTaxId);
        }
    });
}

export function validateEffectiveDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        let effectiveDate = r[Field.PropertyTaxIdEffectiveDate.name];
        let renewalDate = r[Field.PropertyTaxIdRenewalDate.name];

        if (!isNil(effectiveDate) && !isNil(renewalDate)) {
            effectiveDate = new Date(effectiveDate).valueOf();
            renewalDate = new Date(renewalDate).valueOf();

            if (renewalDate <= effectiveDate) {
                r._invalid.add(RowError.InvalidEffectiveDate);
            }
        }
    });
}

export function validatePropertyTaxIdQuantity(...rows: ParsedRow[]) {
    const groupedUnits = rows.reduce((g, r) => {
        const id = r[CommonField.UnitID] as number;
        if (!g.hasOwnProperty(id)) g[id] = [];
        g[id].push(r);
        return g;
    }, {} as Record<number, ParsedRow[]>);

    const invalidUnitIds: Array<string> = [];

    for (const unitGroup in groupedUnits) {
        if (groupedUnits[unitGroup].length > 5) {
            invalidUnitIds.push(unitGroup);
        }
    }

    rows.forEach(r => {
        if (
            invalidUnitIds.some(iui => iui === r[CommonField.UnitID].toString())
        ) {
            r._invalid.add(RowError.MaxPropertyTaxIdQuantityPerUnit);
        }
    });
}
