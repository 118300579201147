import { isNullorEmpty } from '../util';
import { Field, RowError, DATE_FORMAT, DATE_FORMAT_ENDPOINT } from './model';
import moment from 'moment';

export function verifyTaxIDandEIN(...rows: ParsedRow[]) {
    rows.forEach(r => {
        if (isNullorEmpty(r[Field.Ein]) && isNullorEmpty(r[Field.TaxId])) {
            r._invalid.add(RowError.EmptyTaxIDandEIN);
        }
        if (!isNullorEmpty(r[Field.Ein]) && !isNullorEmpty(r[Field.TaxId])) {
            r._invalid.add(RowError.InvalidTaxIDandEIN);
        }
    });
}

export function verifyRequired(...rows: ParsedRow[]) {
    rows.forEach(r => {
        if (isNullorEmpty(r[Field.TaxClassificationType])) {
            r._invalid.add(RowError.RequiredTaxClassificationType);
        }
        if (isNullorEmpty(r[Field.TaxClassification])) {
            r._invalid.add(RowError.RequiredTaxClassification);
        }
        if (isNullorEmpty(r[Field.TaxClassificationOther])) {
            r._invalid.add(RowError.RequiredTaxClassificationOther);
        }
        if (isNullorEmpty(r[Field.ExemptPayeeCode])) {
            r._invalid.add(RowError.RequiredExemptPayeeCode);
        }
        if (isNullorEmpty(r[Field.ExemptFatcaCode])) {
            r._invalid.add(RowError.RequiredExemptFatcaCode);
        }
        if (isNullorEmpty(r[Field.Signature])) {
            r._invalid.add(RowError.RequiredSignature);
        }

        if (isNullorEmpty(r[Field.SignDate])) {
            r._invalid.add(RowError.RequiredSignDate);
        } else if (moment(r[Field.SignDate], DATE_FORMAT, true).isValid()) {
            r[Field.SignDate] = moment(r[Field.SignDate]).format(DATE_FORMAT_ENDPOINT);
        } else {
            r._invalid.add(RowError.InvalidSignDateFormat);
        }

        if (isNullorEmpty(r[Field.IpAddress])) {
            r._invalid.add(RowError.RequiredIpAddress);
        }
        if (isNullorEmpty(r[Field.CreatedBy])) {
            r._invalid.add(RowError.RequiredCreatedBy);
        }
    });
}
