import {
    DataType,
    firstNameField,
    lastNameField,
    emailField,
    phoneField,
    CommonField,
    createdByField,
} from '../util';
import { LookupCache } from '../util/lookup-cache';
import { getLanguagePreferences } from './api';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    AddressLine1: 'address_1',
    AddressLine2: 'address_2',
    City: 'city',
    State: 'state',
    ZipCode: 'zip',
    CountryCode: 'country_code',
    LanguageID: 'language_id',
    PhoneNotes: 'phone_notes',
    SendEmail: 'send_email',
    TaxEntityName: 'tax_entity_name',
};

/**
 * According to the spec, `1` is true and `2` is false, `2` being the default.
 * Like other validators, this returns a tuple of
 * [whether value is valid, the parsed value].
 */
export const parseEmailOption = (
    value: string | boolean | number | null
): ParseBoolean =>
    typeof value == 'boolean'
        ? [true, value]
        : !value || value === 2 || value === '2'
        ? [true, false]
        : value === 1 || value === '1'
        ? [true, true]
        : // value given doesn't match options
          [false, false];

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const contactModel: Model = [
    {
        name: CommonField.ContactID,
        label: 'Contact ID',
        type: DataType.Integer,
        omit: true,
    },
    firstNameField(true, 50),
    lastNameField(true, 50),
    emailField(true),
    {
        name: Field.LanguageID,
        label: 'Preferred Language',
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.AddressLine1,
        label: 'Mailing Address',
        maxLength: 100,
    },
    {
        name: Field.City,
        label: 'City',
        maxLength: 50,
    },
    {
        name: Field.State,
        label: 'State',
        maxLength: 50,
    },
    {
        name: Field.ZipCode,
        label: 'Zip Code',
        maxLength: 10,
    },
    {
        name: Field.CountryCode,
        label: 'Country Code',
        maxLength: 2,
    },
    phoneField(false),
    {
        name: Field.PhoneNotes,
        label: 'Phone Notes',
    },
    {
        name: Field.TaxEntityName,
        label: 'Tax Entity',
        maxLength: 100,
    },
    {
        name: Field.SendEmail,
        label: 'Welcome E-mail',
        type: DataType.Boolean,
        parse: parseEmailOption,
        default: false,
    },
    createdByField(),
];

const languageCache = new LookupCache(Field.LanguageID, getLanguagePreferences);

export const parseConfig: ParseConfig = {
    model: contactModel,
    skipRows: 2,
    onComplete: (file: ParsedFile) => languageCache.validate(file.rows),
};
