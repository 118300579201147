import { parseDateText } from '../util/parse-field';
import { Field, RowError } from './model';
import { getPrograms, getProgramFields } from './api';
import * as _ from 'lodash';

const validCycles = /^(ANNUAL|SEMI_ANNUAL|MONTHLY|WEEKLY|ONE_TIME)$/i;

/**
 * Ensure value entered for billing cycle is allowed
 */
export const parseBillingCycle = (value: string): ParseResult<string> => (value ? [validCycles.test(value), value] : [false, value]);

/**
 * Ensure actual value for setup billing is present if the `IsSetup` field is
 * `true` (`"TRUE"` in the CSV)
 *
 * @see https://github.com/Vacasa/units-app/blob/main/src/units/data/models/unit_program.py#L342
 */
export function verifySetupBillingActual(row: ParsedRow) {
    // value is already validated as float if present
    if (!row[Field.Billing.Setup.Actual]) {
        // blank
        if (row[Field.IsSetup]) {
            // if IsSetup is true then value is required
            row._invalid.add(Field.Billing.Setup.Actual);
        } else {
            // value is required in the API but must be 0 if `IsSetup` is false
            row[Field.Billing.Setup.Actual] = 0;
        }
    } else if (!row[Field.IsSetup]) {
        // setup actual shouldn't have a value if not IsSetup
        row._invalid.add(Field.Billing.Setup.Actual);
    }
}

/**
 * Ensure start date is required when it isn't draft
 * and end date is not before start date
 */
export function verifyDates(...rows: ParsedRow[]) {
    rows.forEach(row => {
        const isDraft: boolean = row[Field.IsDraft];
        if (isDraft) return;

        const startDate: Date | undefined = row[Field.StartDate];
        const endDate: Date | undefined = row[Field.EndDate];

        if (_.isNil(startDate)) {
            row._invalid.add(RowError.StartDateRequired);
        }

        if (startDate && endDate && endDate < startDate) {
            row._invalid.add(RowError.InvalidEndDate);
        }
    });
}

/**
 * Ensure program slug exists and any supplied fields match the specifications
 * for the program type. All specified fields are required.
 */
export async function verifyProgramSlugAndFields(rows: ParsedRow[]) {
    const allPrograms = await getPrograms();

    if (!allPrograms) return;

    for (const r of rows) {
        const slug: ProgramType | undefined = r[Field.ProgramSlug];
        const program = slug ? allPrograms.find(p => p.attributes.slug === slug) : undefined;

        if (!program) {
            r._invalid.add(Field.ProgramSlug);
            continue;
        }
        const fieldSpecs = await getProgramFields(program.id);

        if (!fieldSpecs) continue; // no fields defined for program type

        /** User given fields with non-empty keys and values */
        let fields = ((r[Field.ProgramFields] ?? []) as ParsedRow[]).filter(f => f[Field.FieldSlug] !== '' && f[Field.FieldValue] !== '');
        // re-assign fields to remove empties
        r[Field.ProgramFields] = fields;

        /** Field slugs given in CSV */
        const given: string[] = [];

        // ensure CSV value is one of the allowed choices for the program field
        fields.forEach(fieldRow => {
            const slug = fieldRow[Field.FieldSlug];
            /** Specification for field type */
            const spec = fieldSpecs.find(r => r.attributes.slug === slug);
            let error = false;

            if (!spec) {
                // field slug doesn't exist in the spec
                error = true;
            } else {
                given.push(slug);
                const value = fieldRow[Field.FieldValue];

                switch (spec.attributes.program_field_type) {
                    case 'string':
                        const choices = spec.attributes.program_field_config?.choices;
                        error = choices !== undefined && choices.find(c => c.value === value) === undefined;

                        break;
                    case 'date':
                        if (program.id === 2) {
                            if (value === null) {
                                break;
                            }
                        }
                        const [valid] = parseDateText(value);

                        error = !valid;

                        break;
                    case 'float':
                        error = isNaN(parseFloat(value));
                        break;
                }
            }

            if (error) r._invalid.add(Field.ProgramFields);
        });

        if (!r._invalid.has(Field.ProgramFields) && fieldSpecs.find(r => !given.includes(r.attributes.slug))) {
            // spec includes a field that wasn't given by the user
            r._invalid.add(RowError.MissingRequiredData);
        }
    }
}

export function parseDamageInsuranceFields(rows: ParsedRow[]) {
    for (let r of rows) {
        let damageInsuranceFields: Array<{ slug: string; data: any }> = [
            { slug: 'payer', data: null },
            { slug: 'opt-in-effective', data: null },
            { slug: 'opt-in-received', data: null },
            { slug: 'poi-effective', data: null },
            { slug: 'poi-received', data: null },
            { slug: 'opt-out-effective', data: null },
            { slug: 'opt-out-received', data: null },
        ];

        for (const field of damageInsuranceFields) {
            if (field.slug === 'payer') {
                field.data = r[field.slug.replace(/\-/g, '_')]?.toLowerCase();
                continue;
            }
            field.data = r[field.slug.replace(/\-/g, '_')];
        }

        let notes = r['internal_notes'] == null ? '' : r['internal_notes'];
        let owner_notes = r['owner_notes'] == null ? '' : r['owner_notes'];

        Object.assign(r, {
            ...r,
            program_slug: 'damage-insurance',
            billing_cycle: 'MONTHLY',
            is_setup: false,
            billing_currency: 'USD',
            billing_amount_setup_actual: 0.0,
            billing_amount_per_cycle_actual: 0.0,
            program_fields: damageInsuranceFields,
            notes: notes,
            owner_notes: owner_notes,
        });
    }
}
