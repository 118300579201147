import './toggle-button.scss';
import React, { useState, FunctionComponent } from 'react';
import { Button } from './button';

export const ToggleButtons: FunctionComponent<{
    buttonLabels: string[];
    onChange: (v: string) => void;
}> = ({ buttonLabels, onChange }) => {
    const [activeToggle, setActiveToggle] = useState('');
    const onClickToggle = (label: string) => {
        if (activeToggle === label) {
            setActiveToggle('');
            onChange('');
        } else {
            setActiveToggle(label);
            onChange(label);
        }
    };

    return (
        <div className="toggle-buttons">
            {buttonLabels.map(label => (
                <Button
                    id={label}
                    key={label}
                    label={label}
                    type={activeToggle === label ? 'primary' : 'secondary'}
                    onClick={() => onClickToggle(label)}
                />
            ))}
        </div>
    );
};
