import React, { FunctionComponent } from 'react';
import { formatCurrency, DataType, emptyRow } from '../util';
import { DataInput } from './data-input';

const SubTableRow: FunctionComponent<{
    row: ParsedRow;
    specs: FieldSpec[];
    onChange: (row: ParsedRow) => void;
}> = ({ row, specs, onChange }) => {
    /** Update field value */
    const fieldChange = async (name: string, value: any) => {
        row[name] = value;
        onChange(row);
    };

    return (
        <tr>
            {specs.map((c, i) => {
                /** React cache key */
                const key = `row-${row._index}-${c.name}`;
                let value = row[c.name];

                if (c.type === DataType.Float) value = formatCurrency(value);

                return (
                    <td key={key} className="edit">
                        <DataInput
                            onChange={v => fieldChange(c.name, v)}
                            cellKey={key}
                            value={value}
                            spec={c}
                        />
                    </td>
                );
            })}
        </tr>
    );
};

/**
 * A SubTable is a small popup table used to edit array or object field data
 * @see https://vacasa.invisionapp.com/share/UJTY53B8SCF#/screens/384825631
 */
export const SubTable: FunctionComponent<{
    rows: ParsedRow[];
    specs: FieldSpec[];
    innerRef?: React.RefObject<any>;
    onChange: (v: ParsedRow[]) => void;
}> = ({ rows, specs, onChange, innerRef }) => {
    const shown = rows && rows.length > 0 ? rows : [emptyRow()];
    const numberTypes = [
        DataType.Integer,
        DataType.Float,
        DataType.Percent,
        DataType.PositiveInteger,
    ];

    return (
        <table className="sub-table" ref={innerRef}>
            <thead>
                <tr>
                    {specs.map(f => (
                        <th
                            className={
                                f.type && numberTypes.includes(f.type)
                                    ? 'number'
                                    : undefined
                            }
                            key={f.name}
                        >
                            {f.label || f.name}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {shown.map(r => (
                    <SubTableRow
                        onChange={() => onChange(rows)}
                        row={r}
                        key={`row-${r._index}`}
                        specs={specs}
                    />
                ))}
            </tbody>
        </table>
    );
};
