import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createSilentRefreshFrame, inIFrame } from './utils';
import { BrowserRouter } from 'react-router-dom';
import {
    getTokensSilentRefresh,
    silentRefreshMessageHandler,
    checkTokenTimeout,
} from './idp-auth';

const t = process.env.REACT_APP_TOKEN_REFRESH_CHECK_INTERVAL;
const debug = process.env.REACT_APP_DEBUG === 'true';
const tokenRefreshInterval = t ? parseInt(t) : 60000;

const isInIframe = inIFrame();

if (isInIframe) {
    getTokensSilentRefresh();
} else {
    createSilentRefreshFrame();
    window.addEventListener('message', silentRefreshMessageHandler);

    // Periodically check if a token refresh is needed (TOKEN_REFRESH_CHECK_INTERVAL)
    checkTokenTimeout();
    window.setInterval(checkTokenTimeout, tokenRefreshInterval);

    ReactDOM.render(
        <BrowserRouter>
            <App debug={debug} />
        </BrowserRouter>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
