import { getURL, parseJSON, userHeaders } from '../api';
import { Role } from '../constants';

export async function getUserRole(): Promise<IdpUser> {
    const url = getURL('/user/role');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        const json: any = await parseJSON<IdpUser>(res, url);
        return json ? json : [Role.None];
    } else {
        console.error('Failed to retrieve current user role', res);
        const badUser: IdpUser = {
            roles: [Role.None],
            idp_id: '0',
            user_id: 0,
            email: ''
        }
        return badUser;
    }
}

export async function getAllUsers(): Promise<IdpUser[]> {
    const url = getURL('/user/');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        const users = await parseJSON<IdpUser[]>(res, url);
        return users ? users : [];
    } else {
        console.error('Failed to retrieve list of users', res);
        return [];
    }
}

export async function deleteUserFromDB(userId: number): Promise<any> {
    const url = getURL(`/user/${userId}`)
    const res = await fetch(url, {
        method: 'DELETE',
        headers: userHeaders()
    });

    if (res.status === 204) {
        return true;
    } else {
        return false;
    }
}

/**
 * Change a user's role (level of permissions in the app)
 */
export async function setUserRole(userId: number, newSetOfRoles: [number]): Promise<any> {
    if(newSetOfRoles.length < 1) {
        newSetOfRoles = [Role.None]
    }
    const url = getURL(`/user/${userId}/role`);
    const res = await fetch(url, {
        method: 'PATCH',
        headers: userHeaders(),
        body: JSON.stringify({role_ids: newSetOfRoles})
    });

    if (res.ok) {
        return await parseJSON(res, url);
    } else {
        return false;
    }
}

export async function requestAccess(): Promise<IdpUser | null> {
    const url = getURL(`/user/`);
    const res = await fetch(url, {
        method: 'POST',
        headers: userHeaders(),
    });

    if (res.ok) {
        return await parseJSON<IdpUser>(res, url);
    } else {
        console.error('Failed to request access', res);
        return null;
    }
}
