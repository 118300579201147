import { Field } from './model';

const alwaysShow: string[] = [Field.UnitId];

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-id',
        label: 'Required Unit Id',
        editFields: [Field.UnitId],
        showFields: alwaysShow.concat([Field.UnitId]),
    },
    {
        key: 'invalid-minminstay',
        label: 'Invalid Minimum Minstay',
        editFields: [Field.MinMinStay],
        showFields: alwaysShow.concat([Field.MinMinStay]),
    },
    {
        key: 'invalid-minstay-notes',
        label: 'Invalid Minimum Minstay Notes',
        editFields: [Field.CustomMinStayNotes],
        showFields: alwaysShow.concat([Field.CustomMinStayNotes]),
    },
    {
        key: 'invalid-vacasa-min-rate',
        label: 'Invalid Vacasa Minimum rate',
        editFields: [Field.VacasaMinRate],
        showFields: alwaysShow.concat([Field.VacasaMinRate]),
    },
    {
        key: 'invalid-owner-min-rate',
        label: 'Invalid Owner Minimum rate',
        editFields: [Field.OwnerMinRate],
        showFields: alwaysShow.concat([Field.OwnerMinRate]),
    },
    {
        key: 'invalid-hoa-min-rate',
        label: 'Invalid HOA Minimum rate',
        editFields: [Field.HoaMinRate],
        showFields: alwaysShow.concat([Field.HoaMinRate]),
    },
    {
        key: 'invalid-vacasa-ltr-min-rate',
        label: 'Invalid Vacasa LTR Minimum rate',
        editFields: [Field.VacasaLTRMinRate],
        showFields: alwaysShow.concat([Field.VacasaLTRMinRate]),
    },
    {
        key: 'invalid-owner-ltr-min-rate',
        label: 'Invalid Owner LTR  Minimum rate',
        editFields: [Field.OwnerLTRMinRate],
        showFields: alwaysShow.concat([Field.OwnerLTRMinRate]),
    },
    {
        key: 'invalid-hoa-ltr-min-rate',
        label: 'Invalid HOA LTR Minimum rate',
        editFields: [Field.HoaLTRMinRate],
        showFields: alwaysShow.concat([Field.HoaLTRMinRate]),
    },
    {
        key: 'invalid-rates-notes',
        label: 'Invalid Rates notes',
        editFields: [Field.CustomRatesNotes],
        showFields: alwaysShow.concat([Field.CustomRatesNotes]),
    },
    {
        key: 'invalid-ltdexclude',
        label: 'Invalid LTDexclude',
        editFields: [Field.LTDexclude],
        showFields: alwaysShow.concat([Field.LTDexclude]),
    },
    {
        key: 'invalid-standard-discounts-exclude',
        label: 'Invalid Standard Discounts Exclude',
        editFields: [Field.StandardDiscountsExclude],
        showFields: alwaysShow.concat([Field.StandardDiscountsExclude]),
    },
    {
        key: 'invalid-guest-cleaning-fee',
        label: 'Guest Cleaning Fee',
        editFields: [Field.CleaningFee],
        showFields: alwaysShow.concat([Field.CleaningFee]),
    },
    {
        key: 'invalid-owner-stay-cleaning-fee',
        label: 'Owner Stay Cleaning Fee',
        editFields: [Field.OwnerCleaningFee],
        showFields: alwaysShow.concat([Field.OwnerCleaningFee]),
    },
    {
        key: 'invalid-fee-notes',
        label: 'Fee Notes',
        editFields: [Field.fee_note],
        showFields: alwaysShow.concat([Field.fee_note]),
    },
    {
        key: 'invalid-contractor-piece-rate',
        label: 'Contractor Piece Rate',
        editFields: [Field.HousekeeperPayment],
        showFields: alwaysShow.concat([Field.HousekeeperPayment]),
    },
    {
        key: 'invalid-vacasa-housekeeper-piece-rate',
        label: 'Vacasa Housekeeper Piece Rate',
        editFields: [Field.HousekeeperPieceRate],
        showFields: alwaysShow.concat([Field.HousekeeperPieceRate]),
    },
    {
        key: 'invalid-deep-clean-contractor-piece-rate',
        label: 'Deep Clean Contractor Piece Rate',
        editFields: [Field.DeepCleanContractorPieceRate],
        showFields: alwaysShow.concat([Field.DeepCleanContractorPieceRate]),
    },
    {
        key: 'invalid-deep-clean-vacasa-housekeeper-piece-rate',
        label: 'Deep Clean Vacasa Housekeeper Piece Rate',
        editFields: [Field.DeepCleanVacasaHousekeeperPieceRate],
        showFields: alwaysShow.concat([Field.DeepCleanVacasaHousekeeperPieceRate]),
    },
    {
        key: 'invalid-fixed-turnover',
        label: 'Fixed Turnover',
        editFields: [Field.TurnoverDay],
        showFields: alwaysShow.concat([Field.TurnoverDay]),
    }
];
