import {
    parseReservationDate,
    rowHasError,
    parseNumber,
    CommonField,
} from '../util/';
import { Field, RowError } from './model';
import { unitIdLookupCache, ensureCached } from '../util/lookup-cache';

/** Registration type pattern */
const typePattern = /^[1-3]$/;
/** Registration subtype pattern for Owner Hold type */
const subtypePatternOwnerHold = /^[1-7]$/;
/** Registration subtype pattern for Vacasa Hold type */
const subtypePatternVacasaHold = /^[1-5]$/;

/**
 * Parse registration type
 */
export const parseType = (value: string) => parseNumber(value, typePattern);
/**
 * Parse registration subtype for Owner Hold type
 */
export const parseSubtypeOwnerHold = (value: string) => parseNumber(value, subtypePatternOwnerHold);
/**
 * Parse registration subtype for Vacasa Hold type
 */
export const parseSubtypeVacasaHold = (value: string) => parseNumber(value, subtypePatternVacasaHold);

/**
 * Whether two sets of reservation dates overlap. Prior validations ensure the
 * dates are defined and in order (start < end) within a reservation.
 */
export const overlap = (r1: ReservationDates, r2: ReservationDates): boolean =>
    (r1.start >= r2.start && r1.start <= r2.end) ||
    (r2.start >= r1.start && r2.start <= r1.end);

export function verifyDateRanges(file: ParsedFile) {
    /** Row indexes with date conflicts */
    const conflicts: Set<number> = new Set();
    /** Reservation dates mapped to units */
    const units: Map<number, ReservationDates[]> = new Map();

    file.rows
        // exclude rows that have invalid dates
        .filter(r => !rowHasError(r, [Field.Arrival, Field.Departure]))
        // group reservation dates by unit
        .forEach(r => {
            const unitID = parseInt(r[CommonField.UnitID]);

            if (!unitID || isNaN(unitID)) return;

            const start = parseReservationDate(r[Field.Arrival]);
            const end = parseReservationDate(r[Field.Departure], false);

            if (end && start) {
                // dates should already be valid after rowHasError() check
                if (!units.has(unitID)) units.set(unitID, []);

                // @ts-ignore: TS doesn't recognize previous units.has()
                units.get(unitID).push({
                    index: r._index,
                    start: start.getTime(),
                    end: end.getTime(),
                });
            }
        });

    units.forEach(reservations => {
        // compare reservation for each unit
        if (reservations.length < 2) return;

        reservations.forEach(r => {
            if (
                reservations.find(
                    inner => inner.index !== r.index && overlap(inner, r)
                )
            ) {
                conflicts.add(r.index);
            }
        });
    });

    conflicts.forEach(rowIndex => {
        const row = file.rows.find(r => r._index === rowIndex);
        if (!row) {
            throw new RangeError(`Conflicted row index ${rowIndex} not found`);
        }
        row._invalid.add(RowError.DateConflict);
    });
}

export async function verityUnitsAndCurrency(...rows: ParsedRow[]) {
    /** Only consider reservations with correctly formatted unit IDs */
    const rowsWithUnits = rows.filter(
        r => !rowHasError(r, [CommonField.UnitID])
    );
    /** Unique unit IDs within upload file */
    const allUnitIDs = rowsWithUnits.reduce((all: number[], r) => {
        const unitID = parseInt(r[CommonField.UnitID]);
        if (unitID && !isNaN(unitID) && !all.includes(unitID)) {
            all.push(unitID);
        }
        return all;
    }, []);

    await ensureCached(allUnitIDs);

    rowsWithUnits.forEach(r => {
        const unitID = parseInt(r[CommonField.UnitID]);
        const status = unitIdLookupCache.get(unitID);

        if (!status) {
            throw Error(`Status of unit ID ${unitID} is unknown`);
        }
        if (status.valid) {
            if (r[Field.CurrencyUsed] !== status.currencyCode) {
                r._invalid.add(Field.CurrencyUsed);
            }
        } else {
            r._invalid.add(CommonField.UnitID);
        }
    });
}
