import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ReservationRulesView: FunctionComponent = () => (
    <UploadView
        id="reservation-rules-upload-view"
        requiredRole={Role.ReservationRules}
        label="Reservation Rules"
        apiPath="reservation-rules"
        uploadType="reservation_rules"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={false}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="ReservationRules"
        entityCSVModel={ReservationRulesCSVModel}
    />
);

const ReservationRulesCSVModel: Array<string> = [
    'id',
    'rule.code',
    'value.code',
    'notes',
    'start_date',
    'end_date',
    'unit_rule_value_attributes'
];
