import { Field, RowError } from './model';
import { CommonField } from '../util/';

const alwaysShow = [CommonField.UnitID];
const dateFields = [Field.StartDate, Field.EndDate];

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-unit-id',
        label: 'Unit ID Not Found',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'program-slug-is-required',
        label: 'Program Slug is required.',
        editFields: [Field.ProgramSlug],
        showFields: alwaysShow.concat(Field.ProgramSlug),
    },
    {
        key: 'invalid-is-draft',
        label: 'Is Draft is invalid.',
        editFields: [Field.IsDraft],
        showFields: alwaysShow.concat(Field.IsDraft),
    },
    {
        key: 'invalid-start-date',
        label: 'Invalid start date.',
        editFields: [Field.StartDate],
        showFields: alwaysShow.concat(Field.StartDate),
    },
    {
        key: 'start-date-is-required',
        triggeredBy: [RowError.StartDateRequired],
        label: 'Start date is required.',
        editFields: [Field.StartDate],
        showFields: alwaysShow.concat(Field.StartDate),
    },
    {
        key: 'end-date-before-start-date',
        label: 'End Date should be before Start Date.',
        triggeredBy: [RowError.InvalidEndDate],
        editFields: [Field.StartDate, Field.EndDate],
        showFields: alwaysShow.concat(dateFields),
    },
    {
        key: 'invalid-program-fields',
        label: 'Invalid Program Fields',
        editFields: [Field.ProgramFields],
        triggeredBy: [Field.ProgramFields],
        showFields: alwaysShow.concat([Field.ProgramFields]),
    },
    {
        key: 'invalid-opt-out-received',
        label: 'Invalid Opt. Out Received.',
        editFields: [Field.OptOutReceived],
        showFields: alwaysShow.concat([Field.OptOutReceived]),
    },
    {
        key: 'invalid-opt-out-effective',
        label: 'Invalid Opt. Out Effective.',
        editFields: [Field.OptOutEffective],
        showFields: alwaysShow.concat([Field.OptOutEffective]),
    }
];
