import { currentUser, goToLogin } from './idp-auth';
import { FileError } from './util';
import * as moment from 'moment';

/**
 * Build standard header values with user information. Combine with other
 * headers using object spread.
 * @example
 * {
 *    'Content-Type': 'something/wonderful',
 *    ...userHeaders()
 * }
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers
 */
export const userHeaders = (): Record<string, string> => {
    const user = currentUser();

    return user.accessToken
        ? {
              Authorization: 'Bearer ' + user.accessToken,
          }
        : {};
};

/**
 * Safely parse JSON response from body text
 * @param apiPath Supply path to log with potential error
 */
export async function parseJSON<T>(res: Response, apiPath: string, config?: ParseJsonConfig): Promise<T | null> {
    const text = await res.text();
    try {
        const json: T = JSON.parse(text);

        if (config && json && Array.isArray(json)) {

            // apply configuration
            if (config.dateFields) {
                // parse date fields
                const f = config.dateFields;
                json.forEach(row => {
                    f.forEach(n => {
                        row[n] = parseApiDate(row[n]);
                    });
                });
            }
        }
        return json;
    } catch {
        console.error(`Unable to parse ${apiPath} response`, text);
        return null;
    }
}

/**
 * Create date from server value
 */
export const parseApiDate = (d: string): Date | undefined => (d ? new Date(d) : undefined);

/**
 * Return clean API URL necessary to access endpoint path
 */
export const getURL = (path: string): string => ('/api/' + path).replace(/\/{2,}/g, '/').replace(/^\//, '');

/**
 * Retrieve file upload summaries based on API path
 */
export function getUploadStatus(apiPath: string, maxDaysOld = 0): Promise<UploadFile[] | null> {
    const url = getURL(`/${apiPath}?max_days_old=${maxDaysOld}`);
    return getUploadedRecords(url);
}

/**
 * Retrieve file upload summaries based on API path
 */
export function getFileRecords(apiPath: string, fileID: number, getOnlyErrors = false, getOnlySuccess = false): Promise<UploadResponse[] | null> {
    const url = getURL(
        `/${apiPath}/${fileID}?get_error_only=${getOnlyErrors ? 'True' : 'False'}&get_success_only=${getOnlySuccess ? 'True' : 'False'}`
    );
    return getUploadedRecords(url);
}

/**
 * Retrieve uploaded records or files
 */
async function getUploadedRecords<T>(url: string): Promise<T[] | null> {
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
        redirect: 'error',
    });
    if (res.ok) {
        return parseJSON(res, url, {
            dateFields: ['upload_time', 'finish_time'],
        });
    } else {
        if (res.status === 403) goToLogin();
        console.error('Unable to retrieve uploaded records', url);
        return null;
    }
}

/**
 * Creates an object from a dot notation string for nested entities
 * @param jsonObject
 * @returns
 */
function dotNotationKeyToObject(jsonObject: any): object {
    let result: any = {};
    Object.keys(jsonObject).forEach(key => {
        let tempObject: any = {};
        let container: any = tempObject;
        let initVal: any = jsonObject[key];
        let rootKey: any = null;
        key.split('.').forEach((k, i, values) => {
            if (i === 0) rootKey = k;
            container = container[k] = i === values.length - 1 ? initVal : {};
        });
        if (result[rootKey]) {
            result[rootKey] = { ...result[rootKey], ...tempObject[rootKey] };
        } else {
            result = { ...result, ...tempObject };
        }
    });
    return result;
}

/**
 * Upload entity JSON object
 */
export async function saveUpload(apiPath: string, jsonObject: any): Promise<UploadResponse | null> {
    if (Array.isArray(jsonObject?.rows)) {
        jsonObject.rows = jsonObject.rows.map((row: any) => dotNotationKeyToObject(row));
    }
    const body = JSON.stringify(jsonObject);

    const url = getURL('/' + apiPath);
    const res = await fetch(url, {
        method: 'POST',
        headers: userHeaders(),
        body,
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        if (res.status === 403) goToLogin();

        const resJson: any = await parseJSON(res, url);
        const errorMessages = resJson['detail'].reduce((array: string[], jsonObject: any) => {
            array.push(jsonObject.msg);
            return array;
        }, []);
        console.error(`Failed to upload ${apiPath} CSV file`);
        throw new FileError(errorMessages.join(','));
    }
}

/**
 * Patch entity JSON object
 */
export async function patchUpload(apiPath: string, fileID: number, jsonObject: any): Promise<UploadResponse | null> {
    const body = JSON.stringify(jsonObject);
    const url = getURL(`/${apiPath}/${fileID}`);
    const res: Response = await fetch(url, {
        method: 'PATCH',
        headers: userHeaders(),
        body,
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        if (res.status === 403) goToLogin();
        console.error(`Failed to patch ${apiPath} CSV file`);
        return null;
    }
}

/**
 * Retrieve text of template CSV file or `null` if unfound
 */
export async function getTemplateCSV(templateName: string): Promise<string | null> {
    const now = moment.default().format('YYYYMMDDhhmmss');

    const res = await fetch(`/template/${templateName}.csv?v=${now}`);

    if (res.ok) {
        return await res.text();
    } else {
        if (res.status === 403) goToLogin();
        console.error(`Unable to retrieve ${templateName} CSV template`);
        return null;
    }
}

export async function fetchEntitiesByUnitIds(isConnectEntity: boolean, entityName: string, unitsInput: Array<number>): Promise<any> {
    const apiPath = isConnectEntity ? '/api/connect-template-flow' : '/api/unit-service-template-flow';
    return await fetchUnitsEntity(isConnectEntity, apiPath, entityName, unitsInput);
}

async function fetchUnitsEntity(isConnectEntity: boolean, apiPath: string, entityName: string, unitsInput: Array<number>) {
    const params = isConnectEntity
        ? {
              'filter[unit_id]': unitsInput.join(','),
              'page[limit]': entityName == "units" ? unitsInput.length : 2000,
          }
        : {
              resource_name: entityName,
              legacy_unit_ids: unitsInput,
          };
    const res: Response = await fetch(apiPath, {
        method: 'POST',
        headers: userHeaders(),
        body: JSON.stringify({
            entityName,
            params,
        }),
    });

    if (res.ok) {
        return await res.json();
    } else {
        if (res.status === 403) {
            goToLogin();
            console.error(`Unable to retrieve ${entityName} Template Flow CSV`);
            return null;
        }
        throw new Error(`Unable to retrieve ${entityName} Template Flow CSV`);
    }
}

export async function getFileHistory(templateId: number, templateName: string, fileType: string): Promise<any | null> {
    const res = await fetch(`api/file-history/${templateId}/${templateName}/${fileType}`, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        return await res.json();
    } else {
        if (res.status === 403) goToLogin();
        console.error(`getFileHistory unable to retrieve ${templateName} ${templateId} ${fileType} file`);
        return null;
    }
}
