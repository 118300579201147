import { Field, RowError } from './model';

export function validateBlockTypeId(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const blockTypeId = parseInt(r[Field.UnitBlockTypeId.name]);

        if (isNaN(blockTypeId) || blockTypeId < 1 || blockTypeId > 8) {
            r._invalid.add(RowError.InvalidBlockTypeId);
        }
    });
}

export function validateDaysOut(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const daysOut = parseInt(r[Field.DaysOut.name]);

        if (isNaN(daysOut) || daysOut < 0) {
            r._invalid.add(RowError.InvalidDaysOut);
        }
    });
}
