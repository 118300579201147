import { CommonField, DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import { validateEndDate, validateRateDates, validateRateTypes, validateReasonsWhy, validateStartDate } from './parsers';

/**
 * Unique field names required by the Unit service API.
 */
export const Field = {
    RecordId: {
        name: 'id',
        label: 'Record ID'
    },
    RateType: {
        name: 'rate_type',
        label: 'Rate Type',
    },
    ReasonWhy: {
        name: 'reason_why',
        label: 'Reason Why',
    },
    WeekdayRate: {
        name: 'weekday_rate',
        label: 'Weekday Rate',
    },
    WeekendRate: {
        name: 'weekend_rate',
        label: 'Weekend Rate',
    },
    RateEffectiveStartDate: {
        name: 'rate_effective_start_date',
        label: 'Rate Effective Start Date',
    },
    RateEffectiveEndDate: {
        name: 'rate_effective_end_date',
        label: 'Rate Effective End Date',
    },
    Notes: {
        name: 'notes',
        label: 'Notes',
    },
    StartDate: {
        name: 'start_date',
        label: 'Start Date',
    },
    EndDate: {
        name: 'end_date',
        label: 'End Date',
    },
}

export const RowError = {
    InvalidRateEffectiveStartDate: 'invalid_rate_effective_start_date',
    InvalidRateEffectiveEndDate: 'invalid_rate_effective_end_date',
    InvalidStartDate: 'invalid_start_date',
    StartDateRequired: 'start_date_required',
    InvalidEndDate: 'invalid_end_date',
    NotesRequired: 'notes_required',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Unit Service API:
 */
const resRuleModel: Model = [
    unitIdField(true),
    {
        name: Field.RecordId.name,
        label: Field.RecordId.label,
        type: DataType.Text,
        required: false
    },
    {
        name: Field.RateType.name,
        label: Field.RateType.label,
        type: DataType.Text,
        required: true,
        parse: validateRateTypes
    },
    {
        name: Field.ReasonWhy.name,
        label: Field.ReasonWhy.label,
        type: DataType.Text,
        required: true,
        parse: validateReasonsWhy
    },
    {
        name: Field.WeekdayRate.name,
        label: Field.WeekdayRate.label,
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.WeekendRate.name,
        label: Field.WeekendRate.label,
        type: DataType.PositiveInteger,
        required: true
    },
    {
        name: Field.RateEffectiveStartDate.name,
        label: Field.RateEffectiveStartDate.label,
        type: DataType.Text,
        required: true
    },
    {
        name: Field.RateEffectiveEndDate.name,
        label: Field.RateEffectiveEndDate.label,
        type: DataType.Text,
        required: false
    },
    {
        name: Field.Notes.name,
        label: Field.Notes.label,
        type: DataType.Text,
        required: true
    },
    {
        name: Field.StartDate.name,
        label: Field.StartDate.label,
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.EndDate.name,
        label: Field.EndDate.label,
        type: DataType.Date,
        required: false
    },
]

function formatRecord(r: ParsedRow, currentUnitId: number, formatAttributes: any) {
    return {
        'unit_id': currentUnitId,
        'record_id':  r[Field.RecordId.name],
        'rule_code': 'SEASONAL-RATES',
        'value_code': 'ENROLLED',
        'notes': r[Field.Notes.name],
        'start_date': r[Field.StartDate.name],
        'end_date': r[Field.EndDate.name],
        'unit_rule_value_attributes': {
            'enrolled': [
                formatAttributes
            ]
        }
    }
}

export const parseConfig: ParseConfig = {
    model: resRuleModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        validateStartDate(...file.rows);
        validateEndDate(...file.rows);
        validateRateDates(...file.rows);
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
        validateStartDate(row);
        validateEndDate(row);
        validateRateDates(row);
    },
    async beforeDownload(rows): Promise<void> {
        const originalRows = rows.slice();
        for (const r of originalRows) {
            let errors = [""];
            for (const value of Array.from(r._unhandled)) {
                let error;
                try {
                    error = JSON.parse(value);
                    const msg = error['message'];

                    for (const pos of error['positions']) {
                        if (errors[pos] !== undefined) {
                            if (!errors[pos].includes(msg))
                                errors[pos] = errors[pos].concat(` ${msg}`);
                        } else {
                            errors[pos] = msg;
                        }
                    }
                } catch (e) {
                    errors = [value];
                }
            }

            let position = 1;
            for (const rate of r.unit_rule_value_attributes['enrolled']) {
                let row = {
                    ...r,
                    'id': r.record_id,
                    'rate_type': rate.rate_type,
                    'reason_why': rate.reason_why,
                    'weekday_rate': rate.weekday_rate,
                    'weekend_rate': rate.weekend_rate,
                    'rate_effective_start_date': rate.rate_effective_start_date,
                    'rate_effective_end_date': rate.rate_effective_end_date,
                    '_unhandled': [errors[0] === "" ? errors[position] : errors.toString()]
                } as any;
                delete row['unit_rule_value_attributes'];
                delete row['rule_code'];
                delete row['value_code'];
                rows.push(row);
                position++;
            }
        }

        originalRows.forEach(r => {
            const index = rows.indexOf(r);
            rows.splice(index, 1);
        })
    },
    async beforeUpload(file) {
        const unitIds: any = {};
        const formatRows: ParsedRow[] = [];
        file.rows.forEach(r => {
            const currentUnitId = r[CommonField.UnitID];
            const currentRecordId = r[Field.RecordId.name] ?? 0;

            let formatAttributes = {
                'rate_type': r[Field.RateType.name],
                'reason_why': r[Field.ReasonWhy.name],
                'weekday_rate': r[Field.WeekdayRate.name],
                'weekend_rate': r[Field.WeekendRate.name],
                'rate_effective_start_date': r[Field.RateEffectiveStartDate.name],
                'rate_effective_end_date': r[Field.RateEffectiveEndDate.name],

            } as any;

            if (currentUnitId in unitIds) {
                if (currentRecordId in unitIds[currentUnitId])
                    unitIds[currentUnitId][currentRecordId]['unit_rule_value_attributes']['enrolled'].push(formatAttributes);
                else
                    unitIds[currentUnitId][currentRecordId] = formatRecord(r, currentUnitId, formatAttributes);
            } else {
                unitIds[currentUnitId] = {
                    [currentRecordId]: formatRecord(r, currentUnitId, formatAttributes)
                };
            }
        });

        for (const [key, units] of Object.entries(unitIds)) {
            for (const [index, record] of Object.entries(unitIds[key])) {
                formatRows.push(record as unknown as ParsedRow);
            }
        }
        file.rows = formatRows;
    },
}