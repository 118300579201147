import { Field, RowError } from './model';
import { CommonField } from '../util';

const alwaysShow: string[] = [CommonField.UnitID];
const dateFields: string[] = [
    Field.PermitEffectiveDate.name,
    Field.PermitRenewalDate.name,
];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit Id is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'property-permit-id-is-required',
        label: 'Permit property id required is not valid',
        editFields: [Field.PropertyPermitId.name],
        showFields: [...alwaysShow, Field.PropertyPermitId.name],
    },
    {
        key: 'display-public-permit-id-is-required',
        label: 'Permit property id required is not valid',
        editFields: [Field.DisplayPublicPermitId.name],
        showFields: [...alwaysShow, Field.DisplayPublicPermitId.name],
    },
    {
        key: 'permit-type-is-required',
        label: 'Permit type is required',
        editFields: [Field.PermitType.name],
        showFields: [...alwaysShow, Field.PermitType.name],
    },
    {
        key: 'invalid-permit-type',
        label: 'Permit type is not valid',
        triggeredBy: [RowError.InvalidPermitType],
        editFields: [Field.PermitType.name],
        showFields: [...alwaysShow, Field.PermitType.name],
    },
    {
        key: 'invalid-description',
        label: 'Description can not be empty if type is 1 (Other)',
        triggeredBy: [RowError.PermitDescriptionRequired],
        editFields: [Field.PermitDescription.name, Field.PermitType.name],
        showFields: [
            ...alwaysShow,
            Field.PermitDescription.name,
            Field.PermitType.name,
        ],
    },
    {
        key: 'invalid-effective-date',
        label: 'Renewal date cannot be lower or equal to effective date',
        triggeredBy: [RowError.InvalidEffectiveDate],
        editFields: [...dateFields],
        showFields: [...alwaysShow.concat(dateFields)],
    },
    {
        key: 'invalid-permit-id',
        label:
            'Property permit id must contain Letters, numbers and only special characters among these: - : # / . , )(',
        triggeredBy: [RowError.InvalidPermitId],
        editFields: [Field.PropertyPermitId.name],
        showFields: [...alwaysShow, Field.PropertyPermitId.name],
    },
    {
        key: 'max-permit-quantity-per-unit',
        label:
            'You exceeded the maximum permitted permits for a unit (5) fix the file and try again.',
        triggeredBy: [RowError.MaxPermitQuantityPerUnit],
        editFields: [],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-dates',
        label: 'Invalid dates',
        editFields: [
            Field.PermitRenewalDate.name,
            Field.PermitEffectiveDate.name,
        ],
        showFields: [
            ...alwaysShow.concat([
                Field.PermitRenewalDate.name,
                Field.PermitEffectiveDate.name,
            ]),
        ],
    },
];
