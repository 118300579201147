export { loadTemplate } from './template';
export { exportCSV, downloadCSV } from './exporter';
export {
    parseCSV,
    parseQueryResult,
    formatCurrency,
    monetize,
    parseDate,
    parseReservationDate,
    rowHasError,
    emptyRow,
    FileError,
    round,
} from './parse-file';
export { DataType, DataTypeError } from './data-types';
export { createdByField, firstNameField, lastNameField, phoneField, emailField, unitIdField, CommonField } from './model';
export {
    Pattern,
    parseBit,
    parseCurrencyCode,
    parseDateText,
    parseInteger,
    parseDecimals,
    parseNumber,
    parsePositiveInteger,
    parseThreeChoice,
    parseField,
    parse,
    updateRowErrors,
    updateFileErrors,
    padZero,
} from './parse-field';

export const isNil = <T extends any>(v?: T | null) => v === null || v === undefined;

export const isNullorEmpty = (str: string) => str == null || str === '';
