import React from 'react';

/**
 * Render Google Material Design icon. This depends on the font being loaded
 * in the HTML file with
 * `<link
 *    href="https://fonts.googleapis.com/icon?family=Material+Icons"
 *    rel="stylesheet"/>`
 *
 * @see https://google.github.io/material-design-icons/
 */
export const Icon: React.FunctionComponent<{
    id?: string;
    name: string;
    className?: string;
    hint?: string;
    onClick?: (e: React.MouseEvent) => void;
}> = ({ id, name, onClick, hint, className }) => (
    <i
        id={id}
        onClick={onClick}
        title={hint}
        className={(className ? className + ' ' : '') + 'material-icons'}
    >
        {name}
    </i>
);
