// note: this is all shamelessly stolen from https://github.com/Vacasa/real-estate-agent-app
import nanoid from 'nanoid';
import decode from 'jwt-decode';

interface LoginParams {
    error: string | null;
    access_token: string | null;
    id_token: string | null;
    state: string | null;
}

interface FrameMessage {
    type: string;
    payload: LoginParams;
}

interface TokenPayload {
    at_hash: string;
    /** Audience */
    aud: string[];
    email: string;
    /** Expiration timestamp */
    exp: number;
    family_name: string;
    given_name: string;
    /** Timestamp */
    iat: number;
    /** Issuer domain */
    iss: string;
    locale: string;
    /** User name */
    name: string;
    nonce: string;
    /** Subject UUID */
    sub: string;
    /** Timezone */
    zoneinfo: string;
}

/**
 * @see https://github.com/Vacasa/idp-app
 */
interface User {
    accessToken: string | null;
    id: string | null;
    fullName: string | null;
    firstName: string | null;
    email: string | null;
}

const BASE_URL = `https://${process.env.REACT_APP_AUTH_DOMAIN}`;
const LOGIN_URL = `${BASE_URL}/authorize`;
const LOGOUT_URL = `${BASE_URL}/logout`;

/**
 * Current user singleton
 */
const user: User = {
    accessToken: null,
    id: null,
    fullName: null,
    firstName: null,
    email: null,
};

export const currentUser = (): User => user;

/**
 * Save authorization in memory
 * @param id User ID from IdP JWT
 * @param firstName User's given name froom IdP JWT
 * @param fullName User's full name from IdP JWT
 */
const setSessionUser = (
    token: string | null,
    id: string | null,
    firstName: string | null,
    fullName: string | null,
    email: string | null
) => {
    user.accessToken = token;

    if (token) {
        user.id = id;
        user.firstName = firstName;
        user.fullName = fullName;
        user.email = email;
    } else {
        user.id = null;
        user.firstName = null;
        user.fullName = null;
        user.email = null;
    }
};

const getSessionUser = (): User => user;

/**
 * Build parameters required for IdP login
 * @param args Additional key/values to include in params
 */
export function getLoginURLSearchParams(
    args: Record<string, string> = {}
): URLSearchParams {
    const nonce = nanoid();
    const state = nanoid();

    sessionStorage.setItem('nonce', nonce);
    sessionStorage.setItem('state', state);

    return new URLSearchParams({
        // Create React App magically replaces Node process.env references with
        // their actual values during build
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        scope: 'locations:read',
        response_type: 'token id_token',
        nonce,
        state,
        redirect_uri: window.location.origin,
        ...args,
    } as Record<string, string>);
}

/**
 * Return redirect URL for refreshing an access token (session needs to be
 * established)
 */
export const getLoginRefreshURL = () => {
    const params = getLoginURLSearchParams({ prompt: 'none' });

    return `${LOGIN_URL}?${params.toString()}`;
};

/**
 * Redirect to login page
 */
export const goToLogin = () => {
    const params = getLoginURLSearchParams();
    const url = `${LOGIN_URL}?${params.toString()}`;

    // cache current pathname to redirect user when back from IdP
    sessionStorage.setItem('pathname', window.location.pathname);
    window.location.assign(url);
};

export const goToLogout = () => {
    const params = new URLSearchParams({
        redirect_uri: window.location.origin,
    });
    const url = `${LOGOUT_URL}?${params.toString()}`;

    window.location.assign(url);
};

/**
 * @param clearHashParams Whether to remove hash values from URL
 */
export function getAuthFromURL(clearHashParams = true): LoginParams | null {
    const { search, hash } = window.location;
    const searchParams = new URLSearchParams(search);
    const hashParams = new URLSearchParams(hash.replace(/^#/, ''));
    if (
        searchParams.has('error') ||
        hashParams.has('access_token') ||
        hashParams.has('id_token')
    ) {
        const loginParams = {
            error: searchParams.get('error'),
            access_token: hashParams.get('access_token'),
            id_token: hashParams.get('id_token'),
            state: hashParams.get('state'),
        };

        if (clearHashParams) {
            ['access_token', 'id_token', 'state', 'token_type'].forEach(param =>
                hashParams.delete(param)
            );
            window.location.hash = hashParams.toString();
        }
        return loginParams;
    }
    return null;
}

/**
 * Validate login parameters
 */
export function validateAuth({
    error,
    access_token,
    id_token,
    state,
}: LoginParams) {
    if (error) throw new Error(error);

    const session_nonce = sessionStorage.getItem('nonce');
    const session_state = sessionStorage.getItem('state');

    if (!session_nonce || !session_state || !id_token) {
        throw new Error('login_required');
    }

    const data: TokenPayload = decode(id_token);

    if (data.nonce !== session_nonce || state !== session_state) {
        throw new Error('Authentication signature mismatch');
    }
    if (!access_token) throw new Error('Missing Access Token');

    return {
        access_token,
        data,
    };
}

export function isLoggedIn() {
    const { accessToken } = getSessionUser();

    if (accessToken) return true;

    const login = getAuthFromURL();

    if (!login) {
        goToLogin();
        return false;
    }

    try {
        setTokens(login);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

/**
 * Check if the hidden iframe has IdP tokens in the URL, if so, send them to the parent
 */
export function getTokensSilentRefresh() {
    const auth = getAuthFromURL();
    if (auth) {
        window.parent.postMessage(
            { type: 'token_refresh', payload: auth },
            window.location.origin
        );
    }
}

/**
 * Run in parent window - handles a refresh token sent from the hidden iframe
 */
export function silentRefreshMessageHandler(msg: { data: FrameMessage }) {
    if (msg && msg.data.type === 'token_refresh') {
        try {
            setTokens(msg.data.payload);
        } catch (e) {
            console.log('Silent refresh error:', e);
            // todo: indicate to the user that they need to refresh / log in again
        }
    }
}

/**
 * Validate an access token and persist it to the user object
 */
export function setTokens(login: LoginParams) {
    const { data } = validateAuth(login);
    setSessionUser(
        login.access_token,
        data.sub,
        data.given_name,
        data.name,
        data.email
    );
}

/**
 * @param token - User's access token
 * @param leeway - If the token expiration is within this amount of time (in ms), it needs refresh
 */
export function tokenNeedsRefresh(token: string, leeway = 60000): boolean {
    const data: TokenPayload = decode(token);
    const dt = new Date(data.exp * 1000);
    const now = new Date();

    return dt.getTime() - now.getTime() < leeway;
}

/**
 * Kick off a silent token refresh flow in the hidden iframe
 */
export function iFrameSilentRefresh() {
    const refreshFrame = document.getElementById('refresh-frame');
    if (refreshFrame) {
        refreshFrame.setAttribute('src', getLoginRefreshURL());
    } else {
        console.error('Unable to find "refresh-frame" element');
    }
}

/**
 * if the user's access token is expiring, kick off the silent refresh flow
 */
export function checkTokenTimeout() {
    if (user.accessToken && tokenNeedsRefresh(user.accessToken)) {
        iFrameSilentRefresh();
    }
}
