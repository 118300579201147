import { Field, RowError } from './model';
import { CommonField, rowHasError } from '../util/';

const alwaysShow = [CommonField.UnitID, Field.ProgramSlug];
const dateFields = [Field.StartDate, Field.EndDate];

const billingSetupFields = [Field.Billing.Setup.Actual, Field.Billing.Setup.Cost, Field.Billing.Setup.Default];

const billingPerCycleFields = [Field.Billing.PerCycle.Actual, Field.Billing.PerCycle.Cost, Field.Billing.PerCycle.Default];

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-unit-id',
        label: 'Unit ID Not Found',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-slug',
        label: 'Program Slug Does Not Exist',
        editFields: [Field.ProgramSlug],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-start-date',
        label: 'Invalid start date.',
        editFields: [Field.StartDate],
        showFields: alwaysShow.concat(Field.StartDate),
    },
    {
        key: 'start-date-is-required',
        triggeredBy: [RowError.StartDateRequired],
        label: 'Start date is required.',
        editFields: [Field.StartDate],
        showFields: alwaysShow.concat(Field.StartDate),
    },
    {
        key: 'end-date-before-start-date',
        label: 'End Date before Start Date',
        editFields: [Field.StartDate, Field.EndDate],
        showFields: alwaysShow.concat(dateFields),
    },
    {
        key: 'invalid-billing-cycle',
        label: 'Invalid Billing Cycle',
        editFields: [Field.Billing.Cycle],
        showFields: alwaysShow.concat(Field.Billing.Cycle),
    },
    {
        key: 'invalid-billing-setup',
        label: 'Invalid Billing Setup',
        editFields: billingSetupFields,
        showFields: alwaysShow.concat(billingSetupFields),
    },
    {
        key: 'invalid-billing-per-cycle',
        label: 'Invalid Billing Per-Cycle - Actual amount required',
        editFields: billingPerCycleFields,
        showFields: alwaysShow.concat(billingPerCycleFields),
    },
    {
        key: 'invalid-toggle',
        label: 'Missing Required Selections',
        editFields: [Field.IsDraft, Field.IsSetup],
        showFields: alwaysShow.concat([Field.IsDraft, Field.IsSetup]),
    },
    {
        key: 'invalid-program-fields',
        label: 'Invalid Program Fields',
        editFields: [Field.ProgramFields, Field.ProgramSlug],
        triggeredBy: [Field.ProgramFields],
        showFields: alwaysShow.concat([Field.ProgramFields]),
    },
    {
        key: 'missing-program-fields',
        label: 'Required Program Fields are missing',
        editFields: [Field.ProgramFields, Field.ProgramSlug],
        triggeredBy: [RowError.MissingRequiredData],
        showFields: alwaysShow.concat([Field.ProgramFields]),
    },
    {
        key: 'invalid-employee-id',
        label: 'Invalid Employee ID',
        editFields: [CommonField.CreatedBy],
        showFields: alwaysShow.concat([CommonField.CreatedBy]),
    },
];
