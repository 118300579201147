import { Field, RowError } from './model';
import { CommonField } from '../util';

const alwaysShow: string[] = [CommonField.UnitID];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit Id is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'accounting_entity',
        label: 'Unit Id is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'accounting_entity_id_not_valid',
        label: 'Accounting Entity Id is not valid',
        editFields: [Field.AccountingEntityId.name],
        triggeredBy: [RowError.InvalidAccountingEntityId],
        showFields: alwaysShow.concat(Field.AccountingEntityId.name),
    },
    {
        key: 'invalid_dates',
        label: 'Invalid Dates',
        triggeredBy: [RowError.InvalidDates],
        editFields: [Field.StartDate.name, Field.EndDate.name],
        showFields: alwaysShow.concat(Field.StartDate.name, Field.EndDate.name),
    },
];
