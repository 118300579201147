import { DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import {
    validatePropertyTaxIdType,
    validatePropertyTaxIdDescription,
    validateHasRenewalDate,
    validatePropertyTaxIdQuantity,
    validatePropertyTaxId,
    validateEffectiveDate,
} from './parsers';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    PropertyTaxId: {
        name: 'property_tax_id',
        label: 'Property Tax Id',
    },
    DisplayPublicTaxId: {
        name: 'display_public_property_tax_id',
        label: 'Display Public Tax Id',
    },
    PropertyTaxIdEffectiveDate: {
        name: 'property_tax_effective_date',
        label: 'Property Tax Id Effective Date',
    },
    PropertyTaxIdRenewalDate: {
        name: 'property_tax_renewal_date',
        label: 'Property Tax Id Renewal Date',
    },
    PropertyTaxIdType: {
        name: 'property_tax_type',
        label: 'Property Tax Id Type',
    },
    PropertyTaxIdDescription: {
        name: 'property_tax_description',
        label: 'Property Tax Id Description',
    },
};

export const RowError = {
    InvalidPropertyTaxType: 'invalid_property_tax_type',
    PropertyTaxIdDescriptionRequired: 'property_tax_description_required',
    InvalidRenewalDate: 'invalid_renewal_date',
    MaxPropertyTaxIdQuantityPerUnit: 'max_property_tax_quantity_per_unit',
    InvalidPropertyTaxId: 'invalid_property_tax',
    InvalidEffectiveDate: 'invalid_effective_date',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contacts/paths/~1v1~1contacts/post
 */
const permitsModel: Model = [
    unitIdField(),
    {
        name: Field.PropertyTaxId.name,
        label: Field.PropertyTaxId.label,
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.DisplayPublicTaxId.name,
        label: Field.DisplayPublicTaxId.label,
        type: DataType.Boolean,
        required: true,
    },
    {
        name: Field.PropertyTaxIdEffectiveDate.name,
        label: Field.PropertyTaxIdEffectiveDate.label,
        type: DataType.Date,
    },
    {
        name: Field.PropertyTaxIdRenewalDate.name,
        label: Field.PropertyTaxIdRenewalDate.label,
        type: DataType.Date,
    },
    {
        name: Field.PropertyTaxIdType.name,
        label: Field.PropertyTaxIdType.label,
        type: DataType.Integer,
        required: true,
    },
    {
        name: Field.PropertyTaxIdDescription.name,
        label: Field.PropertyTaxIdDescription.label,
        type: DataType.Text,
    },
];

export const parseConfig: ParseConfig = {
    model: permitsModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        validatePropertyTaxIdType(...file.rows);
        validatePropertyTaxIdDescription(...file.rows);
        validateHasRenewalDate(...file.rows);
        validatePropertyTaxIdQuantity(...file.rows);
        validatePropertyTaxId(...file.rows);
        validateEffectiveDate(...file.rows);
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
        validatePropertyTaxIdType(row);
        validatePropertyTaxIdDescription(row);
        validateHasRenewalDate(row);
        validatePropertyTaxId(row);
        validateEffectiveDate(row);
    },
};
