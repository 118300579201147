import React, { useContext, useEffect, useState, FunctionComponent } from 'react';
import { Uploader } from './uploader';
import { RequiresRole } from './requires-role';
import { StatusTable } from './status-table';
import { Spinner } from './spinner';
import { ConfirmModal } from './confirm-modal';
import { saveUpload, patchUpload, getTemplateCSV, getFileHistory } from '../api';
import { getUploadStatus, getFileRecords } from '../api';
import { Context } from '../context';
import { parseQueryResult, downloadCSV, updateFileErrors, FileError, loadTemplate } from '../util/';
import { currentUser } from '../idp-auth';
import { Fetcher } from './fetcher';
import { ToastContainer, toast } from 'react-toastify';
import { getAcquisitionList } from './../util/api';
import { uploadTypesWithSuccess } from '../constants';
import { Button } from './button';
import { Modal } from './modal';
import * as moment from 'moment';
import _ from 'lodash';

const defaultUnitIdsLimit = 1500;

export type FilterArguments = {
    entities: Array<Object>;
    csvTemplate: string;
    entityCSVModel?: Array<string>;
};

export const DefaultTemplateSection: FunctionComponent<{
    templateName: string;
    label: string;
}> = ({ templateName, label }) => {
    const downloadCsv = async () => {
        const csvTemplate = (await getTemplateCSV(templateName)) || '';
        const file = new Blob([csvTemplate]);

        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);

        let now = moment.default().format('YYYYMMDDhhmmss');

        element.download = `template-${templateName.replace(/\//, '-')}-${now}.csv`;

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        toast.success('SUCCESS: CSV template is ready and the download should start soon.');
    };

    return (
        <div>
            <ToastContainer />
            <a
                id={`${label.toLowerCase()}-template-download`}
                onClick={() => {
                    downloadCsv();
                }}
            >
                {label} Template CSV file
            </a>
            <p>
                The {label} Template CSV file may be filled out and uploaded to import multiple reservations in bulk. Please ensure that the file you
                upload below has the same column format as the template to ensure a smooth import process.
            </p>
        </div>
    );
};

/**
 * Standard page view for uploads that show a confirmation modal with optional
 * field corrections and an upload status table
 */
export const UploadView: FunctionComponent<{
    id: string;
    requiredRole: number;
    /**
     * Path to API endpoints for upload type. This will almost always be
     * identifical to `uploadType` but not necessarily.
     */
    apiPath: string;
    label: string;
    /**
     * Name of upload type (i.e. reservation) used as a namespace/field prefix
     * in database
     */
    uploadType: string;
    parseConfig: ParseConfig;
    fixSpecs: FixSpec[];
    /** Supply optional alternative template section */
    templateSection?: JSX.Element;
    isPlural?: boolean;
    templateFlowEnabled?: boolean;
    isConnectEntity?: boolean;
    entityName?: string;
    entityCSVModel?: Array<string>;
    unitIdsLimit?: number;
    childBulks?: Array<FunctionComponent>;
    /**
     * Alternative template
     */
    altTemplate?: string;
    customFilter?: (input: FilterArguments) => FilterArguments;
}> = ({
    id,
    requiredRole,
    apiPath,
    label,
    uploadType,
    parseConfig,
    fixSpecs,
    templateSection,
    isPlural = false,
    templateFlowEnabled = false,
    isConnectEntity = false,
    entityName = '',
    entityCSVModel = [],
    unitIdsLimit = defaultUnitIdsLimit,
    altTemplate,
    customFilter,
}) => {
    const [parsedFile, setParsedFile] = useState<ParsedFile>();
    const [showSpinner, setShowSpinner] = useState(false);
    const [showDownloadSpinner, setShowDownloadSpinner] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<UploadFile[] | null>(null);
    const [error, setError] = useState<FileError>();
    const [acquisitionCollection, setAcquisitionCollection] = useState([]);
    const [acquisitionId, setAcquisitionId] = useState('0');
    const [isFileModalActive, setIsFileModalActive] = useState(false);
    const [fileModalMessage, setFileModalMessage] = useState("");

    const ctx = useContext(Context);

    /**
     * @param maxDaysOld Defaults to current day if not defined
     */
    const loadFileStatus = (maxDaysOld?: number) => {
        getUploadStatus(apiPath, maxDaysOld).then(setUploadedFiles);
    };

    const acquisitionList = async () => {
        if (id === 'unit-upload-view') {
            const list = await getAcquisitionList();
            if (!_.isNil(list)) setAcquisitionCollection(list as any);
        }
    };

    const initialize = () => {
        loadFileStatus();
        acquisitionList();
    };

    // reload upload status if role changes (currently a theoretical, not
    // actual consideration)
    useEffect(initialize, [apiPath, ctx.role]);

    /**
     * Update parsed file state which will trigger modal display and validation
     */
    const upload = (parsed: ParsedFile) => setParsedFile(parsed);
    const cancel = () => setParsedFile(undefined);

    /**
     * Re-run validations
     */
    const retry = async () => {
        if (!parsedFile) return;

        setShowSpinner(true);
        await updateFileErrors(parsedFile, parseConfig, { acqId: acquisitionId });
        setShowSpinner(false);
        // React state check is shallow so clone full object to trigger re-render
        setParsedFile({ ...parsedFile });
    };

    const showModal = (message: string): void => {
        setFileModalMessage(message);
        setIsFileModalActive(true);
    }

    const handleFileHistoryClick = async (fileType: string, row: any, idField: any): Promise<void> => {
        try {
            setShowDownloadSpinner(true);

            if (_.isNil(uploadType)) {
                showModal("Upload type not found.");
                return;
            }

            const templateId = _.get(row, idField);
            const data = await getFileHistory(templateId, uploadType, fileType);
            if (_.isNil(data) || !data.hasOwnProperty("url")) {
                showModal("This file could not be found.");
                return;
            }

            // Download file
            window.open(data.url);
        } catch (e) {
            showModal("An error has occurred.");
            console.error(e);
        } finally {
            setShowDownloadSpinner(false);
        }
    }

    /**
     * Method to re-parse the upload file if it passed local validations but was
     * then rejected by the API. Assigning it back to the `parsedFile` will
     * cause the error correction modal to appear.
     * If viewSuccess is True upload file with success records
     */
    const viewUploadFiles = async (fileID: number, viewSuccess: boolean) => {
        setShowDownloadSpinner(true);
        let records;

        if (uploadTypesWithSuccess.includes(uploadType) && viewSuccess) records = await getFileRecords(apiPath, fileID, false, true);
        else records = await getFileRecords(apiPath, fileID, true);

        /**
         * Static CSV template. If upload type specifies a custom template
         * section then assume it doesn't use a static template.
         */
        const template = templateSection
            ? await loadTemplate(
                  viewSuccess ? 'success/' + (altTemplate || apiPath) : altTemplate || apiPath,
                  viewSuccess ? parseConfig.successModel : parseConfig.model
              )
            : null;

        if (!records || records.length === 0) return;

        /** Removed metadata record */
        const summary = records.shift();

        if (!summary) return;

        const parsed = await parseQueryResult(summary.file_name, records, parseConfig, uploadType, fileID);

        viewSuccess
            ? downloadCSV(parsed, parseConfig.model, template, false, true, parseConfig.successModel)
            : downloadCSV(parsed, parseConfig.model, template, true);
        setShowDownloadSpinner(false);
    };

    const saveUploadedFile = async () => {
        if (!parsedFile) return;

        setShowSpinner(true);
        // clone file object to allow for beforeUpload() changes that shouldn't
        // affect the in-memory object
        const uploadFile = Object.assign({}, parsedFile);
        uploadFile.rows = parsedFile.rows.map(r => Object.assign({}, { ...r, uploaded_by: currentUser().email }));

        if (parseConfig.beforeUpload) {
            await parseConfig.beforeUpload(uploadFile);
        }

        try {
            await saveUpload(apiPath, uploadFile);
            loadFileStatus();
        } catch (e) {
            const fileName = parsedFile ? parsedFile.name : undefined;

            if (e instanceof FileError) {
                e.fileName = fileName;
                e.fileConfig = {
                    parsedFile,
                    model: parseConfig.model,
                };
                setError(e);
            } else {
                console.error(`Unhandled exception: ${e}`);
                setError(
                    new FileError('File failed to upload', fileName, {
                        parsedFile,
                        model: parseConfig.model,
                    })
                );
            }
        }

        setShowSpinner(false);
        setParsedFile(undefined);
    };

    const retryErroredRows = async (fileID?: number) => {
        if (!fileID) return;

        setShowSpinner(true);
        const res = await patchUpload(apiPath, fileID, parsedFile);

        if (res) {
            setShowSpinner(false);
            setParsedFile(undefined);
        }
    };

    if (!templateFlowEnabled) {
        if (!templateSection) {
            templateSection = templateSection = <DefaultTemplateSection templateName={altTemplate || apiPath} label={label} />;
        }
    } else {
        templateSection = (
            <Fetcher
                apiPath={apiPath}
                entityName={entityName}
                isConnectEntity={isConnectEntity}
                entityCSVModel={entityCSVModel}
                unitIdsLimit={unitIdsLimit}
                customFilter={customFilter}
                altTemplate={altTemplate}
            ></Fetcher>
        );
    }

    function handleChange(e: any) {
        const target = e.target as HTMLInputElement;
        setAcquisitionId(target.value);
    }

    return (
        <>
            {parsedFile && (
                <ConfirmModal
                    onCancel={cancel}
                    onRetry={retry}
                    onSave={saveUploadedFile}
                    onPatchRetry={retryErroredRows}
                    config={parseConfig}
                    file={parsedFile}
                    fixSpecs={fixSpecs}
                    showSpinner={showSpinner}
                />
            )}
            <div id={id}>
                <RequiresRole requiredRole={requiredRole}>
                    {templateSection}
                    {id === 'unit-upload-view' ? (
                        <select name={'acquisition'} id={'acquisition'} onChange={e => handleChange(e)}>
                            <option key={'-1'} value={'0'}>
                                {'Select acquisition'}
                            </option>
                            <option key={'0'} value={'-1'}>
                                {'WITHOUT AN ACQUISITION'}
                            </option>
                            {acquisitionCollection.map((acq, index) => {
                                return (
                                    <option key={acq['id']} value={acq['id']}>
                                        {acq['attributes']['name']}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        <React.Fragment />
                    )}

                    <Uploader
                        parseConfig={parseConfig}
                        onUpload={upload}
                        fileError={error}
                        templateFlowEnabled={templateFlowEnabled}
                        additionalData={{ acqId: acquisitionId }}
                        idView={id}
                    />
                </RequiresRole>
                <h2>Status</h2>
                <RequiresRole requiredRole={requiredRole}>
                    {uploadedFiles ? (
                        <>
                            {showDownloadSpinner ? (
                                <div className="center-loading-spinner">
                                    <h3>Downloading File</h3>
                                    <Spinner size={64} />
                                </div>
                            ) : (
                                <StatusTable
                                    requestReload={loadFileStatus}
                                    items={uploadedFiles}
                                    idField={uploadType + '_batch_id'}
                                    viewFiles={viewUploadFiles}
                                    uploadType={uploadType}
                                    handleFileHistoryClick={handleFileHistoryClick}
                                />
                            )}
                        </>
                    ) : (
                        <div id={`${id}-status-loader`} className="center-loading-spinner flex-column">
                            <div>
                                <p>Retrieving File Upload Statuses</p>
                            </div>
                            <div>
                                <Spinner />
                            </div>
                        </div>
                    )}
                    {isFileModalActive && (<Modal className="fileModal" onClose={() => {setIsFileModalActive(false)}}>
                        <h2 id="csv-file-name">Import Tool</h2>
                        <div className="center-loading-spinner">
                            {fileModalMessage}
                            <Button onClick={() => setIsFileModalActive(false)}  type="secondary" label="Ok" />
                        </div>
                    </Modal>)}
                </RequiresRole>
            </div>
        </>
    );
};
