import {
    DataType,
    CommonField,
    unitIdField,
    emptyRow,
    createdByField,
    isNullorEmpty,
} from '../util';
import {
    getTemplateVersionIDs,
    getFormIDs,
    getChannelFees,
    getAmendmentByNoticeIDs,
    getExpenseDebitMethodIDs,
} from './api';
import { LookupCache } from '../util/lookup-cache';
import {onlyEmail} from "../units/parsers";
import {validateServiceType} from "./parser";

import moment from 'moment';

/**
 * Unique contact field names required by the Connect API.
 */
export const Field = {
    PercentOwnership: 'percentage_ownership',
    TaxOwnership: 'tax_ownership',
    /**
     * Format is `YYYY-MM-DD`. `start_date` and `end_date` must not overlap
     * existing contracts associated with the given `unit_id`.
     */
    StartDate: 'start_date',
    EndDate: 'end_date',
    /**
     * must be <: 1; `management_fee` minus referral_discount MUST be >: 0
     */
    ManagementFee: 'management_fee',
    /**
     * Corresponds to “Owner Referral Eligible” on Contract page in Admin
     */
    ReferralEligible: 'referral_eligible',
    /**
     * `referral_discount` *must* be `0` if `referral_eligible` is `false`.
     * `management_fee` minus `referral_discount` *must* be >: `0`. Corresponds
     * to “Owner Referral Discount” on Contract page in Admin. Default is `0`.
     */
    ReferralDiscount: 'referral_discount',
    /**
     * Corresponds to “Fixed Monthly Rent” on Contract page in Admin
     */
    MonthlyRent: 'monthly_rent',
    /**
     * Foreign key to table contract_template_version, corresponds to “Template
     * Version” on Contract page in Admin. Default is `1`.
     */
    TemplateVersionID: 'template_version_id',
    /**
     * Foreign key to table contract_form, corresponds to “Contract Form” on
     * Contract page in Admin. Default is `1`.
     * @see https://www.vacasa.com/admin/dashboard/finance/contract/edit?ContractID:36085.
     */
    FormID: 'form_id',
    /**
     * Foreign key to table contract_channel_fee_cost_sharing, corresponds to
     * “Channel Fee Cost Sharing” on Contract page in Admin. Defalut is `5`.
     */
    ChannelFeeCostSharingID: 'channel_fee_cost_sharing_id',
    /**
     * Foreign key to table `contract_amendment_by_notice`, corresponds to
     * “Amendment by Notice” on Contract page in Admin. Default is `4`.
     */
    AmendmentByNoticeID: 'amendment_by_notice_id',
    /**
     * Foreign key to table expense_debit_method, corresponds to
     * "Expense Debit Method" on Contract page in Admin. Default is `1`.
     */
    ExpenseDebitMethodID: 'expense_debit_method_id',

    ServiceTypeID: 'contract_service_type_id',

    Owners: 'owners',

    SecuredBy: 'secured_by',

};

export const DATE_FORMAT = 'MM/DD/YYYY';

const DATE_FORMAT_ENDPOINT = 'YYYY-MM-DD';
const STRICT_DATE_FORMAT = true;

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Contracts/paths/~1v1~1contracts/post
 * @see https://vacasait.atlassian.net/browse/GROW-806
 * @see https://docs.google.com/spreadsheets/d/1fs_noswYJqnzSHLXyQU67D0Zrb0tYWDTBAcLeQCIweU/edit?ts=5d1a9195#gid=1531639576
 */
const contractModel: Model = [
    unitIdField(true),
    {
        name: CommonField.Email,
        label: 'Owner E-mail',
        type: DataType.Email,
        required: true,
    },
    {
        name: Field.PercentOwnership,
        label: '% Ownership',
        type: DataType.Percent,
        required: true,
    },
    {
        name: Field.TaxOwnership,
        label: 'Tax Ownership',
        type: DataType.Percent,
        required: true,
    },
    {
        name: Field.StartDate,
        label: 'Start Date',
        type: DataType.Text,
    },
    {
        name: Field.EndDate,
        label: 'End Date',
        type: DataType.Text,
    },
    {
        name: Field.ManagementFee,
        label: 'Management Fee',
        type: DataType.Percent,
        required: true,
    },
    {
        name: Field.ReferralEligible,
        label: 'Owner Referral Eligible',
        type: DataType.Bit,
    },
    {
        name: Field.ReferralDiscount,
        label: 'Owner Referral Discount',
        type: DataType.Percent,
    },
    {
        name: Field.MonthlyRent,
        label: 'Monthly Rent',
        type: DataType.Float,
    },
    {
        name: Field.TemplateVersionID,
        label: 'Template Version ID',
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.FormID,
        label: 'Form ID',
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.ChannelFeeCostSharingID,
        label: 'Channel Fee Cost Sharing ID',
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.AmendmentByNoticeID,
        label: 'Amendment by Notice ID',
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.ExpenseDebitMethodID,
        label: 'Expense Debit Method ID',
        type: DataType.PositiveInteger,
        required: true
    },
    {
        name: Field.ServiceTypeID,
        label: 'Service Type ID',
        type: DataType.PositiveInteger,
        required: true,
    },
    createdByField(),
    {
        name: Field.SecuredBy,
        label: 'Secured By',
        type: DataType.Text,
        required: true,
        parse: onlyEmail
    },
];

function shallowCopy(row: ParsedRow): ParsedRow {
    const out = emptyRow();
    Object.keys(row).forEach(key => (out[key] = row[key]));
    return out;
}

/**
 * If an error involves more than one field and should be handled differently
 * than individual field errors then create an error code to store in
 * `row._invalid` rather than field names.
 */
export const RowError = {
    ReferralDiscount: 'referral_discount_and_mgmt_fee',
    InvalidDateFormat: 'invalid_date_format',
    StartDateRequired: 'start_date_required',
    EndDateRequired: 'end_date_required',
    InvalidServiceType: 'invalid_service_type',
};

const lookup = {
    templateVersionID: new LookupCache(
        Field.TemplateVersionID,
        getTemplateVersionIDs
    ),
    costSharingID: new LookupCache(
        Field.ChannelFeeCostSharingID,
        getChannelFees
    ),
    amendmentID: new LookupCache(
        Field.AmendmentByNoticeID,
        getAmendmentByNoticeIDs
    ),
    formID: new LookupCache(Field.FormID, getFormIDs),
    expenseDebitMethodID: new LookupCache(
        Field.ExpenseDebitMethodID,
        getExpenseDebitMethodIDs
    ),
};

export const parseConfig: ParseConfig = {
    model: contractModel,
    skipRows: 2,
    onRowParse: row => {
        const discount: number = row[Field.ReferralDiscount];
        const eligible: boolean = row[Field.ReferralEligible] === 1;
        const fee: number = row[Field.ManagementFee];

        if ((!eligible && discount > 0) || discount > fee) {
            row._invalid.add(RowError.ReferralDiscount);
        }

        // Validate Start and End date required
        const missingStartDate: boolean = isNullorEmpty(row[Field.StartDate]);
        const missingEndDate: boolean = isNullorEmpty(row[Field.EndDate]);
        if (missingStartDate) row._required.add(RowError.StartDateRequired);
        if (missingEndDate) row._required.add(RowError.EndDateRequired);

        if (!(missingStartDate || missingEndDate)) {
            // Validate Start and End date format
            const invalidStartDateFormat: boolean = !moment(row[Field.StartDate], DATE_FORMAT, STRICT_DATE_FORMAT).isValid();
            const invalidEndDateFormat: boolean = !moment(row[Field.EndDate], DATE_FORMAT, STRICT_DATE_FORMAT).isValid();

            if (invalidStartDateFormat || invalidEndDateFormat) {
                row._invalid.add(RowError.InvalidDateFormat);
            }
        }
    },
    onComplete: async file => {
        /** Tax and percent ownership keyed to unit ID */
        const known: Map<number, [number, number]> = new Map();

        file.rows.forEach(r => {
            const unitID: number = r[CommonField.UnitID];

            if (!known.has(unitID)) {
                known.set(
                    unitID,
                    // total tax and ownership percent for same unit ID
                    file.rows
                        .filter(m => m[CommonField.UnitID] === unitID)
                        .reduce(
                            (sum, m) => [
                                sum[0] + m[Field.TaxOwnership],
                                sum[1] + m[Field.PercentOwnership],
                            ],
                            [0, 0]
                        )
                );
            }
            const [tax, own] = known.get(unitID)!;

            if (tax !== 1) r._invalid.add(Field.TaxOwnership);
            if (own !== 1) r._invalid.add(Field.PercentOwnership);
        });

        validateServiceType(...file.rows);
        await Promise.all([
            lookup.templateVersionID.validate(file.rows),
            lookup.formID.validate(file.rows),
            lookup.costSharingID.validate(file.rows),
            lookup.amendmentID.validate(file.rows),
            lookup.expenseDebitMethodID.validate(file.rows),
        ]);
    },

    /**
     * Combine owner-specific columns into an object array before uploading in
     * order to match the Connect API
     */
    async beforeUpload(file) {
        const removeRow = [];
        const u = CommonField.UnitID;
        const o = Field.Owners;

        file.rows.forEach(r => {
            r[o] = [
                {
                    [CommonField.Email]: r[CommonField.Email],
                    [Field.TaxOwnership]: r[Field.TaxOwnership],
                    [Field.PercentOwnership]: r[Field.PercentOwnership],
                },
            ];
            delete r[CommonField.Email];
            delete r[Field.TaxOwnership];
            delete r[Field.PercentOwnership];
        });

        // sort so same unit IDs are adjacent
        file.rows.sort((r1, r2) => r1[u] - r2[u]);

        // copy owners of same unit to single row
        for (let i = file.rows.length - 2; i >= 0; i--) {
            const r1 = file.rows[i];
            const r2 = file.rows[i + 1];

            if (r1[u] === r2[u]) {
                removeRow.push(i + 1);

                // push owners to previous row (more than one owner only if this
                // row previously had owners pushed to it)
                r1[o].push(...r2[o]);
            }
        }
        // remove rows that had owner copied to other row
        removeRow.forEach(i => file.rows.splice(i, 1));

        // Change Start and End date format to match endpoint format
        file.rows.forEach(r => {
            r[Field.StartDate] = moment(r[Field.StartDate], DATE_FORMAT, STRICT_DATE_FORMAT).format(DATE_FORMAT_ENDPOINT);
            r[Field.EndDate] = moment(r[Field.EndDate], DATE_FORMAT, STRICT_DATE_FORMAT).format(DATE_FORMAT_ENDPOINT);
        });
    },

    /**
     * Restore separate rows for each owner to match the CSV layout
     */
    async beforeDownload(rows: ParsedRow[]) {
        const addRow: ParsedRow[] = [];
        const o = Field.Owners;

        rows.forEach(r => {
            let j = 0;
            while (r[o].length > 1) {
                const owner = r[o].pop();
                const newRow = shallowCopy(r);
                Object.assign(newRow, owner);
                delete newRow[o];
                newRow._index = r._index + ++j;
                addRow.push(newRow);
            }
            const owner = r[o].pop();
            Object.assign(r, owner);
            delete r[o];
        });

        rows.push(...addRow);
        rows.sort((r1, r2) => r1[CommonField.UnitID] - r2[CommonField.UnitID]);

        // Change Start and End date format to align with US locale
        rows.forEach(r => {
            r[Field.StartDate] = moment(r[Field.StartDate], DATE_FORMAT_ENDPOINT, STRICT_DATE_FORMAT).format(DATE_FORMAT);
            r[Field.EndDate] = moment(r[Field.EndDate], DATE_FORMAT_ENDPOINT, STRICT_DATE_FORMAT).format(DATE_FORMAT);
        });
    },
};
