import { getURL, parseJSON, userHeaders } from '../api';

/**
 * Fetch language preferences
 */
export async function getLanguagePreferences(): Promise<Cacheable[] | null> {
    const url = getURL('/contacts/language-preferences');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });
    if (res.ok) {
        return parseJSON<Cacheable[]>(res, url);
    } else {
        console.error('Failed to fetch lanauge preferences');
        return null;
    }
}
