import { parseDateText, parseField, Pattern } from './parse-field';
import { pairDelimiter } from '../constants';
import { DataType } from './data-types';

/**
 * CSV file error
 */
export class FileError extends Error {
    fileName: string | undefined;
    fileConfig: any;

    constructor(message: string, fileName?: string, fileConfig: any = null) {
        super(message);
        this.fileName = fileName;
        this.fileConfig = fileConfig;
    }
}

/**
 * Whether set contains any array items
 */
export const intersect = (set: Set<string>, list: string[]): boolean =>
    list.findIndex(i => set.has(i)) >= 0;

/**
 * Whether row has errors
 * @param fields Optional list of field names to check only those.
 * The result will be `true` if *any* field in the list has an error.
 */
export const rowHasError = (row: ParsedRow, fields?: string[]): boolean =>
    fields
        ? intersect(row._invalid, fields) ||
          intersect(row._required, fields) ||
          intersect(row._unhandled, fields)
        : row._invalid.size > 0 ||
          row._required.size > 0 ||
          row._unhandled.size > 0;

/**
 * Clear existing errors before re-validating
 * @param fields Optional list of field names to clear only those
 */
export const clearRowErrors = (row: ParsedRow, fields?: string[]) => {
    if (fields && fields.length > 0) {
        fields.forEach(f => {
            row._invalid.delete(f);
            row._required.delete(f);
            row._unhandled.delete(f);
            if (row._totals_off) row._totals_off.delete(f);
        });
    } else {
        row._invalid.clear();
        row._required.clear();
        row._unhandled.clear();
        if (row._totals_off) row._totals_off.clear();
    }
};

export function isSemicolonSeparated(csv: string | null, config: ParseConfig): boolean{
    if (csv == null) return false;
    const pattern = /(\;|\r?\n|\r|^)(?:"((?:\\.|""|[^\\"])*)"|([^\;"\r\n]*))/gi;
    const out: CSV = [[]];
    let matches: RegExpExecArray | null = null;
    let rowHasNoData = true;

    while ((matches = pattern.exec(csv))) {
        const delimiter = matches[1];
        if (delimiter.length && delimiter !== ';') {
            if (rowHasNoData) out.pop();
            rowHasNoData = true;
            out.push([]);
        }
        const content = matches[2]
            ?
            matches[2].replace(/[\\""](.)/g, '$1')
            :
            matches[3];

        if (content) rowHasNoData = false;

        out[out.length - 1].push(content);
    }
    if (rowHasNoData) out.pop();

    return (out[1].length == config.model.length);
}


/**
 *      Delimiter: (,|\r?\n|\r|^)
 *   Quoted field: (?:"([^"]*(?:""[^"]*)*)"
 * Standard field: ([^",\r\n]*))
 *
 * @see https://gist.github.com/plbowers/7560ae793613ee839151624182133159
 */
export function csvToArray(csv: string): CSV {
    /* eslint-disable no-useless-escape */
    const pattern = /(\,|\r?\n|\r|^)(?:"((?:\\.|""|[^\\"])*)"|([^\,"\r\n]*))/gi;
    const out: CSV = [[]];
    let matches: RegExpExecArray | null = null;
    let rowHasNoData = true;

    while ((matches = pattern.exec(csv))) {

        // execute pattern as long as new delimiters and content are found
        const delimiter = matches[1];

        if (delimiter.length && delimiter !== ',') {
            // row delimiter was found so need to add empty row array

            // but first drop current row if it was blank (commas or "" in every field)
            // trying to parse rows of empty data causes errors on required fields
            if (rowHasNoData) out.pop();

            rowHasNoData = true;
            out.push([]);
        }
        const content = matches[2]
            ? // quoted content -- replace any escaped quotes
              matches[2].replace(/[\\""](.)/g, '$1')
            : // unquoted content
              matches[3];

        if (content) rowHasNoData = false;

        out[out.length - 1].push(content);
    }
    // last row needs to be checked for emptitude also
    if (rowHasNoData) out.pop();

    return out;
}

/**
 * Parse comma separated text file
 *
 * @param fileName Name of CSV file
 * @param csv File content
 * @throws {FileError}
 */
export async function parseCSV(
    fileName: string,
    csv: string | null,
    config: ParseConfig,
    additionalData?: any
): Promise<ParsedFile> {

    if (csv?.includes('\\'))  throw new FileError('Row contains a \\ which must be removed or replaced.', fileName);

    if (isSemicolonSeparated(csv, config)) throw new FileError('File is separated by semicolons instead of commas, please update file.', fileName);

    const rows: CSV = csv ? csvToArray(csv) : [[]];

    if (config.beforeParse) await config.beforeParse(rows);

    if (config.skipRows) {
        for (let i = 0; i < config.skipRows && rows.length > 0; i++) {
            rows.shift();
        }
    }

    if (rows.length === 0) {
        throw new FileError('File contains no data', fileName);
    }

    const parsed: ParsedFile = {
        name: fileName,
        fromDB: false,
        rows: rows.map((r, i) => parseRowCSV(r, i + 1, config, fileName, additionalData)),
    };

    if (config.onComplete) await config.onComplete(parsed);

    return parsed;
}

/**
 * Create intermediate rows from database records
 */
export const unwrapRecords = <T extends UploadResponse>(
    records: T[],
    uploadType: string
): ParsedRow[] =>
    records.map(r => ({
        // @ts-ignore
        // @ts-ignore
        ...r[uploadType + '_record_object'],
        // @ts-ignore
        _index: r[uploadType + '_record_id'],
        _unhandled: new Set(parseApiErrors(r['error'])),
        _response: r['vacasa_connect_object']
    }));

/**
 * Re-parse file that was previously uploaded through API
 *
 * @param name File name saved with database record
 * @param records Records returned from query
 * @param uploadType Database field name prefix
 */
export async function parseQueryResult<T extends UploadResponse>(
    name: string,
    records: T[],
    config: ParseConfig,
    uploadType: string,
    fileID: number
): Promise<ParsedFile> {
    const apiRows = unwrapRecords(records, uploadType);
    if (config.beforeDownload) await config.beforeDownload(apiRows);

    const parsed: ParsedFile = {
        name,
        fromDB: true,
        id: fileID,
        rows: apiRows.map(r => parseRowDB(r, config)),
    };
    if (config.onComplete) await config.onComplete(parsed);

    return parsed;
}

/**
 * Each row field becomes a property in the `out` object. If value is
 * required (but missing) or invalid then the field name is added to a
 * `_required` or `_invalid` list
 */
export const emptyRow = (index = 0): ParsedRow => ({
    _required: new Set(),
    _invalid: new Set(),
    _unhandled: new Set(),
    _totals_off: new Map(),
    _response: null,
    _index: index,
});

/**
 * Assign and validate field value according to spec
 */
export function assignValue(
    row: ParsedRow,
    value: string | { [key: string]: string }[],
    spec: FieldSpec
): ParsedRow {
    if (spec.type === DataType.Table) {
        if (!spec.table) {
            console.error(
                `${spec.name} defined as a table but has no table configuration`
            );
            return row;
        }
        row[spec.name] = parseTableValue(value, spec.table.fields);
    } else {
        row[spec.name] = value;
        parseField(row, spec);
    }
    return row;
}

/**
 * Convert key/values to sub-table rows
 * @param value Formatted as `key1=value1;key2=value2`
 */
export function parseTableValue(
    value: string | { [key: string]: string }[],
    specs: FieldSpec[]
): ParsedRow[] | null {
    if (!value) return null;
    /** Sub-table rows */
    const rows: ParsedRow[] = [];
    /** Whether at least one row is valid */
    let valid = false;
    if (Array.isArray(value)) {
        // value has already been parsed and stored as array of objects
        value.forEach((v, i) => {
            const r = emptyRow(i);
            specs.forEach(s => {
                r[s.name] = v[s.name];
                parseField(r, s);
            });
            rows.push(r);
        });
        valid = true;
    } else {
        if (typeof value === "object") {
            let parsedValue = value as any;
            parsedValue = Object.keys(parsedValue).map(function(key) {
                return `${key}=${parsedValue[key]}`;
            }).join(';');
            value = parsedValue as string;
        }
        // convert string to rows array
        const lines = value.trim().split(pairDelimiter);
        lines.forEach((l, i) => {
            if (l.length === 0) return;

            const r = emptyRow(i);
            const values: string[] | null = l.includes('=')
                ? l.split('=')
                : null;

            // reject any "line" that can't be split into key/value
            if (!values) return;

            valid = true;

            specs.forEach((s, i) => {
                if (i < values.length) r[s.name] = values[i];
                parseField(r, s);
            });
            rows.push(r);
        });
    }
    return valid ? rows : null;
}

/**
 * Read values from CSV and apply validator methods from `FieldSpec`
 *
 * @param index One-based index so user can match with row in CSV
 * @param fileName File name used only to log errors
 * @throws {FileError}
 */
export function parseRowCSV(
    values: string[],
    index: number,
    config: ParseConfig,
    fileName: string,
    additionalData?: any
): ParsedRow {
    const row: ParsedRow = emptyRow(index);
    if (values.length !== config.model.length) {
        throw new FileError(
            `Number of columns in file (${values.length}) does not match the expected number of columns (${config.model.length})`,
            fileName
        );
    }

    values.forEach((value, i) => {
        if (i >= config.model.length) {
            throw RangeError(`CSV column ${i + 1} has no mapping`);
        }
        const spec: FieldSpec = config.model[i];

        // return without assigning field to output
        if (spec.omit === true) return;

        assignValue(row, value, spec);
    });

    if (config.onRowParse) config.onRowParse(row, additionalData);

    return row;
}

/**
 * Read values from database record and apply validator methods from
 * `FieldSpec`
 *
 * @param apiRow Row as it was stored in database
 */
export function parseRowDB(apiRow: ParsedRow, config: ParseConfig): ParsedRow {
    const handledErrors: string[] = [];
    const row: ParsedRow = emptyRow(apiRow._index);
    /**
     * Full list of errors returned from API. These will be normalized into
     * known field errors or left as unhandled.
     */
    const errors = Array.from(apiRow._unhandled);

    for (let name in apiRow) {
        const value = apiRow[name];
        const spec: FieldSpec | undefined = config.model.find(
            m => m.name === name
        );
        if (!spec) continue;
        assignValue(row, value, spec);

        errors
            // assign API errors matching field specification
            .filter(e => spec.apiErrorPattern && spec.apiErrorPattern.test(e))
            .forEach(e => {
                row._invalid.add(spec.name);
                handledErrors.push(e);
            });
    }

    if (config.successModel) {
        config.successModel.forEach((m: FieldSpec) => {
            const value = apiRow[m.name];
            const spec: FieldSpec | undefined = m;

            assignValue(row, value, spec);
        })
    }


    // Assign as "unhandled" any API errors weren't matched to a field or
    // error code, meaning there's no fix view defined for the error
    errors
        .filter(e => !handledErrors.includes(e))
        .forEach(e => row._unhandled.add(e));

    if (config.onRowParse) config.onRowParse(row, {downloadErrors: true});

    return row;
}

/**
 * Extract pertinent error messages from API response
 */
export function parseApiErrors(apiErrors: UploadError[]): string[] {
    if (!apiErrors) return [];

    const out: string[] = [];

    apiErrors.forEach(e => {
        if (typeof e === 'string') {
            out.push(e);
        } else if (e !== null){
            if (Array.isArray(e.errors)) {
                e.errors.forEach(rowError => {
                    if (rowError.meta) {
                        const meta = rowError.meta;

                        if (Array.isArray(meta)) {
                            meta.forEach(m => out.push(...m.validation));
                        } else {
                            const extra = meta.validation;

                            for (let key in extra) {
                                // TODO: this could likely be improved since this key
                                // appears to directly match the model field name
                                let msg = extra[key];
                                out.push(Array.isArray(msg) ? msg[0] : msg);
                            }
                        }
                    } else {
                        out.push(rowError.detail);
                    }
                });
            }
        } else {
            out.push("");
        }

    });

    return out;
}

/**
 * Format text as currency (two decimal places) without currency symbol
 */
export function formatCurrency(v: string | number | null): string {
    if (typeof v !== 'number' && !v) return '';

    let n = 0;

    if (typeof v == 'number') {
        n = v;
    } else if (Pattern.Decimals.test(v)) {
        n = parseFloat(v);
    } else {
        // return non-currency value as is so user can see what it was to make
        // possible correction
        return v;
    }
    return n.toFixed(2);
}

/**
 * @see https://www.jacklmoore.com/notes/rounding-in-javascript/
 */
export const round = (value: number, decimals = 0): number =>
    // @ts-ignore: exponent notation
    Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);

/**
 * Convert value to float or 0 if it can't be converted
 */
export const monetize = (v: string | null | undefined): number =>
    v && Pattern.Decimals.test(v) ? parseFloat(v) : 0;

/**
 * Create `Date` instance from YYYY-MM-DD with time set to noon. This can be
 * used to compare dates in a standard way.
 *
 * @param start Whether to treat as start date (affects time value)
 */
export const parseReservationDate = (
    v: string | null | undefined,
    start = true
): Date | null => parseDate(v, start ? 14 : 10);

/**
 * Create `Date` instance from YYYY-MM-DD with time set to noon. This can be
 * used to compare dates in a standard way.
 */
export const parseDate = (
    v: string | null | undefined,
    hour = 0,
    minute = 0
): Date | null => {
    if (!v) return null;
    const [valid, d] = parseDateText(v);
    if (!valid) return null;

    const match = Pattern.Date.exec(d);
    return !match
        ? null
        : new Date(
              parseInt(match[1]),
              parseInt(match[2]) - 1,
              parseInt(match[3]),
              hour,
              minute,
              0,
              0
          );
};
