import { getURL, parseJSON, userHeaders } from '../api';

/**
 * Fetch contract template versions
 */
export async function getTemplateVersionIDs(): Promise<Cacheable[] | null> {
    const url = getURL('/contracts/template-versions');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        console.error('Failed to fetch contract template versions.');
        return null;
    }
}

/**
 * Fetch contract forms
 */
export async function getFormIDs(): Promise<Cacheable[] | null> {
    const url = getURL('/contracts/forms');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        console.error('Failed to fetch contract forms.');
        return null;
    }
}

/**
 * Fetch contract channel fee cost sharings
 */
export async function getChannelFees(): Promise<Cacheable[] | null> {
    const url = getURL('/contracts/channel_fee_cost_sharings');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        console.error('Failed to fetch contract channel fee cost sharings.');
        return null;
    }
}

/**
 * Fetch contract amendment by notices
 */
export async function getAmendmentByNoticeIDs(): Promise<Cacheable[] | null> {
    const url = getURL('/contracts/amendment-by-notices');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        console.error('Failed to fetch contract amendment by notices.');
        return null;
    }
}

/**
 * Fetch expense debit methods
 */
export async function getExpenseDebitMethodIDs(): Promise<Cacheable[] | null> {
    const url = getURL('/contracts/expense-debit-methods');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        console.error('Failed to fetch expense debit methods.');
        return null;
    }
}
