import { CommonField, DataType, unitIdField } from '../util';
import { verifyUnitIDs } from '../util/parse-field';
import {
    validateEndDate,
    validateRestrictionDuration,
    validateRestrictionDurationTypes,
    validateRestrictionEffectiveDate, validateRestrictionEffectiveEndDate,
    validateRestrictionEffectiveStartDate,
    validateRestrictionEffectiveTypes,
    validateRestrictionTypes,
    validateStartDate
} from './parsers';

/**
 * Unique field names required by the Unit service API.
 */
export const Field = {
    RecordId: {
        name: 'id',
        label: 'Record ID'
    },
    RestrictionType: {
        name: 'restriction_type',
        label: 'Restriction Type',
    },
    RestrictionNumber: {
        name: 'restriction_number',
        label: 'Restriction Number',
    },
    RestrictionDurationType: {
        name: 'restriction_duration_type',
        label: 'Restriction Duration Type',
    },
    RestrictionDuration: {
        name: 'restriction_duration',
        label: 'Restriction Duration',
    },
    RestrictionEffectiveType: {
        name: 'restriction_effective_type',
        label: 'Restriction Effective Type',
    },
    RestrictionEffectiveStartDate: {
        name: 'restriction_effective_start_date',
        label: 'Restriction Effective Start Date',
    },
    RestrictionEffectiveEndDate: {
        name: 'restriction_effective_end_date',
        label: 'Restriction Effective End Date',
    },
    Notes: {
        name: 'notes',
        label: 'Notes',
    },
    StartDate: {
        name: 'start_date',
        label: 'Start Date',
    },
    EndDate: {
        name: 'end_date',
        label: 'End Date',
    },
}

export const RowError = {
    InvalidRestrictionType: 'invalid_restriction_type',
    RestrictionNumberRequired: 'restriction_number_required',
    InvalidRestrictionDurationType: 'invalid_restriction_duration_type',
    InvalidRestrictionEffectiveType: 'invalid_restriction_effective_type',
    InvalidRestrictionEffectiveStartDate: 'invalid_restriction_effective_start_date',
    InvalidRestrictionEffectiveEndDate: 'invalid_restriction_effective_end_date',
    InvalidStartDate: 'invalid_start_date',
    StartDateRequired: 'start_date_required',
    InvalidEndDate: 'invalid_end_date',
    NotesRequired: 'notes_required',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Unit Service API:
 */
const resRuleModel: Model = [
    unitIdField(true),
    {
        name: Field.RecordId.name,
        label: Field.RecordId.label,
        type: DataType.Text,
        required: false
    },
    {
        name: Field.RestrictionType.name,
        label: Field.RestrictionType.label,
        type: DataType.Text,
        required: true,
        parse: validateRestrictionTypes
    },
    {
        name: Field.RestrictionNumber.name,
        label: Field.RestrictionNumber.label,
        type: DataType.PositiveInteger,
        required: true
    },
    {
        name: Field.RestrictionDurationType.name,
        label: Field.RestrictionDurationType.label,
        type: DataType.Text,
        required: true,
        parse: validateRestrictionDurationTypes
    },
    {
        name: Field.RestrictionDuration.name,
        label: Field.RestrictionDuration.label,
        type: DataType.PositiveInteger,
        required: false
    },
    {
        name: Field.RestrictionEffectiveType.name,
        label: Field.RestrictionEffectiveType.label,
        type: DataType.Text,
        required: true,
        parse: validateRestrictionEffectiveTypes
    },
    {
        name: Field.RestrictionEffectiveStartDate.name,
        label: Field.RestrictionEffectiveStartDate.label,
        type: DataType.Date,
        parse: validateRestrictionEffectiveDate,
        required: false
    },
    {
        name: Field.RestrictionEffectiveEndDate.name,
        label: Field.RestrictionEffectiveEndDate.label,
        type: DataType.Date,
        parse: validateRestrictionEffectiveDate,
        required: false
    },
    {
        name: Field.Notes.name,
        label: Field.Notes.label,
        type: DataType.Text,
        required: true
    },
    {
        name: Field.StartDate.name,
        label: Field.StartDate.label,
        type: DataType.Date,
        required: false
    },
    {
        name: Field.EndDate.name,
        label: Field.EndDate.label,
        type: DataType.Date,
        required: false
    },
]

function formatRecord(r: ParsedRow, currentUnitId: number, formatAttributes: any) {
    return {
        'unit_id': currentUnitId,
        'record_id':  r[Field.RecordId.name],
        'rule_code': 'UNITSTAYRESTRICTION',
        'value_code': 'ENROLLED',
        'notes': r[Field.Notes.name],
        'start_date': r[Field.StartDate.name],
        'end_date': r[Field.EndDate.name],
        'unit_rule_value_attributes': {
            'enrolled': [
                formatAttributes
            ]
        }
    }
}

export const parseConfig: ParseConfig = {
    model: resRuleModel,
    skipRows: 2,
    async onComplete(file: ParsedFile) {
        verifyUnitIDs(...file.rows);
        validateRestrictionDuration(...file.rows);
        validateStartDate(...file.rows);
        validateEndDate(...file.rows);
        validateRestrictionEffectiveStartDate(...file.rows)
        validateRestrictionEffectiveEndDate(...file.rows)
    },
    async onRowUpdate(row, fields) {
        verifyUnitIDs(row);
        validateRestrictionDuration(row);
        validateStartDate(row);
        validateEndDate(row);
        validateRestrictionEffectiveStartDate(row)
        validateRestrictionEffectiveEndDate(row)
    },
    async beforeDownload(rows): Promise<void> {
        const originalRows = rows.slice();
        for (const r of originalRows) {
            let errors = [""];
            for (const value of Array.from(r._unhandled)) {
                let error;
                try {
                    error = JSON.parse(value);
                    const msg = error['message'];

                    for (const pos of error['positions']) {
                        if (errors[pos] !== undefined) {
                            if (!errors[pos].includes(msg))
                                errors[pos] = errors[pos].concat(` ${msg}`);
                        } else {
                            errors[pos] = msg;
                        }
                    }
                } catch (e) {
                    errors = [value];
                }
            }

            let position = 1;
            for (const restriction of r.unit_rule_value_attributes['enrolled']) {
                let row = {
                    ...r,
                    'id': r.record_id,
                    'restriction_type': restriction.restriction_type,
                    'restriction_number': restriction.restriction_number,
                    'restriction_duration_type': restriction.restriction_duration_type,
                    'restriction_duration': restriction.restriction_duration,
                    'restriction_effective_type': restriction.restriction_effective_type,
                    'restriction_effective_start_date': restriction.restriction_effective_start_date,
                    'restriction_effective_end_date': restriction.restriction_effective_end_date,
                    '_unhandled': [errors[0] === "" ? errors[position] : errors.toString()]
                } as any;
                delete row['unit_rule_value_attributes'];
                delete row['rule_code'];
                delete row['value_code'];
                rows.push(row);
                position++;
            }
        }

        originalRows.forEach(r => {
            const index = rows.indexOf(r);
            rows.splice(index, 1);
        })
    },
    async beforeUpload(file) {
        const unitIds: any = {};
        const formatRows: ParsedRow[] = [];
        file.rows.forEach(r => {
            const currentUnitId = r[CommonField.UnitID];
            const currentRecordId = r[Field.RecordId.name] ?? 0;
            let formatAttributes = {
                'restriction_type': r[Field.RestrictionType.name].toLowerCase(),
                'restriction_number': r[Field.RestrictionNumber.name],
                'restriction_duration_type': r[Field.RestrictionDurationType.name].toLowerCase(),
                'restriction_effective_type': r[Field.RestrictionEffectiveType.name].toLowerCase()
            } as any;
            if (r[Field.RestrictionDurationType.name].toLowerCase() === 'days') {
                formatAttributes['restriction_duration'] = r[Field.RestrictionDuration.name]
            }

            if (r[Field.RestrictionEffectiveType.name].toLowerCase() === 'custom') {
                formatAttributes['restriction_effective_start_date'] = r[Field.RestrictionEffectiveStartDate.name]
                formatAttributes['restriction_effective_end_date'] = r[Field.RestrictionEffectiveEndDate.name]
            }

            if (currentUnitId in unitIds) {
                if (currentRecordId in unitIds[currentUnitId])
                    unitIds[currentUnitId][currentRecordId]['unit_rule_value_attributes']['enrolled'].push(formatAttributes);
                else
                    unitIds[currentUnitId][currentRecordId] = formatRecord(r, currentUnitId, formatAttributes);
            } else {
                unitIds[currentUnitId] = {
                    [currentRecordId]: formatRecord(r, currentUnitId, formatAttributes)
                };
            }
        });


        for (const [key, units] of Object.entries(unitIds)) {
            for (const [index, record] of Object.entries(unitIds[key])) {
                formatRows.push(record as unknown as ParsedRow);
            }
        }
        file.rows = formatRows;
    },
}