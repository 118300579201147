import { Field, RowError } from './model';
import * as moment from 'moment';
import * as _ from 'lodash';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const RESTRICTION_TYPES = ["check-in", "nights"];
export const RESTRICTION_DURATION_TYPES = ["year", "month", "days"];
export const RESTRICTION_EFFECTIVE_TYPES = ["always", "custom"];

const isValidDateFormat = (dateVal: string) => moment.default(dateVal).format(DATE_FORMAT) === dateVal;
let isValidRestrictionDateFormat = new RegExp(/(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);

export function validateEndDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const endDateVal: string = r[Field.EndDate.name];
        if (!_.isNil(endDateVal) && !isValidDateFormat(endDateVal)) {
            r._invalid.add(RowError.InvalidEndDate);
        }
    });
}

export function validateStartDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const startDate: string = r[Field.StartDate.name];
        if (_.isNil(startDate)) {
            r._invalid.add(RowError.StartDateRequired);
        }
        if (!_.isNil(startDate) && !isValidDateFormat(startDate)) {
            r._invalid.add(RowError.InvalidStartDate);
        }
    });
}

export function validateRestrictionEffectiveStartDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const overrideStartDate: string = r[Field.RestrictionEffectiveStartDate.name];
        if(r[Field.RestrictionEffectiveType.name] == "custom" && _.isNil(overrideStartDate)){
            r._required.add(Field.RestrictionEffectiveStartDate.name);
        }
    });
}

export function validateRestrictionEffectiveEndDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const overrideEndDate: string = r[Field.RestrictionEffectiveEndDate.name];
        if(r[Field.RestrictionEffectiveType.name] == "custom" && _.isNil(overrideEndDate)){
            r._required.add(Field.RestrictionEffectiveEndDate.name);
        }
    });
}

export function validateRestrictionDuration(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const restrictionDuration: string = r[Field.RestrictionDuration.name];
        if(r[Field.RestrictionDurationType.name] == "days" && _.isNil(restrictionDuration)) {
            r._required.add(Field.RestrictionDuration.name);
        }
    });
}

export const validateRestrictionTypes = (value: string): ParseResult<any> => {
    const valid = RESTRICTION_TYPES.includes(value);
    return [valid, valid ? value : 0];
}

export const validateRestrictionDurationTypes = (value: string): ParseResult<any> => {
    const valid = RESTRICTION_DURATION_TYPES.includes(value);
    return [valid, valid ? value : 0];
}

export const validateRestrictionEffectiveTypes = (value: string): ParseResult<any> => {
    const valid = RESTRICTION_EFFECTIVE_TYPES.includes(value);
    return [valid, valid ? value : 0];
}

export const validateRestrictionEffectiveDate = (value: string): ParseResult<any> => {
    const valid = isValidRestrictionDateFormat.test(value);
    return [valid, valid ? value : 0];
}

