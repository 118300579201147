import { Field, RowError } from './model';
import * as moment from 'moment';
import * as _ from 'lodash';

const EXCEPTION_CODE = 'EXCEPTION';
export const RULE_CODE = 'CMPLNC-PERMITS';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const OVERRIDE_NOTES_MIN = 20;

const isValidDateFormat = (dateVal: string) => moment.default(dateVal).format(DATE_FORMAT) === dateVal;
const isException = (valCode: string) => valCode?.trim().toLocaleUpperCase() === EXCEPTION_CODE;
const isRule = (ruleCode: string) => ruleCode && ruleCode === RULE_CODE;
const isValidPermitsNum = (permitsNum: number) => permitsNum >= 1 && permitsNum <= 5;

export function validateEndDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const endDateVal: string = r[Field.EndDate.name];
        if (!_.isNil(endDateVal) && !isValidDateFormat(endDateVal)) {
            r._invalid.add(RowError.InvalidEndDate);
        }
    });
}

export function validateStartDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const startDate: string = r[Field.StartDate.name];
        if (_.isNil(startDate)) {
            r._invalid.add(RowError.StartDateRequired);
        }
        if (!_.isNil(startDate) && !isValidDateFormat(startDate)) {
            r._invalid.add(RowError.InvalidStartDate);
        }
    });
}

export function validateRequiredPermits(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const reqPermitsVal: number = r[Field.RequiredPermits.name];
        if (isException(r[Field.ValueCode.name]) && !isValidPermitsNum(reqPermitsVal)) {
            r._invalid.add(RowError.InvalidRequiredPermits);
        }
    });
}

export function validateRuleCode(...rows: ParsedRow[]) {
    rows.forEach(r => {
        if (!isRule(r[Field.RuleCode.name])) {
            r._invalid.add(RowError.InvalidRuleCode);
        }
    });
}

export function validateOverride(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const override: boolean = r[Field.Override.name];
        if (_.isNil(override) && isException(r[Field.ValueCode.name])) {
            r._invalid.add(RowError.InvalidOverride);
        }
    });
}

export function validateOverrideEffectiveDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const overrideEffectiveDate: string = r[Field.OverrideEffectiveDate.name];
        const override: boolean = r[Field.Override.name];
        if (!_.isNil(overrideEffectiveDate) && !isValidDateFormat(overrideEffectiveDate)) {
            r._invalid.add(RowError.InvalidOverrideEffectiveDate);
        }
        if (_.isNil(overrideEffectiveDate) && override) {
            r._invalid.add(RowError.OverrideEffectiveDateRequired);
        }
    });
}

export function validateOverrideEndDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const overrideEndDate: string = r[Field.OverrideEndDate.name];
        if (!_.isNil(overrideEndDate) && !isValidDateFormat(overrideEndDate)) {
            r._invalid.add(RowError.InvalidOverrideEndDate);
        }
    });
}

export function validateOverrideNotes(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const overrideNotes: string = r[Field.OverrideNotes.name];
        const override: boolean = r[Field.Override.name];
        if (_.isNil(overrideNotes) && override) {
            r._invalid.add(RowError.OverrideNotesRequired);
        }
        if (!_.isNil(overrideNotes) && override && overrideNotes.length < OVERRIDE_NOTES_MIN) {
            r._invalid.add(RowError.InvalidOverrideNotes);
        }
    });
}
