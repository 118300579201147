import { Field } from './model';

const alwaysShow: string[] = [Field.UnitId, Field.ReserveBalance];

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-id',
        label: 'Required Unit Id',
        editFields: [Field.UnitId],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-reserve',
        label: 'Invalid Owner Reserve Balance, should be a positive number',
        editFields: [Field.ReserveBalance],
        showFields: alwaysShow,
    }
];
