import { DataType, unitIdField } from '../util/';
import { verifyUnitIDs } from '../util/parse-field';
import {
    verifyDates,
    parseDamageInsuranceFields,
} from '../programs/parsers';
import { Field as FieldBase, RowError as RowErrorBase } from '../programs/model';

export const Field = {
    ...FieldBase,
    InternalNotes: 'internal_notes',
    OwnerNotes: 'owner_notes',
    Payer: 'payer',
    OptInEffective: 'opt_in_effective',
    OptInReceived: 'opt_in_received',
    PoiEffective: 'poi_effective',
    PoiReceived: 'poi_received',
    OptOutEffective: 'opt_out_effective',
    OptOutReceived: 'opt_out_received'
}

export const RowError = {
    ...RowErrorBase,
}

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#operation/post-reservations-import
 */
const damageInsuranceModel: Model = [
    unitIdField(true),
    {
        name: 'id',
        label: 'Program Id',
        type: DataType.Integer,
        required: false,
    },
    {
        name: Field.StartDate,
        label: 'Start Date',
        type: DataType.Date,
        default: null
    },
    {
        name: Field.EndDate,
        label: 'End Date',
        type: DataType.Date,
    },
    {
        name: Field.IsDraft,
        label: 'Is Draft',
        type: DataType.Boolean,
        // match values required in template
        yesNoLabels: ['TRUE', 'FALSE'],
        required: true
    },
    {
        name: Field.InternalNotes,
        label: 'Internal Notes',
        maxLength: 256,
        type: DataType.MultiLineText,
    },
    {
        name: Field.OwnerNotes,
        label: 'Owner Notes',
        maxLength: 256,
        type: DataType.MultiLineText,
    },
    {
        name: Field.Payer,
        label: 'Payer',
        type: DataType.Text,
    },
    {
        name: Field.OptInEffective,
        label: 'Opt In Effective',
        type: DataType.Date,
    },
    {
        name: Field.OptInReceived,
        label: 'Opt In Received',
        type: DataType.Date,
    },
    {
        name: Field.PoiEffective,
        label: 'Poi Effective',
        type: DataType.Date,
    },
    {
        name: Field.PoiReceived,
        label: 'Poi Received',
        type: DataType.Date,
    },
    {
        name: Field.OptOutEffective,
        label: 'Opt Out Effective',
        type: DataType.Date,
    },
    {
        name: Field.OptOutReceived,
        label: 'Opt Out Received',
        type: DataType.Date,
    },
];

export const parseConfig: ParseConfig = {
    model: damageInsuranceModel,
    skipRows: 2,

    /** Verify billing cycle */
    onRowParse(row) {
        verifyDates(row);
    },

    /**
     * Update errors when row values change
     */
    onRowUpdate(row, fields) {
        parseDamageInsuranceFields([row]);
        this.onRowParse!(row);
        return verifyUnitIDs(row);
    },

    onComplete: async file => {
        parseDamageInsuranceFields(file.rows);
        await Promise.all([verifyUnitIDs(...file.rows)]);
    },
};
