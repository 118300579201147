import {parseNumber} from "../util";


/** validate numeber > 0 **/
const greaterThanZeroPattern = /^[1-9][0-9]*$/;

/** validate only a-z A-Z characters **/
const alphabeticPattern = /^[a-zA-Z\u00C0-\u00FF\s]*$/;

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailOrNumberPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|[0-9]*$/;

/** Hard-coded unit type ids **/
const validUnitTypesIds: number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];

/** validate only a-z A-Z & / - characters **/
const housingTypePattern = /^[&\-\/a-zA-Z\s]*$/;

/**
 * Parse Number > 0
 */
export const greaterThanZero = (value: string) => parseNumber(value, greaterThanZeroPattern);

/**
 * Parse Alphanumeric
 */
export const onlyAlphabetic = (value: string): ParseResult<string> => {
    const valid = alphabeticPattern.test(value);
    return [valid, valid ? value : ""];
};

export const onlyEmail = (value: string): ParseResult<string> => {
    const valid = emailPattern.test(value);
    return [valid, valid ? value : ""];
}

export const onlyEmailOrNumber = (value: string): ParseResult<string> => {
    const valid = emailOrNumberPattern.test(value);
    return [valid, valid ? value : ""];
}

export const onlyValidUnitTypes = (value: number): ParseResult<any> => {
    const valid = validUnitTypesIds.includes(+value);
    return [valid, valid ? value : 0];
}

export const onlyHousingType = (value: string): ParseResult<string> => {
    const valid = housingTypePattern.test(value);
    return [valid, valid ? value : ""];
};

export const onlyPositiveIntegerOrNull = (value: string): ParseResult<any> => {
    if (value === '' || value === null) return [true, null]
    return greaterThanZero(value);
};