import { getURL, parseJSON, userHeaders } from '../api';
import { AmenityProperties, IncludeProperty, AmenityID } from './constants';

/**
 * All amenities from Connect
 */
export async function lookupAmenities(): Promise<Amenity[]> {
    const url = getURL('/unit/amenities-list');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        const list: Amenity[] | null = await parseJSON(res, url);

        if (list) {
            /** Get required amenities */
            const nonRequiredAmenities = await getRequiredAmenities();
            /**
             * Exclude beds since they are handled by separate bed-to-room
             * mapping https://vacasait.atlassian.net/browse/UJM-1404
             */
            const exclude = [
                AmenityID.BunkBed,
                AmenityID.ChildBed,
                AmenityID.Crib,
                AmenityID.DoubleBed,
                AmenityID.Futon,
                AmenityID.KingBed,
                AmenityID.Loft,
                //AmenityID.LowerBed,
                AmenityID.MurphyBed,
                AmenityID.QueenBed,
                AmenityID.Sofabed,
                AmenityID.TwinBed,
                //AmenityID.UpperBed,
            ];

            const nonRequiredAmenitiesIds = nonRequiredAmenities.map(s => parseInt(s.id.toString()));
            list.forEach(function (a, i, arr) {
                a.id = parseInt(a.id.toString());
                a.isRequired = !nonRequiredAmenitiesIds.includes(parseInt(a.id.toString()));
            });
            return list.filter(a => !isNaN(a.id) && !exclude.includes(a.id));
        }
        return [];
    } else {
        console.error('Failed to retrieve amenities');
        return [];
    }
}

export async function getRequiredAmenities(): Promise<Array<any>> {
    const url = getURL('/unit/required-amenities-list');
    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
    });

    if (res.ok) {
        return await res.json();
    } else {
        console.error('Failed to retrieve required amenities');
        return [];
    }
}

/**
 * Properties that are part of every amenity
 */
const standardProperties = (id: number): AmenityProperty[] => {
    // PER https://vacasait.atlassian.net/browse/UJM-1312 We only show the
    // provider ID on a select set of amenities
    const idsToAddProviderID = [AmenityID.Garbage, AmenityID.Cable, AmenityID.Internet];
    const props: AmenityProperty[] = [
        {
            property_id: IncludeProperty.Notes,
            property_name: 'Include notes',
            field_type: 'text',
            isVisible: true,
        },
        // { PER https://vacasait.atlassian.net/browse/UJM-1324: We don't want to show the internal notes fields
        //     property_id: IncludeProperty.InternalNotes,
        //     property_name: 'Include internal notes',
        //     field_type: 'text'
        // },
        {
            property_id: IncludeProperty.Instructions,
            property_name: 'Include instructions',
            field_type: 'text',
            isVisible: true,
        },
    ];
    if (idsToAddProviderID.includes(id)) {
        props.push({
            property_id: IncludeProperty.ProviderID,
            property_name: 'Include provider ID',
            field_type: 'number',
            isVisible: true,
        });
    }

    return props;
};

export const amenityPropertiesForID = async (id: number, returnAll: boolean = true): Promise<AmenityProperty[]> => [
    ...AmenityProperties.filter(p => p.amenity_id === id && (returnAll ? true : p.isVisible)),
    ...standardProperties(id),
];
