/**
 * Psuedo-properties to indicate which amenity standard properties to include
 */
export const IncludeProperty: Record<string, AmenityFixedProps> = {
    Notes: 'notes',
    Instructions: 'instructions',
    InternalNotes: 'internal_notes',
    ProviderID: 'provider_id',
};

export const headerDelimiter: string = '|';
/** Abbreviated list of amenities needing special handling */
export const AmenityID = {
    Garbage: 253,
    Cable: 177,
    Internet: 145,
    Futon: 130,
    Sofabed: 131,
    Crib: 132,
    KingBed: 222,
    QueenBed: 223,
    MurphyBed: 298,
    BunkBed: 312,
    ChildBed: 471,
    UpperBed: 232,
    LowerBed: 231,
    TwinBed: 225,
    DoubleBed: 224,
    Loft: 233,
    DogFriendly: 134,
};
export const AMENITY_PROPERTY_NO_DOGS_DECISION_OPTIONS = [
    "Owner's Preference - Not Interested",
    "Owner's Preference - Damage in Past",
    "Owner's Preference - Allergies",
    "Owner's Preference - Recently Remodeled",
    "Owner's Preference - Issues with Vacasa policy",
    "Owner's Preference - Other",
    'HOA Policy',
    'Zoning/Ordinance/Other Regulations',
    'Unknown',
];
export const RowError = {
    InvalidNoDogDecision: 'invalid_no_dog_decision',
    InvalidMaxDogsProperty: 'invalid_max_dogs_property',
    InvalidInternetSpeed: 'invalid_internet_speed_property',
};
export const AMENITY_PROPERTY_MAX_DOGS_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const MAX_DOGS_PROPERTY_ID: number = 165;
export const NO_DOGS_DECISION_PROPERTY_ID: number = 168;
export const AMENITY_INTERNET_SPEED_OPTIONS = ['<25 Mbps', '25 - 49 Mbps', '50 - 99 Mbps', '100 - 249 Mbps', '250 - 499 Mbps', '500+ Mbps'];
export const LOW_SPEED_OPTIONS = ['<25 Mbps', '25 - 49 Mbps'];
export const INTERNET_SPEED_PROPERTY_ID: number = 166;
export const HIGH_SPEED_PROPERTY_ID: number = 167;

const Name = {
    Location: 'Location',
    MakeModel: 'Make and Model',
    SerialNumber: 'Serial Number',
    KeypadCode: 'Key Pad Code',
    LockCombination: 'Lock Combination',
};

/**
 * Properties mapped to particular amenities. This should be made to come from
 * an authoritative data source but one doesn't currently exist.
 */
export const AmenityProperties: AmenityProperty[] = [
    {
        property_id: 78,
        amenity_id: 121,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 79,
        amenity_id: 121,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 80,
        amenity_id: 121,
        property_name: Name.LockCombination,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 81,
        amenity_id: 121,
        property_name: 'Warranty Info',
        field_type: 'textarea',
        isVisible: true,
    },
    {
        property_id: 82,
        amenity_id: 142,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 83,
        amenity_id: 142,
        property_name: Name.LockCombination,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 84,
        amenity_id: 263,
        property_name: 'Backup Location',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 85,
        amenity_id: 264,
        property_name: 'Locations',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 86,
        amenity_id: 158,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 87,
        amenity_id: 158,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 88,
        amenity_id: 154,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 89,
        amenity_id: 154,
        property_name: 'GasElectric/Ceramic',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 90,
        amenity_id: 154,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 91,
        amenity_id: 156,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 92,
        amenity_id: 153,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 93,
        amenity_id: 153,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 94,
        amenity_id: 265,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 95,
        amenity_id: 265,
        property_name: 'Reset Button Location',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 96,
        amenity_id: 265,
        property_name: 'Switch Location',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 97,
        amenity_id: 266,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 98,
        amenity_id: 266,
        property_name: 'Date of Last Inspection',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 99,
        amenity_id: 226,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 100,
        amenity_id: 226,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 101,
        amenity_id: 226,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 102,
        amenity_id: 180,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 104,
        amenity_id: 177,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 105,
        amenity_id: 145,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 106,
        amenity_id: 145,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 166,
        amenity_id: AmenityID.Internet,
        property_name: 'Internet Speed',
        field_type: 'text',
        helpText: `Options:
<25 Mbps
25 - 49 Mbps
50 - 99 Mbps
100 - 249 Mbps
250 - 499 Mbps
500+ Mbps`,
        isVisible: true,
    },
    {
        property_id: 167,
        amenity_id: AmenityID.Internet,
        property_name: 'High Speed',
        field_type: 'number',
        isVisible: false,
    },
    {
        property_id: 107,
        amenity_id: 267,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 108,
        amenity_id: 267,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 109,
        amenity_id: 267,
        property_name: 'SSID',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 110,
        amenity_id: 267,
        property_name: 'Admin Username/Password',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 111,
        amenity_id: 267,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 112,
        amenity_id: 178,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 114,
        amenity_id: 268,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 115,
        amenity_id: 268,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 116,
        amenity_id: 269,
        property_name: 'Wood/Gas/Pellet',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 117,
        amenity_id: 269,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 118,
        amenity_id: 138,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 120,
        amenity_id: 271,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 121,
        amenity_id: 271,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 122,
        amenity_id: 271,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 123,
        amenity_id: 272,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 124,
        amenity_id: 272,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 125,
        amenity_id: 272,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 126,
        amenity_id: 273,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 127,
        amenity_id: 274,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 128,
        amenity_id: 274,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 129,
        amenity_id: 274,
        property_name: Name.SerialNumber,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 130,
        amenity_id: 137,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 131,
        amenity_id: 137,
        property_name: 'Washer Make and Model',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 132,
        amenity_id: 137,
        property_name: 'Washer Serial Number',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 133,
        amenity_id: 137,
        property_name: 'Dryer Make and Model',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 134,
        amenity_id: 137,
        property_name: 'Dryer Serial Number',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 135,
        amenity_id: 248,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 136,
        amenity_id: 248,
        property_name: Name.MakeModel,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 138,
        amenity_id: 276,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 139,
        amenity_id: 277,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 140,
        amenity_id: 277,
        property_name: 'Type of Specialty Bulb',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 141,
        amenity_id: 278,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 142,
        amenity_id: 279,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 143,
        amenity_id: 279,
        property_name: 'Key Location',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 144,
        amenity_id: 279,
        property_name: 'Combo',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 145,
        amenity_id: 297,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 146,
        amenity_id: 297,
        property_name: 'Bags Location',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 147,
        amenity_id: 297,
        property_name: 'Belts Location',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 148,
        amenity_id: 208,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 149,
        amenity_id: 242,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 150,
        amenity_id: 273,
        property_name: Name.KeypadCode,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 151,
        amenity_id: 291,
        property_name: Name.KeypadCode,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 152,
        amenity_id: 145,
        property_name: 'Network',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 153,
        amenity_id: 145,
        property_name: 'Password',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 154,
        amenity_id: 145,
        property_name: 'Account',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 155,
        amenity_id: 145,
        property_name: 'AccountPW',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 156,
        amenity_id: 253,
        property_name: 'Garbage Day',
        field_type: 'dayofweek',
        isVisible: true,
    },
    {
        property_id: 157,
        amenity_id: 134,
        property_name: 'Weight Limit',
        field_type: 'number',
        isVisible: true,
    },
    {
        property_id: MAX_DOGS_PROPERTY_ID,
        amenity_id: 134,
        property_name: 'Max Dogs',
        field_type: 'number',
        isVisible: true,
    },
    {
        property_id: NO_DOGS_DECISION_PROPERTY_ID,
        amenity_id: 134,
        property_name: 'No Dogs Decision',
        field_type: 'text',
        helpText: `Options:
Owner's Preference - Not Interested
Owner's Preference - Damage in Past
Owner's Preference - Allergies
Owner's Preference - Recently Remodeled
Owner's Preference - Issues with Vacasa policy
Owner's Preference - Other
HOA Policy
Zoning/Ordinance/Other Regulations
Unknown`,
        isVisible: true,
    },
    {
        property_id: 158,
        amenity_id: 177,
        property_name: 'Account Number',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 159,
        amenity_id: 177,
        property_name: 'Account Password',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 160,
        amenity_id: 254,
        property_name: Name.Location,
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 161,
        amenity_id: 283,
        property_name: 'Pickup Day',
        field_type: 'dayofweek',
        isVisible: true,
    },
    {
        property_id: 162,
        amenity_id: 297,
        property_name: 'Make',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 163,
        amenity_id: 297,
        property_name: 'Model',
        field_type: 'text',
        isVisible: true,
    },
    {
        property_id: 164,
        amenity_id: 121,
        property_name: 'Type',
        field_type: 'number',
        isVisible: true,
    },
];
