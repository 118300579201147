import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const UnitsView: FunctionComponent = () => (
    <UploadView
        id="unit-upload-view"
        requiredRole={Role.UnitCreate}
        label="Unit Creation"
        apiPath="unit/units-creation"
        uploadType="unit_create"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
    />
);
