import { Field, RowError } from './model';
import { CommonField } from '../util/';

const alwaysShow: string[] = [CommonField.UnitID];
const dateFields = alwaysShow.concat([Field.StartDate, Field.EndDate]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit ID is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'start-date-is-required',
        label: 'Start date is required',
        editFields: [Field.StartDate],
        showFields: dateFields,
    },
    {
        key: 'end-date-before-start-date',
        label: 'End date before start date',
        editFields: [Field.StartDate, Field.EndDate],
        triggeredBy: [RowError.EndBeforeStart],
        showFields: dateFields,
    },
    {
        key: 'invalid-rest-days',
        label: 'Invalid Rest days',
        editFields: [Field.RestDays],
        showFields: alwaysShow.concat([Field.RestDays]),
    },
    {
        key: 'invalid-reason-id',
        label: 'Invalid reason ID',
        editFields: [Field.ReasonID],
        showFields: alwaysShow.concat([Field.ReasonID]),
    },
    {
        key: 'invalid-start-date',
        label: 'Start date cannot be in the past',
        triggeredBy: [RowError.StartBeforePresent],
        editFields: [Field.StartDate],
        showFields: alwaysShow.concat([Field.StartDate]),
    },
];
