import { Field, RowError } from './model';

export function validateServiceType(...rows: ParsedRow[]) {
    /*
        1 = Guest Work
        2 = Full Service
        3 = Maintenance Only
    */
    rows.forEach(r => {
        if ([1,2,3].includes(r[Field.ServiceTypeID]) == false) {
            r._invalid.add(RowError.InvalidServiceType);
        }
    });
}