import { Field } from './model';
import { CommonField } from '../util/';

const alwaysShow: string[] = [CommonField.FirstName, CommonField.LastName];

const personFields = alwaysShow.concat([
    Field.LanguageID,
    Field.TaxEntityName,
    Field.SendEmail,
]);

const addressFields = alwaysShow.concat([
    Field.AddressLine1,
    Field.City,
    Field.State,
    Field.ZipCode,
    Field.CountryCode,
]);

const messageFields = alwaysShow.concat([
    CommonField.Email,
    CommonField.Phone,
    Field.SendEmail,
    Field.PhoneNotes,
]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'name-is-required',
        label: 'First or Last Name is not valid',
        editFields: [CommonField.FirstName, CommonField.LastName],
        showFields: personFields,
    },
    {
        key: 'invalid-e-mail',
        label: 'Invalid e-mail format or send option',
        editFields: [CommonField.Email, Field.SendEmail],
        showFields: messageFields,
    },
    {
        key: 'invalid-phone',
        label: 'Phone number is not valid',
        editFields: [CommonField.Phone],
        showFields: messageFields,
    },
    {
        key: 'invalid-language',
        label: 'Preferred Language not valid',
        editFields: [Field.LanguageID],
        showFields: personFields,
    },
    {
        key: 'invalid-country',
        label: 'Country not valid format',
        editFields: [Field.CountryCode],
        showFields: addressFields,
    },
    {
        key: 'invalid-address',
        label: 'Address value not valid',
        editFields: [Field.AddressLine1, Field.City, Field.State],
        showFields: addressFields,
    },
    {
        key: 'invalid-zip-code',
        label: 'Invalid Zip Code',
        editFields: [Field.ZipCode],
        showFields: addressFields,
    },
    {
        key: 'invalid-employee-id',
        label: 'Invalid Employee ID',
        editFields: [CommonField.CreatedBy],
        showFields: alwaysShow.concat([CommonField.CreatedBy]),
    },
    {
        key: 'invalid-contact-id',
        label: 'Invalid Contact ID',
        editFields: [CommonField.ContactID],
        showFields: alwaysShow.concat([CommonField.ContactID]),
    },
];
