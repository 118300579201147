import { getURL, parseJSON, userHeaders } from '../api';

interface UnitApiResponse {
    unit_id: string;
    attributes: UnitAttributes;
}

interface UnitAttributes {
    currency_code: string;
}

/**
 * Verify that units exist and, if so, return their currency code
 */
export async function lookupUnitIDs(
    ids: (string | number)[]
): Promise<UnitApiResponse | null> {
    const url = getURL('/unit/validate');

    const res = await fetch(url, {
        method: 'POST',
        headers: userHeaders(),
        body: JSON.stringify({ unit_ids: ids }),
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        console.error('Failed to validate unit IDs');
        return null;
    }
}

export async function getAcquisitionList(): Promise<UnitApiResponse | null> {
    const url = getURL('/unit/acquisition');

    const res = await fetch(url, {
        method: 'GET',
        headers: userHeaders(),
        body: null,
    });

    if (res.ok) {
        return parseJSON(res, url);
    } else {
        console.error('Failed to get acquisitions list');
        return null;
    }
}
