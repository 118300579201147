import { Field } from './model';

const alwaysShow: string[] = [Field.UnitCode];

const addressFields = alwaysShow.concat([Field.Address, Field.Address2, Field.City, Field.State, Field.ZipCode]);
const bedroomsFields = alwaysShow.concat([Field.Bedrooms, Field.FullBaths, Field.HalfBaths]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-code',
        label: 'Required Unit Code',
        editFields: [Field.UnitCode],
        showFields: addressFields,
    },
    {
        key: 'invalid-bedrooms',
        label: 'Required Bedrooms',
        editFields: [Field.Bedrooms],
        showFields: bedroomsFields,
    },
    {
        key: 'invalid-full-bathrooms',
        label: 'Required Full Bathrooms',
        editFields: [Field.FullBaths],
        showFields: bedroomsFields,
    },
    {
        key: 'invalid-half-bathrooms',
        label: 'Required Half Bathrooms',
        editFields: [Field.HalfBaths],
        showFields: bedroomsFields,
    },
    {
        key: 'invalid-address',
        label: 'Invalid Address Field',
        editFields: [Field.Address],
        showFields: addressFields,
    },
    {
        key: 'invalid-address-2',
        label: 'Invalid 2nd Address Line Field',
        editFields: [Field.Address2],
        showFields: addressFields,
    },
    {
        key: 'invalid-city',
        label: 'Invalid City Field',
        editFields: [Field.City],
        showFields: addressFields,
    },
    {
        key: 'invalid-state',
        label: 'Invalid State Field',
        editFields: [Field.State],
        showFields: addressFields,
    },
    {
        key: 'invalid-zip-code',
        label: 'Invalid Zip Code Field',
        editFields: [Field.ZipCode],
        showFields: addressFields,
    },
    {
        key: 'invalid-housing-types',
        label: 'Invalid Housing Types',
        editFields: [Field.HousingType],
        showFields: alwaysShow.concat([Field.HousingType]),
    },
    {
        key: 'invalid-secured-by',
        label: 'Invalid Secured By',
        editFields: [Field.SecuredBy],
        showFields: alwaysShow.concat([Field.SecuredBy]),
    },
    {
        key: 'invalid-local-operations-manager',
        label: 'Invalid Local Operations Manager',
        editFields: [Field.LocalOperationsManager],
        showFields: alwaysShow.concat([Field.LocalOperationsManager]),
    },
    {
        key: 'invalid-vacasa-contact',
        label: 'Invalid Vacasa Contact',
        editFields: [Field.VacasaContact],
        showFields: alwaysShow.concat([Field.VacasaContact]),
    },
    {
        key: 'invalid-account-manager',
        label: 'Invalid Account Manager',
        editFields: [Field.AccountManager],
        showFields: alwaysShow.concat([Field.AccountManager]),
    },
    {
        key: 'invalid-max-occupancy',
        label: 'Invalid Max Occupancy',
        editFields: [Field.MaxOccupancyTotal],
        showFields: alwaysShow.concat([Field.MaxOccupancyTotal]),
    },
    {
        key: 'invalid-acquisition',
        label: 'Invalid Acquisition, please select one in the previous view',
        editFields: [Field.AcquisitionId],
        showFields: alwaysShow.concat([Field.AcquisitionId]),
    },
    {
        key: 'invalid-notes-for-copywriters',
        label: 'Invalid Notes for copywriters',
        editFields: [Field.NotesForCopyWriters],
        showFields: alwaysShow.concat([Field.NotesForCopyWriters]),
    },
    {
        key: 'invalid-unit-type',
        label: 'Invalid Unit Type',
        editFields: [Field.UnitType],
        showFields: alwaysShow.concat([Field.UnitType]),
    },
];
