/*
  Fields that are common across Connect endpoints
 */
import { DataType } from './data-types';

/** Field names shared among Connect endpoints */
export const CommonField = {
    Amount: 'amount',
    ContactID: 'contact_id',
    CreatedBy: 'created_by',
    SecuredBy: 'secured_by',
    Email: 'email',
    FirstName: 'first_name',
    LastName: 'last_name',
    Phone: 'phone',
    UnitID: 'unit_id',
};

/**
 * This now defaults to required for all uploads
 * @see https://vacasait.atlassian.net/browse/UJM-1329
 * @see https://vacasait.atlassian.net/browse/GROW-918
 * @see https://vacasait.atlassian.net/browse/UJM-1235
 */
export const createdByField = (required = true): FieldSpec => ({
    name: CommonField.CreatedBy,
    label: 'Created By',
    type: DataType.PositiveInteger,
    required,
});

export const firstNameField = (
    required = false,
    maxLength = 100
): FieldSpec => ({
    name: CommonField.FirstName,
    label: 'First Name',
    maxLength,
    required,
});

export const lastNameField = (
    required = false,
    maxLength = 100
): FieldSpec => ({
    name: CommonField.LastName,
    label: 'Last Name',
    maxLength,
    required,
});

export const emailField = (required = false): FieldSpec => ({
    name: CommonField.Email,
    label: 'E-mail',
    type: DataType.Email,
    maxLength: 100,
    required,
});

export const phoneField = (required = false): FieldSpec => ({
    name: CommonField.Phone,
    label: 'Phone',
    type: DataType.PhoneNumber,
    maxLength: 100,
    required,
});

export const unitIdField = (required = false): FieldSpec => ({
    name: CommonField.UnitID,
    label: 'Unit ID',
    type: DataType.PositiveInteger,
    required,
});
