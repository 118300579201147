/*

Parsers for standard data types.

All methods return a tuple indicating whether the value was valid (boolean)
and the parsed value.

*/
import { DataType } from './data-types';
import { ensureCached, unitIdLookupCache } from './lookup-cache';
import { CommonField } from './model';
import { clearRowErrors, rowHasError } from './parse-file';
import * as _ from 'lodash';

/**
 * Validations patterns used in custom methods and HTML `pattern` attributes
 */
export const Pattern: { [key: string]: RegExp } = {
    Bit: /^[0-1]$/,
    CurrencyCode: /^[a-zA-Z]{3}$/,
    /** Dates should all be `YYYY-MM-DD` */
    Date: /^(\d{4})[-/](\d{1,2})[-/](\d{1,2})$/,
    Integer: /^-?\d+$/,
    Decimals: /^-?\.?\d+(\.\d+)?$/,
    PositiveInteger: /^\d+$/,
    /**
     * Per Katy, users exporting from Excel have the date localized to U.S.
     * format so detect and transform it.
     */
    ExcelDate: /^(\d{1,2})[-/](\d{1,2})[-/](\d{2,4})$/,
    /**
     * @see https://emailregex.com/
     */
    Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    Phone: /^[0-9 .\-()]+$/,
};

/** Number of days in each month */
const monthDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * @param digits Total digits
 */
export const padZero = (n: number, digits: number): string => n.toString().padStart(digits, '0');

/**
 * Convert date text to `<year>-<month>-<day>` or `undefiend` if not parseable
 */
export function formatDate(date: string | null | undefined): string | undefined {
    if (!date) return undefined;
    // remote time portion of ISO8601 date
    if (date.includes('T')) date = date.substr(0, date.indexOf('T'));

    const parts = date.split(/[-/]/).map(p => parseInt(p, 10));
    let y = 0;
    let m = 0;
    let d = 0;

    if (Pattern.Date.test(date)) {
        // order of these pattern tests matters since first is more specific
        [y, m, d] = parts;
    } else if (Pattern.ExcelDate.test(date)) {
        [m, d, y] = parts;
        if (y < 100) y += 2000; // no need for 1900s
    }

    return y >= 2000 && m > 0 && m <= 12 && d <= monthDays[m - 1] && d > 0 ? `${padZero(y, 4)}-${padZero(m, 2)}-${padZero(d, 2)}` : undefined;
}

/**
 * @returns Whether valid and parsed value
 */
export function parseDateText(date: string | null | undefined, _spec?: FieldSpec): ParseResult<string> {
    const text = formatDate(date);
    return text ? [true, text] : [false, date ?? ''];
}

/**
 * Parse text, optionally enforcing length limits.
 *
 * Empty string is checked for separately so if there are no min/max lengths
 * defined then text is valid for purposes of this method.
 */
export const parseText = (value: string | number, spec?: FieldSpec): ParseResult<string | number> => {
    const tooLong = (max: number): boolean => (typeof value === 'number' ? value.toString().length > max : value.length > max);

    return spec && value && spec.maxLength && tooLong(spec.maxLength) ? [false, value] : [true, value];
};

/**
 * Parse e-mail according to pattern and length limits
 */
export const parseEmail = (value: string, spec?: FieldSpec): ParseResult<string> =>
    spec && value && spec.maxLength && value.length > spec.maxLength ? [false, value] : [Pattern.Email.test(value), value];

/**
 * Parse phone according to pattern and length limits
 */
export const parsePhone = (value: string, spec?: FieldSpec): ParseResult<string> =>
    spec && value && spec.maxLength && value.length > spec.maxLength ? [false, value] : [Pattern.Phone.test(value), value];

/**
 * Parse integer types that match given pattern
 * @returns Whether valid plus parsed value
 */
export const parseNumber = (value: string, pattern: RegExp): ParseResult<number> => (pattern.test(value) ? [true, parseInt(value)] : [false, NaN]);

/**
 * Parse decimal value such as percent or currency
 * @returns Whether valid plus parsed value
 */
export const parseDecimals = (value: string | number, _spec?: FieldSpec): ParseResult<number> =>
    typeof value === 'number' ? [true, value] : value && Pattern.Decimals.test(value) ? [true, parseFloat(value)] : [false, NaN];

/**
 * @returns Whether valid plus parsed value
 */
export const parsePercent = (value: string | number, spec?: FieldSpec): ParseResult<number> => {
    let [valid, n] = parseDecimals(value, spec);
    if (valid) valid = n <= 1 && n >= 0;
    return [valid, n];
};

/**
 * Parse integers (whole numbers)
 * @returns Whether valid plus parsed value
 */
export const parseInteger = (value: string, _spec?: FieldSpec): ParseResult<number> => parseNumber(value, Pattern.Integer);

/**
 * Parse integers (whole numbers)
 * @returns Whether valid plus parsed value
 */
export const parsePositiveInteger = (value: string | number, spec?: FieldSpec): ParseResult<number> =>
    typeof value === 'number'
        ? value > 0 || (spec && !spec.required && value === 0)
            ? // zero is acceptable if the value isn't required
              [true, value]
            : [false, value]
        : parseNumber(value, Pattern.PositiveInteger);

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * Parse day of week number
 */
export const parseDayOfWeek = (value: string | number, spec?: FieldSpec): ParseResult<number> => {
    let [valid, d] = parsePositiveInteger(value, spec);
    if (valid) {
        valid = d >= 1 && d <= 7;
    } else if (typeof value == 'string') {
        const i = days.indexOf(value.toLocaleLowerCase());
        if (i >= 0) {
            valid = true;
            d = i + 1;
        }
    }
    return [valid, d];
};

/**
 * Parse 1/0 value
 * @returns Whether valid plus parsed value
 */
export const parseBit = (value: string | number, _spec?: FieldSpec): ParseResult<number> =>
    typeof value === 'number' ? (value === 1 || value === 0 ? [true, value] : [false, value]) : parseNumber(value, Pattern.Bit);

const trueText = ['1', 'true'];
const falseText = ['0', 'false'];

/**
 * Parse 1/0 value
 * @returns Whether valid plus parsed value
 */
export const parseBoolean = (value: string | number | boolean | null): ParseResult<boolean> => {
    if (typeof value === 'boolean') return [true, value];

    if (typeof value === 'number') {
        return value === 1 ? [true, true] : value === 0 ? [true, false] : [false, false];
    }
    if (typeof value === 'string') {
        const lower = value.toLocaleLowerCase();
        return trueText.includes(lower) ? [true, true] : falseText.includes(lower) ? [true, false] : [false, false];
    }
    return [false, false];
};

const threePattern = /^(-1|0|1)$/;

/**
 * Parse three choice thingies (-1, 0, 1)
 * @returns Whether valid plus parsed value
 */
export const parseThreeChoice = (value: string | number): ParseResult<number> =>
    typeof value === 'number' ? (value <= 1 && value >= -1 ? [true, value] : [false, value]) : parseNumber(value, threePattern);

/**
 * Parse currency code
 * @returns Whether valid plus parsed value
 */
export const parseCurrencyCode = (value: string): ParseResult<string> => {
    const valid = Pattern.CurrencyCode.test(value);
    return [valid, valid ? value.toLocaleUpperCase() : value];
};

/**
 * Standard parsing methods keyed to data types.
 *
 * For CSVs with custom, one-off fields, directly assign a `FieldSpec.parse`
 * method rather than extending the standard parsers.
 */
export const parse: { [key: number]: Parser<any, any> } = {
    [DataType.Bit]: parseBit,
    [DataType.Text]: parseText,
    [DataType.Email]: parseEmail,
    [DataType.Float]: parseDecimals,
    [DataType.Date]: parseDateText,
    [DataType.Boolean]: parseBoolean,
    [DataType.Integer]: parseInteger,
    [DataType.Percent]: parsePercent,
    [DataType.PhoneNumber]: parsePhone,
    [DataType.PositiveInteger]: parsePositiveInteger,
    [DataType.DayOfWeek]: parseDayOfWeek,
};

/**
 * Parse single row field per model specification
 */
export function parseField(row: ParsedRow, spec: FieldSpec) {
    let value = row[spec.name];

    if (typeof value == 'string') {
        value = value.trim();
    }

    let validField = true;

    if (spec.required && (value === null || value === undefined || value.toString().trim() === '')) {
        // comparisons must be explicit so that number 0 is allowed
        row._required.add(spec.name);
        validField = false;
    } else if (typeof value === 'boolean' || value || value === 0) {
        /** Parse function */
        let parser: Parser<string, any> | null = null;

        if (spec.parse) {
            // custom parser has precedence over standard type parser
            parser = spec.parse;
        } else {
            // lookup standard parser -- default is text if none defined
            parser = parse[spec.type ? spec.type : DataType.Text];
        }

        if (parser) {
            // execute parser
            const [valid, parsed] = parser(value, spec);

            if (valid) {
                // only assign parsed value if field was valid
                row[spec.name] = parsed;
            } else {
                // record error and leave bad value in field
                row._invalid.add(spec.name);
                validField = false;
            }
        }

        if (spec.maxLength) {
            if (value.length > spec.maxLength) {
                row._invalid.add(spec.name);
            }
        }
    } else {
        // not required and value is undefined / null / NaN / ''
        row[spec.name] = spec.default !== undefined ? spec.default : null;
    }
    return validField;
}

/**
 * Validate row of already-parsed values per model specification. The row
 * error fields will be cleared and repopulated (by reference).
 * @param rowValidator Optional callback for additional validations
 */
export async function updateRowErrors(row: ParsedRow, specs: FieldSpec[], rowValidator?: (row: ParsedRow, fields: FieldSpec[]) => Promise<void>) {
    const fields = specs.map(s => s.name);
    clearRowErrors(row, fields);
    specs.forEach(s => parseField(row, s));

    if (rowValidator) await rowValidator(row, specs);
}

export async function updateFileErrors(file: ParsedFile, config: ParseConfig, additionalData?: any) {
    file.rows.forEach(r => {
        // updateRowErrors isn't called because it's written to clear specific
        // field errors while this method clears all row errors
        clearRowErrors(r);
        config.model.forEach(s => parseField(r, s));
        if (config.onRowParse) config.onRowParse(r, additionalData);
    });

    if (config.onComplete) await config.onComplete(file);
}

export async function verifyUnitIDs(...rows: ParsedRow[]) {
    /** Distinct unit IDs */
    const allUnitIDs = rows.reduce((all: number[], r) => {
        const unitID = parseInt(r[CommonField.UnitID]);
        if (unitID && !isNaN(unitID) && !all.includes(unitID)) all.push(unitID);
        return all;
    }, []);

    await ensureCached(allUnitIDs);

    rows.forEach(r => {
        // skip check if field already marked in error
        if (rowHasError(r, [CommonField.UnitID])) return;

        const unitID = parseInt(r[CommonField.UnitID]);
        const status = unitIdLookupCache.get(unitID);

        if (!status && !isNaN(unitID)) {
            throw Error(`Status of unit ID ${unitID} is unknown`);
        }
        if (isNaN(unitID) || (status && !status.valid)) {
            r._invalid.add(CommonField.UnitID);
        }
    });
}

/**
 * Parse Number > 0
 */
const greaterThanZeroPattern = /^[1-9][0-9]*$/;
export const greaterThanZero = (value: string) => parseNumber(value, greaterThanZeroPattern);

/**
 * Put a "'" at the begining of the number
 * @param str
 * @returns
 */
export const formatStringNumber = (str: string): string => {
    if (!_.isNull(str)) {
        str = str.charAt(0) !== "'" ? "'" + str : str;
    }

    return str;
};


export const onlyEvenDoubleQuotationMarks = (value: string): ParseResult<string> => {

    const isOddNumber = (numberValue: number) => {
        return numberValue % 2 !== 0;
    };

    const countStringOccurrences = (stringValue: string, stringOccurrence: string) => {
        if (_.isNil(stringValue) || _.isEmpty(stringValue)) {
            return 0;
        }

        return stringValue.split(stringOccurrence).length - 1;
    }

    const valid = !isOddNumber(countStringOccurrences(value,`"`));
    return [valid, valid ? value : ""];
};
