import { Field, RowError } from './model';
import { CommonField } from '../util';

const alwaysShow: string[] = [CommonField.UnitID];
const dateFields: string[] = [
    Field.PropertyTaxIdEffectiveDate.name,
    Field.PropertyTaxIdRenewalDate.name,
];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit Id is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'property-tax-id-is-required',
        label: 'Property tax id required is not valid',
        editFields: [Field.PropertyTaxId.name],
        showFields: [...alwaysShow, Field.PropertyTaxId.name],
    },
    {
        key: 'display-public-tax-id-is-required',
        label: 'Property tax id required is not valid',
        editFields: [Field.DisplayPublicTaxId.name],
        showFields: [...alwaysShow, Field.DisplayPublicTaxId.name],
    },
    {
        key: 'property-tax-id-type-is-required',
        label: 'Property tax id type must be between 1 and 4 (inclusive)',
        triggeredBy: [RowError.InvalidPropertyTaxType],
        editFields: [Field.PropertyTaxIdType.name],
        showFields: [...alwaysShow, Field.PropertyTaxIdType.name],
    },
    {
        key: 'invalid-property-tax-id',
        label:
            'Property tax id must contain Letters, numbers and only special characters among these: - : # / . , )(',
        triggeredBy: [RowError.InvalidPropertyTaxId],
        editFields: [Field.PropertyTaxId.name],
        showFields: [...alwaysShow, Field.PropertyTaxId.name],
    },
    {
        key: 'invalid-effective-date',
        label: 'Renewal date cannot be lower or equal to effective date',
        triggeredBy: [RowError.InvalidEffectiveDate],
        editFields: [...dateFields],
        showFields: [...alwaysShow.concat(dateFields)],
    },
    {
        key: 'invalid-description',
        label: 'Description is not valid',
        triggeredBy: [RowError.PropertyTaxIdDescriptionRequired],
        editFields: [Field.PropertyTaxIdDescription.name],
        showFields: [...alwaysShow, Field.PropertyTaxIdDescription.name],
    },
    {
        key: 'max-property-taxes-quantity-per-unit',
        label:
            'You exceeded the maximum permitted property taxes for a unit (3) fix the file and try again.',
        triggeredBy: [RowError.MaxPropertyTaxIdQuantityPerUnit],
        editFields: [],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-dates',
        label: 'Invalid dates',
        editFields: [
            Field.PropertyTaxIdRenewalDate.name,
            Field.PropertyTaxIdEffectiveDate.name,
        ],
        showFields: [
            ...alwaysShow.concat([
                Field.PropertyTaxIdRenewalDate.name,
                Field.PropertyTaxIdEffectiveDate.name,
            ]),
        ],
    },
    {
        key: 'invalid-description',
        label: 'Description can not be empty if type is 1 (Other)',
        triggeredBy: [RowError.PropertyTaxIdDescriptionRequired],
        editFields: [
            Field.PropertyTaxIdDescription.name,
            Field.PropertyTaxIdType.name,
        ],
        showFields: [
            ...alwaysShow,
            Field.PropertyTaxIdDescription.name,
            Field.PropertyTaxIdType.name,
        ],
    },
];
