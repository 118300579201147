import React, { FunctionComponent } from 'react';
import { Icon } from './icon';
import { slugify } from '../utils';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

interface ChildBulkProps {
    childBulks: Array<string>;
    ViewIcon: Record<string, string>;
    childIndex: number;
}

export const ChildBulk: FunctionComponent<ChildBulkProps> = props => {
    return (
        <div className="child-bulk">
            <ul>
                {_.map(props.childBulks, childBulk => {
                    return (
                        <li key={props.childIndex}>
                            <Link
                                key={childBulk + props.childIndex}
                                to={`/${slugify(childBulk)}`}
                            >
                                <Icon name={props.ViewIcon[childBulk]} />
                                {childBulk}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
