/** Standard view titles */
export const View = {
    Dashboard: 'Dashboard',
    Reservations: 'Reservations',
    Finance: 'Finances',
    Units: 'Units Creation',
    UnitsUpdate: 'Units Update',
    Contracts: 'Contracts',
    Contacts: 'Contacts',
    Users: 'Users',
    Amenities: 'Amenities',
    Programs: 'Programs',
    RestDays: 'Rest Days',
    Permits: 'Permits',
    UnitBlocks: 'Unit Blocks',
    BedToRoom: 'Bed to Room',
    PropertyTaxId: 'Property Tax',
    ReportingLocation: 'Unit Rep Location',
    AccountingEntity: 'Accounting Entity',
    DamageInsurance: 'Damage Insurance',
    ReservationRules: 'Unit Rules',
    PermitsRules: 'Permits rules',
    UnitStayRules: 'Unit Stay rules',
    SeasonalRateRules: 'Seasonal Rates rules',
    LocationAndAccess: 'Location & Access',
    RatesInfoUpdate: 'Rates-Housekeeping Fees',
    Reserve: 'Owners Reserve'
};

/**
 * Material icon for upload type
 * @see https://material.io/resources/icons/
 */
export const ViewIcon: Record<string, string> = {
    [View.Dashboard]: 'assesment',
    [View.Reservations]: 'insert_invitation',
    [View.Finance]: 'account_balance',
    [View.Units]: 'house',
    [View.Contracts]: 'receipt',
    [View.Contacts]: 'contact_mail',
    [View.Users]: 'account_circle',
    [View.Amenities]: 'beach_access',
    [View.Programs]: 'cleaning_services',
    [View.RestDays]: 'snooze',
    [View.Permits]: 'assignment',
    [View.UnitBlocks]: 'cloud_upload',
    [View.BedToRoom]: 'king_bed',
    [View.PropertyTaxId]: 'list_alt',
    [View.ReportingLocation]: 'location_on',
    [View.AccountingEntity]: 'attach_money',
    [View.DamageInsurance]: 'foundation',
    [View.ReservationRules]: 'receipt_long',
    [View.UnitsUpdate]: 'update',
    [View.PermitsRules]: 'assignment',
    [View.UnitStayRules]: 'assignment',
    [View.SeasonalRateRules]: 'assignment',
    [View.LocationAndAccess]: 'location_on',
    [View.RatesInfoUpdate]: 'trending_up',
    [View.Reserve]: 'savings',
};

// because this has changed and is used in a few places
export const pairDelimiter = ';';

/**
 * Role IDs that must match enumeration in `api/shared/roles.py`. Actual role
 * enforcement must occur in the API. In JS they can't be considered more than
 * UX sugar.
 */
export const Role = {
    /** Role has not yet been retrieved */
    Pending: -1,
    None: 0,
    SuperUser: 3,
    Finance: 4,
    Reservations: 5,
    Units: 6,
    Contracts: 7,
    Contacts: 8,
    Amenities: 9,
    Programs: 10,
    DamageInsurance: 11,
    RestDays: 12,
    Permits: 13,
    UnitBlocks: 14,
    BedToRoom: 15,
    PropertyTax: 16,
    UnitRepLocation: 17,
    AccountingEntity: 18,
    CherreContactData: 19,
    ReservationRules: 20,
    UnitCreate: 21,
    UnitUpdate: 22,
    LocationAndAccess: 23,
    RatesAndHKFees: 24,
    UserAdmin: 25,
    UnitStayRestrictionsRule: 26,
    OwnerReserve: 27,
    SeasonalRatesRule: 28
};

// prettier-ignore
export const months = [
    'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
];

/**
 * Allowed languages. Eventually this should be an API lookup.
 *
 * There doesn't seem to be an ISO or IETF standard that matches these codes
 * so they may just be Vacasa database IDs.
 */
export const LanguageCode = {
    Czech: 36,
    English: 40,
    French: 47,
    Hungarian: 60,
    Italian: 67,
    PortugueseEuropean: 176,
    Russian: 119,
    SpanishEuropean: 170,
    SpanishLatinAmerican: 173,
};

export const uploadTypesWithSuccess = ['unit_create'];
