import './App.scss';
import logoSVG from './logo.svg';
import React, { useEffect, useState, useContext, FunctionComponent, ContextType } from 'react';
import { Icon } from './components';
import { Role, View, ViewIcon } from './constants';
import { slugify } from './utils';
import { Context } from './context';
import { FinanceView } from './finances/view';
import { ReservationView } from './reservations/view';
import { ProgramView } from './programs/view';
import { UnitsView } from './units/view';
import { UnitsUpdateView } from './units-update/view';
import { AmenitiesView } from './amenities/view';
import { ContractsView } from './contracts/view';
import { ContactsView } from './contacts/view';
import { RestDaysView } from './rest-days/view';
import { PermitsView } from './permits/view';
import { UsersView } from './users/view';
import { getUserRole } from './users/api';
import { currentUser, goToLogout, isLoggedIn } from './idp-auth';
import { RequestAccessButton } from './users/access_request';
import { Route, Switch, Link } from 'react-router-dom';
import { UnitBlocksView } from './unit-blocks/view';
import { BedToRoomView } from './bed-to-room/view';
import { PropertyTaxView } from './property-taxes/view';
import { ReportingLocationView } from './reporting-location/view';
import { AccountingEntityView } from './accounting-entities/view';
import { DamageInsuranceView } from './damage-insurance/view';
import { ReservationRulesView } from './reservation-rules/view';
import { ReservationRulesPermitsView } from './reservation-rules-permits/view';
import * as _ from 'lodash';
import { ChildBulk } from './components/child-bulk';
import { LocationAndAccessView } from './location-and-access/view';
import { RatesInfoUpdateView } from './rate-info/view';
import { ReservationRulesUnitStayView } from './reservation-rules-unit-stay/view';
import { ReserveView } from './reserve/view';
import { RulesSeasonalRatesView } from './reservation-rules-seasonal-rates/view';

interface ViewInterface {
    view: FunctionComponent;
    childBulks: Array<string>;
    requiredRole: number | Array<number>;
    isHidden?: boolean;
}

/** All available views */
const views: { [key: string]: ViewInterface } = {
    [View.Finance]: {
        view: FinanceView,
        childBulks: [],
        requiredRole: Role.Finance,
    },
    [View.Reservations]: {
        view: ReservationView,
        childBulks: [],
        requiredRole: Role.Reservations,
    },
    [View.Contracts]: {
        view: ContractsView,
        childBulks: [],
        requiredRole: Role.Contracts,
    },
    [View.Contacts]: {
        view: ContactsView,
        childBulks: [],
        requiredRole: Role.Contacts,
    },
    [View.Users]: {
        view: UsersView,
        childBulks: [],
        requiredRole: [Role.SuperUser, Role.UserAdmin],
    },
    [View.Amenities]: {
        view: AmenitiesView,
        childBulks: [],
        requiredRole: Role.Amenities,
    },
    [View.Programs]: {
        view: ProgramView,
        childBulks: [View.DamageInsurance],
        requiredRole: Role.Programs,
    },
    [View.RestDays]: {
        view: RestDaysView,
        childBulks: [],
        requiredRole: Role.RestDays,
    },
    [View.Permits]: {
        view: PermitsView,
        childBulks: [],
        requiredRole: Role.Permits,
    },
    [View.UnitBlocks]: {
        view: UnitBlocksView,
        childBulks: [],
        requiredRole: Role.UnitBlocks,
    },
    [View.BedToRoom]: {
        view: BedToRoomView,
        childBulks: [],
        requiredRole: Role.BedToRoom,
    },
    [View.PropertyTaxId]: {
        view: PropertyTaxView,
        childBulks: [],
        requiredRole: Role.PropertyTax,
    },
    [View.ReportingLocation]: {
        view: ReportingLocationView,
        childBulks: [],
        requiredRole: Role.UnitRepLocation,
    },
    [View.AccountingEntity]: {
        view: AccountingEntityView,
        childBulks: [],
        requiredRole: Role.AccountingEntity,
    },
    [View.DamageInsurance]: {
        view: DamageInsuranceView,
        childBulks: [],
        isHidden: true,
        requiredRole: Role.DamageInsurance,
    },
    [View.ReservationRules]: {
        view: ReservationRulesView,
        childBulks: [View.PermitsRules, View.UnitStayRules, View.SeasonalRateRules],
        requiredRole: [Role.ReservationRules, Role.UnitStayRestrictionsRule,Role.SeasonalRatesRule],
    },
    [View.PermitsRules]: {
        view: ReservationRulesPermitsView,
        childBulks: [],
        isHidden: true,
        requiredRole: Role.ReservationRules,
    },
    [View.UnitStayRules]: {
        view: ReservationRulesUnitStayView,
        childBulks: [],
        isHidden: true,
        requiredRole: Role.UnitStayRestrictionsRule,
    },
    [View.SeasonalRateRules]: {
        view: RulesSeasonalRatesView,
        childBulks: [],
        isHidden: true,
        requiredRole: Role.SeasonalRatesRule,
    },
    [View.Units]: {
        view: UnitsView,
        childBulks: [],
        requiredRole: Role.UnitCreate,
    },
    [View.UnitsUpdate]: {
        view: UnitsUpdateView,
        childBulks: [],
        requiredRole: Role.UnitUpdate,
    },
    [View.LocationAndAccess]: {
        view: LocationAndAccessView,
        childBulks: [],
        requiredRole: Role.LocationAndAccess,
    },
    [View.RatesInfoUpdate]: {
        view: RatesInfoUpdateView,
        childBulks: [],
        requiredRole: Role.RatesAndHKFees,
    },
    [View.Reserve]: {
        view: ReserveView,
        childBulks: [],
        requiredRole: [Role.OwnerReserve],
    },
};

/**
 * Menu shown at root URL
 */
const ViewMenu: FunctionComponent = () => {
    const [activeMenu, setActiveMenu] = useState({ id: 0, active: false });
    const toggleBulkMenu = (index: number) => {
        setActiveMenu({
            id: index,
            active: index === activeMenu.id ? !activeMenu.active : true,
        });
    };

    /**
     * Checks roles, in nested views by default, but can be set to single check also.
     * @param view
     * @param roles
     * @param nested
     */
    const checkRoles = (view: ViewInterface, roles: number[], nested: boolean = true): boolean => {
        let result = false;
        if (typeof view.requiredRole == 'number') {
            result = roles.includes(view.requiredRole);
        } else {
            for (let r of view.requiredRole) {
                if (roles.includes(r)) {
                    result = true;
                    break;
                }
            }
        }

        if (!result && nested) {
            if (view.childBulks.length > 0) {
                view.childBulks.every(c => {
                    result = checkRoles(views[c], roles);
                    if (result) return result;
                });
            }
        }
        return result;
    };

    const ctx = useContext(Context);
    return (
        <nav className="view-menu">
            {Object.keys(views).map((v, index) => {
                let viewIcon = ViewIcon[v];
                let childBulks = views[v]['childBulks'];
                const bulkMenuVisible = activeMenu.active && activeMenu.id === index;
                const menuIcon = bulkMenuVisible ? 'arrow_drop_up' : 'arrow_drop_down';
                return views[v].isHidden ? (
                    <React.Fragment key={'hidden' + index} />
                ) : ctx.role.includes(Role.SuperUser) || checkRoles(views[v], ctx.role) ? (
                    <div className="father-bulk" key={'div' + index}>
                        <Link key={v + index} to={`/${slugify(v)}`}>
                            <Icon name={viewIcon} />
                            {v}
                        </Link>
                        {childBulks.length > 0 && (
                            <Icon key={index} onClick={() => toggleBulkMenu(index)} name={menuIcon} className={'child-bulk-i'} />
                        )}
                        {childBulks.length > 0 && bulkMenuVisible && (
                            <ChildBulk childIndex={index} ViewIcon={ViewIcon} childBulks={childBulks}></ChildBulk>
                        )}
                    </div>
                ) : (
                    <React.Fragment key={'forbidden' + index} />
                );
            })}
        </nav>
    );
};
const Header: FunctionComponent<{ activeView?: string }> = ({ activeView }) => {
    const [userMenuVisible, setUserMenuVisible] = useState(false);
    const menuIcon = userMenuVisible ? 'arrow_drop_up' : 'arrow_drop_down';
    const toggleUserMenu = () => setUserMenuVisible(!userMenuVisible);
    const match = /\.vacasa(\w+)\./.exec(window.location.toString());
    /** Current environment (local, dev, stage, prod) */
    const env = match && match.length > 1 ? match[1] : 'unknown';
    const title = activeView ?? 'Import Tool';

    return (
        <header className={`env-${env}`}>
            <div>
                <Link className="home" to="/">
                    <img className="logo" src={logoSVG} alt="Vacasa Logo" />
                </Link>
                <Link className="home" to="/">
                    <h1>{title}</h1>
                </Link>

                {isLoggedIn() && (
                    <nav>
                        <p onClick={toggleUserMenu}>Welcome back, {currentUser().firstName}</p>
                        <Icon onClick={toggleUserMenu} name={menuIcon} />
                        {userMenuVisible && (
                            <ul>
                                <RequestAccessButton />
                                <li onClick={() => goToLogout()}>
                                    <Icon name="exit_to_app" />
                                    Log out
                                </li>
                            </ul>
                        )}
                    </nav>
                )}
            </div>
        </header>
    );
};

const AcquisitionApp: FunctionComponent<{ debug?: boolean }> = ({ debug }) => {
    const [role, setRole] = useState([Role.Pending]);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
        getUserRoles();
    }, []);

    const getUserRoles = async () => {
        const userObject: IdpUser = await getUserRole();
        setRole(userObject.roles);
        setUserId(userObject.user_id);
    };

    const context: ContextType<typeof Context> = {
        debug: debug ?? false,
        role: role,
        userId: userId,
    };

    return (
        <Context.Provider value={context}>
            <Switch>
                {Object.keys(views).map(v => {
                    const UploadView = _.get(views[v], 'view');
                    return (
                        <Route key={v} path={`/${slugify(v)}`}>
                            <Header activeView={v} />
                            <main>
                                <UploadView />
                            </main>
                        </Route>
                    );
                })}
                <Route key="/" exact path="/">
                    <Header />
                    <main>
                        <ViewMenu />
                        <div><p className="center">Please do not open import templates in Apple Numbers, this results in extra spaces in the content.</p></div>
                    </main>
                </Route>
            </Switch>
        </Context.Provider>
    );
};

export default AcquisitionApp;
