import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { Role } from '../constants';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';

export const ReservationRulesUnitStayView: FunctionComponent = () => {
    return (
    <UploadView
        id="reservation-rules-upload-view"
        requiredRole={Role.UnitStayRestrictionsRule}
        apiPath="unit-stay-rules"
        label="Reservation Rules"
        altTemplate="reservation-rules-unit-stay"
        uploadType="reservation_rules"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={false}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="ReservationRulesUnitStay"
        entityCSVModel={ReservationRulesUnitStayCSVModel}
    />
    )
};

const ReservationRulesUnitStayCSVModel: Array<string> = [
    'id',
    'unit_rule_value_attributes.restriction_type',
    'unit_rule_value_attributes.restriction_number',
    'unit_rule_value_attributes.restriction_duration_type',
    'unit_rule_value_attributes.restriction_duration',
    'unit_rule_value_attributes.restriction_effective_type',
    'unit_rule_value_attributes.restriction_effective_start_date',
    'unit_rule_value_attributes.restriction_effective_end_date',
    'notes',
    'start_date',
    'end_date',
];