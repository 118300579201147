import React, { useState, useContext, FunctionComponent } from 'react';
import { Role } from '../constants';
import { requestAccess } from './api';
import { Icon } from '../components';
import { currentUser } from '../idp-auth';
import { Context } from '../context';

/** Navbar item which a user to request access to this application */
export const RequestAccessButton: FunctionComponent = () => {
    const [accessRequested, setAccessRequested] = useState(false);
    const ctx = useContext(Context);

    const onClickRequestAccess = async () => {
        const res = await requestAccess();
        if (res && res.idp_id === currentUser().id) setAccessRequested(true);
    };

    if (!ctx.role.includes(Role.None)) return null; // no need to request access if you already have it
    if (accessRequested) return <li>Access Requested!</li>; // or if you already put in the request

    return (
        <li onClick={() => onClickRequestAccess()}>
            <Icon name="lock_open" />
            Request access
        </li>
    );
};
