import { CommonField } from '../util';
import { Field } from './model';

const alwaysShow: string[] = [
    CommonField.UnitID,
    Field.RestrictionType.name,
    Field.RestrictionNumber.name,
    Field.RestrictionDurationType.name,
    Field.Notes.name
];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit ID is not valid.',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-restriction-type',
        label: 'Restriction Type is invalid.',
        editFields: [Field.RestrictionType.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'restriction-number-required',
        label: 'Restriction Number is required.',
        editFields: [Field.RestrictionNumber.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'invalid-restriction-duration-type',
        label: 'Restriction Duration Type is invalid.',
        editFields: [Field.RestrictionDurationType.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'restriction-duration-required',
        label: 'Restriction Duration is required if Duration type is days.',
        editFields: [Field.RestrictionDuration.name],
        showFields: [...alwaysShow, Field.RestrictionEffectiveType.name, Field.RestrictionEffectiveStartDate.name, Field.RestrictionEffectiveEndDate.name],
    },
    {
        key: 'invalid-restriction-effective-type',
        label: 'Restriction Effective Type is invalid.',
        editFields: [Field.RestrictionEffectiveType.name],
        showFields: [...alwaysShow, Field.RestrictionEffectiveType.name],
    },
    {
        key: 'restriction-effective-start-date-required',
        label: 'Restriction Effective Start Date is required if Effective type is custom or is invalid.',
        editFields: [Field.RestrictionEffectiveStartDate.name],
        showFields: [...alwaysShow, Field.RestrictionEffectiveType.name, Field.RestrictionEffectiveStartDate.name, Field.RestrictionEffectiveEndDate.name],
    },
    {
        key: 'invalid-restriction-effective-end-date',
        label: 'Restriction Effective End Date is invalid.',
        editFields: [Field.RestrictionEffectiveEndDate.name],
        showFields: [...alwaysShow, Field.RestrictionEffectiveType.name, Field.RestrictionEffectiveStartDate.name, Field.RestrictionEffectiveEndDate.name],
    },
    {
        key: 'invalid-start-date',
        label: 'Start Date is invalid.',
        editFields: [Field.StartDate.name],
        showFields: [...alwaysShow, Field.StartDate.name, Field.EndDate.name],
    },
    {
        key: 'start-date-required',
        label: 'Start Date is required.',
        editFields: [Field.StartDate.name],
        showFields: [...alwaysShow, Field.StartDate.name, Field.EndDate.name],
    },
    {
        key: 'invalid-end-date',
        label: 'End Date is invalid.',
        editFields: [Field.EndDate.name],
        showFields: [...alwaysShow, Field.StartDate.name, Field.EndDate.name],
    },
    {
        key: 'notes-required',
        label: 'Notes is required.',
        editFields: [Field.Notes.name],
        showFields: [...alwaysShow],
    },
]