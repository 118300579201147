import { Field } from './model';
import { CommonField } from '../util';

const alwaysShow: string[] = [
    CommonField.UnitID,
    Field.RecordId.name,
    Field.FinanceReportingLocationCode.name,
    Field.StartDate.name,
    Field.EndDate.name,
];
const dateFields: string[] = [Field.StartDate.name, Field.EndDate.name];

export const fixSpecs: FixSpec[] = [
    {
        key: 'unit-id-is-required',
        label: 'Unit ID is not valid',
        editFields: [CommonField.UnitID],
        showFields: alwaysShow,
    },
    {
        key: 'reporting-location-code-is-required',
        label: 'Reporting Location Code is required',
        editFields: [Field.FinanceReportingLocationCode.name],
        showFields: [...alwaysShow],
    },
    {
        key: 'start-date-is-required',
        label: 'Start date is required',
        editFields: [...dateFields],
        showFields: [...alwaysShow],
    },
];
