import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { Role } from '../constants';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';

export const RulesSeasonalRatesView: FunctionComponent = () => {
    return (
    <UploadView
        id="reservation-rules-upload-view"
        requiredRole={Role.SeasonalRatesRule}
        apiPath="seasonal-rates-rules"
        label="Reservation Rules"
        altTemplate="reservation-rules-seasonal-rates"
        uploadType="reservation_rules"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={false}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="ReservationRulesSeasonalRates"
        entityCSVModel={ReservationRulesSeasonalRatesCSVModel}
    />
    )
};

const ReservationRulesSeasonalRatesCSVModel: Array<string> = [
    'id',
    'unit_rule_value_attributes.rate_type',
    'unit_rule_value_attributes.reason_why',
    'unit_rule_value_attributes.weekday_rate',
    'unit_rule_value_attributes.weekend_rate',
    'unit_rule_value_attributes.rate_effective_start_date',
    'unit_rule_value_attributes.rate_effective_end_date',
    'notes',
    'start_date',
    'end_date',
];