import { Field, RowError } from './model';
import moment from 'moment';
import * as _ from 'lodash';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const REASONS_WHY = [ "Vacasa Seasonal", "Owner Seasonal", "HOA Seasonal"];
export const RATE_TYPES =["min_rate"];

const isValidDateFormat = (dateVal: string) => moment(dateVal).format(DATE_FORMAT) === dateVal;
let isValidRestrictionDateFormat = new RegExp(/(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);

export function validateEndDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const endDateVal: string = r[Field.EndDate.name];
        if (!_.isNil(endDateVal) && !isValidDateFormat(endDateVal)) {
            r._invalid.add(RowError.InvalidEndDate);
        }
    });
}

export function validateStartDate(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const startDate: string = r[Field.StartDate.name];
        if (_.isNil(startDate)) {
            r._invalid.add(RowError.StartDateRequired);
        }
        if (!_.isNil(startDate) && !isValidDateFormat(startDate)) {
            r._invalid.add(RowError.InvalidStartDate);
        }
    });
}

function generateDate(date: string): string | null {
    const currentYear = moment().year();
    if (date) {
        const splitDate = date.split("-");
        return `${currentYear}-${splitDate[0]}-${splitDate[1]}`;
    }
    return null;
}

export function validateRateDates(...rows: ParsedRow[]) {
    rows.forEach(r => {
        const rateStartDate: string = r[Field.RateEffectiveStartDate.name];
        const startDate = generateDate(rateStartDate);
        if (!moment(startDate,"YYYY-MM-DD", true).isValid()) {
            console.log(`startDate ${startDate} is not valid`);
            r._invalid.add(RowError.InvalidRateEffectiveStartDate);
            return;
        }

        const rateEndDate: string = r[Field.RateEffectiveEndDate.name];
        if (!_.isNil(rateEndDate)) {
            const endDate = generateDate(rateEndDate);
            if (!moment(endDate,"YYYY-MM-DD", true).isValid()) {
                console.log(`endDate ${endDate} is not valid`);
                r._invalid.add(RowError.InvalidRateEffectiveEndDate);
                return;
            }

            if (startDate && endDate && endDate < startDate) {
                console.log(`invalid range ${startDate} ${endDate}`);
                r._invalid.add(RowError.InvalidRateEffectiveEndDate);
            }
        }
    });
}

export const validateRateTypes = (value: string): ParseResult<any> => {
    const isValid = RATE_TYPES.includes(value);
    return [isValid, isValid ? value : 0];
}

export const validateReasonsWhy = (value: string): ParseResult<any> => {
    const isValid = REASONS_WHY.includes(value);
    return [isValid, isValid ? value : 0];
}

