import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ReservationView: FunctionComponent = () => (
    <UploadView
        id="reservation-upload-view"
        requiredRole={Role.Reservations}
        label="Reservation"
        apiPath="reservations"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        uploadType="reservation"
    />
);
