import {
    DataType,
    parseDecimals,
    parseThreeChoice,
    parseCurrencyCode,
    monetize,
    parseReservationDate,
    firstNameField,
    lastNameField,
    emailField,
    phoneField,
    unitIdField,
    CommonField,
    padZero,
    round,
    createdByField, parseDate
} from '../util/';
import {
    verifyDateRanges,
    parseType,
    verityUnitsAndCurrency,
    parseSubtypeOwnerHold,
    parseSubtypeVacasaHold
} from './parsers';
import { emptyRow } from '../util/parse-file';
import _ from "lodash";

/**
 * Unique reservation field names required by the Connect API
 */
export const Field = {
    ListingChannelReservationID: 'listing_channel_reservation_id',
    SourceID: 'source',
    Cluster: 'cluster',
    Type: 'type',
    HoldType: 'subtype',
    InternalNotes: 'internal_notes',
    ExternalNotes: 'external_notes',
    /** Arrival or first night date */
    Arrival: 'arrival',
    /** Departure date */
    Departure: 'departure',
    Adults: 'adults',
    Children: 'children',
    Pets: 'pets',
    Rent: 'rent',
    Fees: 'fees',
    Taxes: 'taxes',
    Paid: 'paid',
    Total: 'total',
    CurrencyUsed: 'booked_currency_code',
    Notes: 'notes',
    Phone2: 'phone2',
    AutoPay: 'autopay',
    CleanAfterStay: 'clean_after_stay',
    /** Whether to send confirmation e-mail */
    ConfirmationEmail: 'confirmation_email',
    BookingDate: 'source_booking_date'
};

enum TYPE_FIELD {
    reservation = 1,
    owner_hold = 2,
    vacasa_hold = 3
}

const OWNER_STAY = {value: 1, name: "owner stay"}
const COMPLEMENTARY_STAY = {value: 2, name: "complimentary stay"}
const PROPERTY_CARE = {value: 3, name: "property care"}
const SEASONAL_HOLD = {value: 4, name: "seasonal hold"}
const TERMINATION_HOLD = {value: 5, name: "termination hold"}
const HOME_FOR_SALE = {value: 6, name: "home for sale"};
const OTHER_OWNER_HOLD = {value: 7, name: "other"};

const OUT_OF_ORDER = {value: 1, name: "out of order"};
const MAINTENANCE = {value: 2, name: "maintenance"};
const PHOTOGRAPHY = {value: 3, name: "photography"};
const HOUSEKEEPING = {value: 4, name: "housekeeping"};
const OTHER_VACASA_HOLD = {value: 5, name: "other"};

const VACASA_HOLD_SUBTYPE_REQUIRED_INTERNAL_NOTES = [OUT_OF_ORDER, MAINTENANCE, PHOTOGRAPHY, HOUSEKEEPING, OTHER_VACASA_HOLD];
const OWNER_HOLD_SUBTYPE_REQUIRED_INTERNAL_NOTES = [OTHER_OWNER_HOLD];


/** Used for validating that the fees includes the booking fee */
const BOOKING_FEE = 6;

/**
 * If an error involves more than one field and should be handled differently
 * than individual field errors then create an error code to store in
 * `row._invalid` rather than field names.
 */
export const RowError = {
    DateConflict: 'date_conflict',
    InvalidTotal: 'invalid_total',
};

/**
 * Total value of rows having `field`
 * @param field Name of field to be summed
 */
const totalValue = (field: string) => (rows?: ParsedRow[]): number => {
    let total = 0;

    if (rows) {
        rows.forEach(r => {
            const text = r[field];
            const value = text ? parseFloat(text) : 0;
            total += isNaN(value) ? 0 : round(value, 2);
        });
    }
    return total;
};

const isRequiredInternalNotes = (type: number | string, subtype: number | string): boolean => {
    //Internal note is required when type= Owner Hold and subtype is in OWNER_HOLD_SUBTYPE_REQUIRED_INTERNAL_NOTES or type= Vacasa Hold and subtype is in VACASA_HOLD_SUBTYPE_REQUIRED_INTERNAL_NOTES
    const is_required_for_owner_hold = (type === TYPE_FIELD[2] || type === TYPE_FIELD.owner_hold) && (!!OWNER_HOLD_SUBTYPE_REQUIRED_INTERNAL_NOTES.find( s => s.value === subtype || s.name === subtype ))
    const is_required_for_vacasa_hold = (type === TYPE_FIELD[3] || type === TYPE_FIELD.vacasa_hold) && (!!VACASA_HOLD_SUBTYPE_REQUIRED_INTERNAL_NOTES.find( s => s.value === subtype || s.name === subtype ))
    return (is_required_for_owner_hold || is_required_for_vacasa_hold);
}

/**
 * Validate the fields related with the subtype 2 = Owner Hold or 3 = Vacasa Hold
 * @param row Parsed Row
 */
export const validateSubtype = (row: ParsedRow): any => {
    let validSubtype: ParseResult<any>;

    if (!row[Field.HoldType])
        row._invalid.add(Field.HoldType);
    if (!row[Field.InternalNotes] && isRequiredInternalNotes(row[Field.Type], row[Field.HoldType]))
        row._invalid.add(Field.InternalNotes);

    validSubtype = row[Field.Type] === TYPE_FIELD[2] || row[Field.Type] === TYPE_FIELD.owner_hold ? parseSubtypeOwnerHold(row[Field.HoldType]) : parseSubtypeVacasaHold(row[Field.HoldType])

    if (!validSubtype[0])
        row._invalid.add(Field.HoldType)
    return row;

};

/**
 * Validate the fields related with the type depending of the subtype field
 * @param row Parsed Row
 */
export const validateTypeFields = (row: ParsedRow ): any => {
    return (row[Field.Type] === TYPE_FIELD.reservation || row[Field.Type] === TYPE_FIELD[1]) ? row : validateSubtype(row);
};

/**
 * Validate scalar rent imported from CSV or daily rent object array stored in
 * upload queue. In either case, return scalar rent for UX.
 */
export const parseRent = (value: number | string | DayRent[]) =>
    // @ts-ignore
    parseDecimals(Array.isArray(value) ? totalValue('rent')(value) : value);

/**
 * Define fields *in the order* they appear in CSV. The definitions must match the
 * Connect API:
 * @see https://connect.vacasa.com/#operation/post-reservations-import
 */
const reservationModel: Model = [
    unitIdField(true),
    {
        name: Field.ListingChannelReservationID,
        label: 'Listing Channel Reservation ID',
        type: DataType.Text,
    },
    {
        name: Field.SourceID,
        label: 'Source ID',
    },
    {
        name: Field.Cluster,
        label: 'Cluster',
        type: DataType.Bit,
        required: true,
    },
    {
        name: Field.Type,
        label: 'Type',
        type: DataType.PositiveInteger,
        parse: parseType,
    },
    {
        name: Field.HoldType,
        label: 'Hold Type',
        type: DataType.PositiveInteger,
        parse: parseSubtypeOwnerHold,
        required: false,
    },
    {
        name: Field.InternalNotes,
        label: 'Internal Notes',
        type: DataType.MultiLineText,
        required: false,
    },
    {
        name: Field.ExternalNotes,
        label: 'External Notes',
        type: DataType.MultiLineText,
        required: false,
    },
    {
        name: Field.Arrival,
        label: 'First Night',
        apiErrorPattern: /FirstNight/,
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.Departure,
        label: 'Departure Date',
        apiErrorPattern: /LastNight/,
        type: DataType.Date,
        required: true,
    },
    {
        name: Field.Adults,
        type: DataType.PositiveInteger,
        required: true,
    },
    {
        name: Field.Children,
        type: DataType.PositiveInteger,
        default: 0,
    },
    {
        name: Field.Pets,
        type: DataType.PositiveInteger,
        default: 0,
    },
    {
        name: Field.Rent,
        label: 'Rent',
        type: DataType.Float,
        parse: parseRent,
    },
    {
        name: Field.Fees,
        label: 'Fees',
        type: DataType.Table,
        table: {
            summarize: totalValue(CommonField.Amount),
            fields: [
                {
                    name: 'id',
                    label: 'Fee ID',
                    type: DataType.Integer,
                },
                {
                    name: CommonField.Amount,
                    label: 'Amount',
                    type: DataType.Float,
                },
            ],
        },
    },
    {
        name: Field.Taxes,
        label: 'Taxes',
        type: DataType.Table,
        table: {
            summarize: totalValue(CommonField.Amount),
            fields: [
                {
                    name: 'id',
                    label: 'Tax ID',
                    type: DataType.Integer,
                },
                {
                    name: CommonField.Amount,
                    label: 'Amount',
                    type: DataType.Float,
                },
            ],
        },
    },
    {
        name: Field.Paid,
        label: 'Paid',
        type: DataType.Float,
    },
    {
        name: Field.Total,
        label: 'Total',
        type: DataType.Float,
    },
    {
        name: Field.CurrencyUsed,
        label: 'Booked Currency Code',
        apiErrorPattern: /currency code/i,
        parse: parseCurrencyCode,
    },
    {
        name: Field.Notes,
        label: 'Notes',
        type: DataType.MultiLineText,
        required: false,
    },
    firstNameField(true),
    lastNameField(true),
    emailField(false),
    phoneField(false),
    {
        name: Field.Phone2,
        label: 'Phone 2',
        type: DataType.PhoneNumber,
    },
    {
        name: Field.AutoPay,
        parse: parseThreeChoice,
        type: DataType.Integer,
        required: true,
    },
    {
        name: Field.CleanAfterStay,
        type: DataType.Bit,
        required: true,
    },
    {
        name: Field.BookingDate,
        label: 'Booking Date',
        type: DataType.Date,
        required: false
    },
    createdByField(),
];

/**
 * @param key Field key to sum
 * @param model Model containing the field to sum
 */
function subTableTotal(key: string, model: Model, row: ParsedRow): number {
    const spec = model.find(f => f.name === key);
    /// @ts-ignore: these summaries always return numbers
    return spec && spec.table ? spec.table.summarize(row[key]) : 0;
}

/**
 * @param key Field key to sum
 */
function verifyZeroFeesAndBookingFee(
    key: string,
    model: Model,
    row: ParsedRow,
    keepZeros?: boolean
) {
    const fees: Monies[] = row[key];
    let hasBookingFee = false;
    if (fees) {
        fees.forEach(r => {
            const id = r.id;
            const text = r.amount;
            const value = text ? parseFloat(text.toString()) : 0;

            if (id === BOOKING_FEE) hasBookingFee = true;

            r.amount = value === 0 ? (keepZeros ? 0 : null) : value;
        });
    } else {
        row[key] = [];
    }
    // Per discussion with Finance, all reservations should have a booking fee
    if (!hasBookingFee) {
        row[key].push({
            ...emptyRow(row[key].length),
            id: BOOKING_FEE,
            [CommonField.Amount]: 0.0,
        });
    }
}

function formatOwnerHoldSubtype(subtype: number | string): { value: number, name: string } {
    switch (subtype) {
        case OWNER_STAY.value:
        case OWNER_STAY.name: {
            return OWNER_STAY;
            break;
        }
        case COMPLEMENTARY_STAY.value:
        case COMPLEMENTARY_STAY.name: {
            return COMPLEMENTARY_STAY;
            break;
        }
        case PROPERTY_CARE.value:
        case PROPERTY_CARE.name: {
            return PROPERTY_CARE;
            break;
        }
        case SEASONAL_HOLD.value:
        case SEASONAL_HOLD.name: {
            return SEASONAL_HOLD;
            break;
        }
        case TERMINATION_HOLD.value:
        case TERMINATION_HOLD.name: {
            return TERMINATION_HOLD;
            break;
        }
        case HOME_FOR_SALE.value:
        case HOME_FOR_SALE.name: {
            return HOME_FOR_SALE;
            break;
        }
        case OTHER_OWNER_HOLD.value:
        case OTHER_OWNER_HOLD.name: {
            return OTHER_OWNER_HOLD;
            break;
        }
        default:
            return {
                value: +subtype,
                name: subtype.toString()
            };
            break;
    }
}

function formatVacasaHoldSubtype(subtype: number | string): { value: number, name: string } {
    switch (subtype) {
        case OUT_OF_ORDER.value:
        case OUT_OF_ORDER.name: {
            return OUT_OF_ORDER;
            break;
        }
        case MAINTENANCE.value:
        case MAINTENANCE.name: {
            return MAINTENANCE;
            break;
        }
        case PHOTOGRAPHY.value:
        case PHOTOGRAPHY.name: {
            return PHOTOGRAPHY;
            break;
        }
        case HOUSEKEEPING.value:
        case HOUSEKEEPING.name: {
            return HOUSEKEEPING;
            break;
        }
        case OTHER_VACASA_HOLD.value:
        case OTHER_VACASA_HOLD.name:
            return OTHER_VACASA_HOLD;
            break;
        default:
            return {
                value: +subtype,
                name: subtype.toString()
            };
            break;
    }
}

export function formatSubtype(type: string | number, subtype: number | string, reverse: boolean = false) {
    if (_.isNil(subtype))
        return subtype;

    switch (type) {
        case TYPE_FIELD[2]:
        case TYPE_FIELD.owner_hold: {
            return reverse ? formatOwnerHoldSubtype(subtype).value : formatOwnerHoldSubtype(subtype).name;
            break;
        }
        case TYPE_FIELD[3]:
        case TYPE_FIELD.vacasa_hold: {
            return reverse ? formatVacasaHoldSubtype(subtype).value : formatVacasaHoldSubtype(subtype).name;
            break;
        }
        default:
            return subtype.toString();
            break;
    }
}

export function splitRent(
    arrive: string,
    depart: string,
    rent: number
): DayRent[] {
    if (!rent) return [];
    const d1 = parseReservationDate(arrive);
    const d2 = parseReservationDate(depart);

    if (!d1 || !d2) {
        throw new Error(`Invalid arrival ${arrive} or departure ${depart}`);
    }

    const dayCount = Math.round(
        (d2.getTime() - d1.getTime()) / (1000 * 3600 * 24)
    );
    const dailyRent = round(rent / dayCount, 2);
    const days: string[] = [];
    const rents: number[] = [];

    for (let i = 0; i <= dayCount - 1; i++) {
        const day = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate() + i);

        days.push(
            `${day.getFullYear()}-${padZero(day.getMonth() + 1, 2)}-${padZero(
                day.getDate(),
                2
            )}`
        );
        rents.push(dailyRent);
    }

    const diff = rent - rents.reduce((total, r) => total + r, 0);

    rents[rents.length - 1] = round(rents[rents.length - 1] + diff, 2);

    return days.map((d, i) => ({
        ltd: 0,
        date: d,
        rent: rents[i],
    }));
}

export const parseConfig: ParseConfig = {
    model: reservationModel,
    showZeroOption: true,
    keepZeros: true,
    skipRows: 2,
    /**
     * Validate fee and tax totals and reservation boundary dates
     * Also validate subtype
     */
    onRowParse(row) {
        verifyZeroFeesAndBookingFee(
            Field.Fees,
            this.model,
            row,
            this.keepZeros
        );

        row = (validateTypeFields(row));

        const totalFees = subTableTotal(Field.Fees, this.model, row);
        const totalTax = subTableTotal(Field.Taxes, this.model, row);

        const rent = monetize(row[Field.Rent]);
        const total = monetize(row[Field.Total]);

        if (round(totalFees + totalTax + rent, 2) !== round(total, 2)) {
            row._invalid.add(RowError.InvalidTotal);
            if (!row._totals_off) row._totals_off = new Map();
            row._totals_off.set(
                Field.Total,
                round(total, 2) - round(totalFees + totalTax + rent, 2)
            );
        }

        const arriveOn = parseReservationDate(row[Field.Arrival]);
        const leaveOn = parseReservationDate(row[Field.Departure]);

        if (arriveOn && leaveOn && arriveOn.getTime() > leaveOn.getTime()) {
            // this results in different UX than adding RowError.DateConflict
            row._invalid.add(Field.Arrival);
            row._invalid.add(Field.Departure);
        }
    },

    /**
     * Update errors when row values change
     */
    async onRowUpdate(row, fields) {
        if (this.onRowParse) this.onRowParse(row);

        if (fields.find(f => f.name === CommonField.UnitID)) {
            await verityUnitsAndCurrency(row);
        }
    },

    /**
     * Update errors that can only be determined by considering all rows
     */
    async onUpdate(file, fixSpec) {
        if (fixSpec.triggeredBy?.includes(RowError.DateConflict)) {
            // implies user is currently viewing date conflict section so re-
            // run that validation
            verifyDateRanges(file);
        }
    },

    /**
     * Validate unit IDs and reservation date uniqueness across rows
     */
    onComplete: async file => {
        verifyDateRanges(file);
        await verityUnitsAndCurrency(...file.rows);
    },

    /**
     * Convert scalar rent value to the daily breakdown expected by Connect
     */
    async beforeUpload(file) {
        file.rows.forEach(r => {
            r[Field.Rent] = splitRent(
                r[Field.Arrival],
                r[Field.Departure],
                r[Field.Rent]
            );
            if (!r[CommonField.CreatedBy]) {
                // Setting the field to null or undefined is not sufficient to
                // trigger Pydantic default value
                delete r[CommonField.CreatedBy];
            }
            r[Field.Type] = TYPE_FIELD[r[Field.Type]];
            r[Field.HoldType] = formatSubtype(r[Field.Type], r[Field.HoldType]);
        });
    },
    /**
     * Convert rent breakdown to the scalar value expected in UI
     */
    async beforeDownload(rows) {
        rows.filter(r => Array.isArray(r)).forEach(r => {
            r[Field.Rent] = subTableTotal(Field.Rent, this.model, r);
        });
        rows.map( r => {
            r[Field.Type] = TYPE_FIELD[r[Field.Type]];
            r[Field.HoldType] = formatSubtype(r[Field.Type], r[Field.HoldType], true);
        })
    },
};
