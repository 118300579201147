import React, { useState, FunctionComponent } from 'react';
import './confirm-modal.scss';
import { Button, FixTable, Icon, Modal, Spinner } from '../components';
import { downloadCSV, rowHasError } from '../util/';

type NoOp = () => void;

/**
 * Render section of the CSV table to allow fixing errors
 */
const Section: FunctionComponent<{
    file: ParsedFile;
    config: ParseConfig;
    fixSpec: FixSpec;
    /** Number of errors */
    count: number;
}> = ({ fixSpec, file, config, count }) => {
    const [visible, setVisible] = useState(false);
    const icon = visible ? 'arrow_drop_up' : 'arrow_drop_down';

    return (
        <section className="fix">
            <div className="fix-title" onClick={() => setVisible(!visible)}>
                <label>{fixSpec.label}</label>
                <label className="fix-count">&nbsp;({count})</label>
                <Icon id={`${fixSpec.label.toLowerCase().replace(/\s/g, '-')}-fix-error-${icon.replace(/_/g, '-')}`} name={icon} />
            </div>
            {visible && <FixTable file={file} config={config} fixSpec={fixSpec} />}
        </section>
    );
};

const Summary: FunctionComponent<{
    file: ParsedFile;
    config: ParseConfig;
    fixSpecs: FixSpec[];
}> = ({ file, config, fixSpecs }) => {
    if (file.rows.length === 0) return <p>No rows were found</p>;

    if (config.addFixSpecs) fixSpecs = fixSpecs.concat(config.addFixSpecs());

    /**
     * Tracks errors found while parsing file upload CSV. Invalid CSV
     * data is matched to a `FixSpec` which describes the problem and will
     * be shown on the modal to make the user 'fix' it before uploading.
     */
    const fixCounts: { [key: string]: number } = {};

    /** Whether row errors have all been matched to a fix specification. */
    let fixSpecFound = false;
    let errorTotal = 0;

    const sayRow = (num: number): string => (num === 1 ? 'row' : 'rows');
    /** List of field errors keyed to a row index */
    const errorRows: { [key: number]: string[] } = {};

    file.rows
        .filter(r => rowHasError(r))
        .forEach(r => {
            // the row has errors
            errorTotal++;
            errorRows[r._index] = Array.from(r._invalid).concat(Array.from(r._required));

            fixSpecs.forEach(spec => {
                if (rowHasError(r, spec.triggeredBy ?? spec.editFields)) {
                    // match row to fix type
                    const has = fixCounts[spec.key];
                    fixCounts[spec.key] = has ? has + 1 : 1;
                    fixSpecFound = true;
                }
            });
        });

    const total = file.rows.length;
    const good = total - errorTotal;

    if (errorTotal > 0 && !fixSpecFound) {
        console.error('Rows with Errors', JSON.stringify(errorRows));
        throw new Error(`${errorTotal} rows have errors but none match a fix specification`);
    }

    return (
        <>
            <p>
                {total} {sayRow(total)} found
            </p>
            {good > 0 && (
                <div id="row-read-success" className="success">
                    <Icon name="check_circle_outline" />
                    {good === total ? 'Every row ' : `${good} ${sayRow(good)}  `}
                    read successfully
                </div>
            )}
            {errorTotal > 0 && (
                <>
                    <div id="row-read-error" className="error">
                        <Icon name="warning" />
                        {errorTotal === total ? 'Every row ' : `${errorTotal} ${sayRow(errorTotal)} `}
                        had errors
                    </div>
                    <div className="fixes">
                        <p className="fixes-hint">You can edit any highlighted errors here or cancel the import and upload a new file.</p>
                        {Object.keys(fixCounts).map(key => (
                            <Section
                                key={key}
                                // @ts-ignore: key will always find a match
                                fixSpec={fixSpecs.find(f => f.key === key)}
                                file={file}
                                count={fixCounts[key]}
                                config={config}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export const ConfirmModal: FunctionComponent<{
    file: ParsedFile;
    config: ParseConfig;
    fixSpecs: FixSpec[];
    onCancel: NoOp;
    onRetry: NoOp;
    onSave: NoOp;
    onPatchRetry: (fileID?: number) => void;
    /** Whether spinner should be visible while modal is shown */
    showSpinner: boolean;
}> = ({ file, config, fixSpecs, onCancel, onRetry, onPatchRetry, onSave, showSpinner }) => {
    const hasErrors = file.rows.findIndex(r => rowHasError(r)) >= 0;
    const downloadReport = () => downloadCSV(file, config.model, null, true);
    return (
        <Modal className="confirmation" onClose={onCancel}>
            <h2 id="csv-file-name">{file.name}</h2>
            {showSpinner ? (
                <div className="center-loading-spinner">
                    <Spinner />
                </div>
            ) : (
                <div>
                    {hasErrors && <Button id="download-error-report" type="secondary" onClick={downloadReport} label="Download Error Report" />}
                    <Summary file={file} config={config} fixSpecs={fixSpecs} />
                    <nav className="actions">
                        <Button id="cancel-upload" type="secondary" onClick={onCancel} label="Cancel Import" />
                        {hasErrors && !file.fromDB ? (
                            <Button id="retry-button" onClick={onRetry} label="Retry" />
                        ) : hasErrors && file.fromDB ? (
                            <Button id="save-file" onClick={() => onPatchRetry(file.id)} label="Save Errored Rows" />
                        ) : (
                            <Button id="save-file" onClick={onSave} label="Continue Import" />
                        )}
                    </nav>
                </div>
            )}
        </Modal>
    );
};
