import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from '../constants';
import { FilterArguments } from '../components/upload-view';
import * as _ from 'lodash';
import { formatStringNumber } from '../util/parse-field';

export const LocationAndAccessView: FunctionComponent = () => (
    <UploadView
        id="location-and-access-upload-view"
        requiredRole={Role.LocationAndAccess}
        label="Location & Access"
        apiPath="location-and-access"
        uploadType="location_and_access"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
        templateFlowEnabled={true}
        isConnectEntity={true}
        entityName="units"
        entityCSVModel={LocationAndAccessCSVModel}
        customFilter={processCsvFile}
    />
);

/**
 * processes the response to load readable data into the template
 * @param input
 * @returns
 */
const processCsvFile = (input: FilterArguments) => {
    const { entities } = input;

    for (const entity of entities) {
        /**
         * Homologate registration
         */
        const registration = _.get(entity, 'attributes.registration', null);
        _.set(entity, 'attributes.registration', registration && registration !== -1 ? 'True' : 'False');

        /**
         * Homologate acknowledge
         */
        const acknowledgeOnline = _.get(entity, 'attributes.acknowledge_online', null);
        const acknowledgeSigned = _.get(entity, 'attributes.acknowledge_signed', null);
        _.set(
            entity,
            'attributes.acknowledge',
            acknowledgeOnline === 1 ? 'Online' : acknowledgeSigned === 1 ? 'Signed' : 'No additional agreement required'
        );

        /**
         * Homologate check in/out time
         */

        const checkInTime = _.get(entity, 'attributes.check_in_time', null);
        const checkOutTime = _.get(entity, 'attributes.check_out_time', null);
        _.set(entity, 'attributes.check_in_time', convertDate(checkInTime));
        _.set(entity, 'attributes.check_out_time', convertDate(checkOutTime));

        /**
         * Formating number string in .csv
         */
        const primaryLockbox = _.get(entity, 'attributes.primary_lockbox', null);
        const backupLockbox = _.get(entity, 'attributes.backup_lockbox', null);
        _.set(entity, 'attributes.primary_lockbox', formatStringNumber(primaryLockbox));
        _.set(entity, 'attributes.backup_lockbox', formatStringNumber(backupLockbox));
    }

    return input;
};

const convertDate = (date: string): string => {
    const arrDate = date.split(':');
    const hourNumber = +arrDate[0];
    const hourTxt = hourNumber >= 12 ? hourNumber - 12 : hourNumber;
    const minTxt = arrDate[1];
    const extension = hourNumber >= 12 ? 'PM' : 'AM';

    return `${hourTxt}:${minTxt} ${extension}`;
};

const LocationAndAccessCSVModel: Array<string> = [
    'address.address_1',
    'address.address_2',
    'address.city',
    'address.state',
    'address.zip',
    'primary_lockbox',
    'backup_lockbox',
    'lockbox_notes',
    'check_in_time',
    'check_out_time',
    'gate_code',
    'alarm_code',
    'driving_directions',
    'registration',
    'warning_email',
    'beach_access',
    'unit_booking_compliance.min_booking_age',
    'contract_terms',
    'acknowledge',
    'parking_total_cars',
    'garage_parking',
    'parking_notes',
];
