import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from "../constants";

export const ReportingLocationView: FunctionComponent = () => (
    <UploadView
        id="reporting-location-upload-view"
        requiredRole={Role.UnitRepLocation}
        label="Reporting Location"
        apiPath="reporting-location"
        uploadType="reporting_location"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={true}
        templateFlowEnabled={true}
        isConnectEntity={false}
        entityName="ReportingLocation"
        entityCSVModel={ReportingLocationCSVModel}
    />
);

const ReportingLocationCSVModel: Array<string> = [
    'id',
    'grouping_value',
    'start_date',
    'end_date',
];
