/**
 * Standard data types.
 *
 * These exist only as mappings to validation methods and input UX. For one-off
 * field types, directly assign a `FieldSpec.validate` method rather than adding
 * to the standard types.
 */
export const DataType = {
    Text: 0,
    /** YYYY-MM-DD */
    Date: 1,
    Float: 2,
    /** Positive or negative integers */
    Integer: 3,
    /** One or zero */
    Bit: 6,
    PositiveInteger: 7,
    PhoneNumber: 8,
    Email: 9,
    Boolean: 10,
    Table: 11,
    /** Ensure value is between or equal to 0 and 1 */
    Percent: 12,
    /** Allow new lines and carriage returns **/
    MultiLineText: 13,
    DayOfWeek: 14,
};

/**
 * Data type exceptions in the form "value must be ..."
 */
export const DataTypeError = {
    [DataType.Integer]: 'a whole number',
    [DataType.PositiveInteger]: 'a positive whole number',
    [DataType.Bit]: 'a one or zero (0/1)',
};
